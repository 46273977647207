import React, { useEffect, useState } from 'react'
import {
  Drawer,
  Form,
  Button,
  Modal,
  Spin,
  Row,
  Col,
  Input,
  PageHeader,
  Table,
  Tooltip,
  message,
} from 'antd'
import {
  ExclamationCircleOutlined,
  FormOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import { useGlobalStore } from 'stores/global'
import {
  findEventTags,
  createEventTag,
  deleteEventTag,
  updateEventTag,
} from 'services/event.service'
import { EventTag } from 'stores/types/event.types'
import SelectColorPlate from './SelectColorPlate'
const { confirm } = Modal
const { Column } = Table
const colorPlates = ['#ff6e01', '#0a0ae6', '#0b9a00', '#6d009a', '#c4237a', '#dd1738']
interface IProps {
  onSuccess: () => void
  onClose: () => void
  isOpen: boolean
}
const EventDrawerTag = ({ onSuccess, onClose, isOpen }: IProps) => {
  const [form] = Form.useForm()
  const { languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(true)
  const [eventTags, setEventTags] = useState<EventTag[]>([])
  const [tagColor, setTagColor] = useState<string>()

  useEffect(() => {
    if (isOpen) {
      findAll()
      setTagColor('')
    }
  }, [isOpen])

  const findAll = async () => {
    setLoading(true)
    const payload: any = await findEventTags()
    if (payload?.status?.code === 200) {
      setEventTags(payload.data)
    }
    setLoading(false)
  }

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const promise = values?.id ? updateEventTag(values?.id, values) : createEventTag(values)
        promise
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                form.resetFields()
                setTagColor('')
                findAll()
              }
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteEventTag(item.categoryId)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findAll()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  return (
    <Drawer
      title='Categories'
      width={768}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div className='text-right'>
          <Button disabled={loading} onClick={onCancel}>
            Close
          </Button>
        </div>
      }
    >
      {!!isOpen && (
        <>
          <Form
            form={form}
            name='tag-form'
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            initialValues={{
              isActive: true,
              options: {
                color: '',
              },
            }}
            requiredMark={false}
            scrollToFirstError
          >
            <Spin
              tip='Loading...'
              spinning={loading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <Row gutter={16}>
                <Form.Item name='id' className='hidden'>
                  <Input hidden />
                </Form.Item>
              </Row>

              <Row gutter={16}>
                {languages.map((item) => {
                  return (
                    <Col span={12} key={'name' + item.locale}>
                      <Form.Item
                        label={`Tag [${item.locale.toUpperCase()}]`}
                        name={['name', item.locale]}
                        rules={[
                          { required: true, message: `please enter tag name ${item.country}` },
                        ]}
                      >
                        <Input size='large' placeholder='name' />
                      </Form.Item>
                    </Col>
                  )
                })}
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    <div className='flex gap-2 items-center'>
                      {colorPlates?.map((color, key) => {
                        return (
                          <div
                            key={`color-${key}`}
                            className={`${
                              tagColor === color ? 'scale-125' : ''
                            } h-10 w-10 cursor-pointer transition duration-300 ease-in-out hover:scale-110`}
                            style={{ backgroundColor: color }}
                            onClick={() => {
                              setTagColor(color)
                              form.setFieldValue('options', {
                                ...form.getFieldValue('options'),
                                color,
                              })
                            }}
                          ></div>
                        )
                      })}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    shouldUpdate
                    name={['options', 'color']}
                    rules={[{ required: true, message: 'please enter color code' }]}
                  >
                    <Input size='large' placeholder='color code' />
                  </Form.Item>
                </Col>
                <Col span={6} className='flex items-center text-right'>
                  <Form.Item>
                    <div className='flex gap-2 justify-between'>
                      <Button loading={loading} type='primary' onClick={() => form.submit()}>
                        Save
                      </Button>

                      <Button disabled={loading} onClick={() => form.resetFields()}>
                        Cancle
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Spin>
          </Form>

          <div className='p-3'>
            <PageHeader title='Q&A Overview' className='p-0' />

            <Table
              loading={loading}
              pagination={false}
              size='small'
              rowKey={(record) => `event-tag-${record?.id + ''}`}
              dataSource={eventTags}
              className='mt-2 mb-2'
            >
              <Column
                title='Tags'
                className='break-word'
                ellipsis
                render={(record) => (
                  <div className='flex gap-2 items-center text-md font-normal'>
                    <div
                      className='h-8 w-8'
                      style={{ backgroundColor: record?.options?.color }}
                    ></div>
                    {languages.map((item, key) => {
                      return (
                        <React.Fragment key={`name-${item.locale}`}>
                          <div>
                            [{item.locale.toUpperCase()}] {record?.name?.[item.locale]}
                          </div>
                          {!!(key === 0) && <div>/</div>}
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
              />

              <Column
                width={100}
                render={(record) => (
                  <div className='flex justify-between'>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        onClick={() => {
                          form.setFieldsValue({
                            id: record?.id,
                            name: record.name,
                            options: record?.options,
                          })
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
              />
            </Table>
          </div>
        </>
      )}
    </Drawer>
  )
}

export default EventDrawerTag
