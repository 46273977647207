import { useEffect, useState } from 'react'
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  PictureOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Button, Col, Row, Spin, Modal, message, notification, Switch } from 'antd'
import { createStaticPage, updateStaticPage } from 'services/staticPage.service'
import { debounce } from 'utils/common'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'

import Editor from 'components/features/editor/QuillEditor'
import ViewHtmlEditor from 'components/features/editor/ViewHtmlEditor'
import FileUpload from 'components/features/upload/Upload'
import WraperEditable from 'components/features/wraperEditable'
import { useAuth } from 'stores/authorized'
import PermissionAccess from 'components/PermissionAccess'

interface IFormHtmlEditableProps {
  lang?: string
  staticPageId?: number
  dataSource?: any
}
const optionItems = {
  isEdiable: false,
  vision: 'Delivering world innovations for exquisite and glamorous beauty for Asians.',
  mission: 'Empowering our people to exceed expectations.',
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',

  thank: 'Graciously yours,',
  name: 'Cheeravish Wisutthiyan and Thammapong',
}

const { confirm } = Modal
const FormHtmlEditable = ({ dataSource, staticPageId, lang }: IFormHtmlEditableProps) => {
  const { scope } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>(optionItems)

  useEffect(() => {
    if (staticPageId) {
      const optionValues = {
        ...dataSource?.options,
        isEdiable: false,
      }
      setOptions(optionValues)
      if (dataSource?.images?.length) {
        setImageUrl(dataSource?.images[0])
      }
    }
  }, [lang])

  const onFinish = (lang) => {
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValues = {
          pageName: pageNameEnum.ABOUT,
          pageType: pageTypeEnum.CEO_MESSAGE,
          isActive: true,
          locale: lang,
          options,
        }

        const optionValues = {
          ...options,
        }
        delete optionValues?.image
        delete optionValues?.isEdiable

        const formData = new FormData()
        formData.append('id', `${staticPageId}`)
        formData.append('pageName', pageNameEnum.ABOUT)
        formData.append('pageType', pageTypeEnum.CEO_MESSAGE)
        formData.append('locale', lang)
        formData.append('options', JSON.stringify(optionValues))

        if (fileUpload) {
          formData.append('files[]', fileUpload, `CEO-MESSAGE-ITOON-${fileUpload.name}`)
        }
        console.log(itemValues)
        const promise = staticPageId
          ? updateStaticPage(staticPageId, formData)
          : createStaticPage(formData)
        promise
          .then((response) => {
            setLoading(false)
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              setOptions({
                ...options,
                isEdiable: false,
              })
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const [imageUrl, setImageUrl] = useState<string>()
  const [fileUpload, setFileUpload] = useState<File>()

  const onUpload = (upload) => {
    setImageUrl(upload.imageUrl)
    setFileUpload(upload.file)
  }

  const onDelete = (file) => {
    console.log('onDelete', file)
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && staticPageId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          updateStaticPage(staticPageId, {
            images: null,
          })
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
                setImageUrl(undefined)
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      setImageUrl(undefined)
      setFileUpload(undefined)
    }
  }

  const rteChange = debounce(function (event: any) {
    const optionState = { ...options }
    optionState[event.field] = event.value
    setOptions(optionState)
  }, 50)

  const setEditable = (isEdiable) => {
    const optionState = { ...options }
    optionState.isEdiable = isEdiable
    setOptions(optionState)
  }

  return (
    <>
      <Spin
        tip='Loading...'
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row gutter={16} className='mb-10'>
          <Col span={24}>
            <div className='relative group'>
              <div className='pt-5 md:pt-0 my-0 md:my-10 mx-auto  w-full md:w-10/12'>
                <div className='md:flex gap-10 justify-center text-base m-auto'>
                  <div className='my-4 md:w-2/5 flex gap-2 items-center'>
                    <div className='h-3 w-3 bg-primary'></div>
                    <div className='w-[96%]'>
                      <div className='font-bold text-primary text-base'>The Visionary</div>
                      <div
                        contentEditable={options.isEdiable}
                        suppressContentEditableWarning={true}
                        onBlur={(e) =>
                          rteChange({
                            field: 'vision',
                            value: e.currentTarget.textContent,
                          })
                        }
                        dangerouslySetInnerHTML={{ __html: options.vision }}
                      ></div>
                    </div>
                  </div>
                  <div className='my-4 md:w-1/4 flex gap-2 items-center'>
                    <div className='h-3 w-3 bg-primary'></div>
                    <div className='w-[96%]'>
                      <div className='font-bold text-primary text-base'>Our Mission</div>
                      <div
                        contentEditable={options.isEdiable}
                        suppressContentEditableWarning={true}
                        onBlur={(e) =>
                          rteChange({
                            field: 'mission',
                            value: e.currentTarget.textContent,
                          })
                        }
                        dangerouslySetInnerHTML={{ __html: options.mission }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='bg-secondary pt-5 md:pt-0 my-0 md:my-10 mx-auto  w-full md:w-10/12'>
                <div className=''>
                  <div className='px-5 md:px-0 md:flex'>
                    <div className='w-full md:w-3/5 pb-10 pt-5 '>
                      <div className='py-8 md:p-10 md:pt-14 quote'>
                        {options.isEdiable ? (
                          <Editor
                            content={options.description}
                            onChange={(value) =>
                              rteChange({
                                field: 'description',
                                value,
                              })
                            }
                            className='editor-content fix-toolbar -mx-[15px]'
                          />
                        ) : (
                          <ViewHtmlEditor html={options.description} className='-mx-[15px]' />
                        )}
                        <div className='quote-right'></div>
                      </div>
                      <div className='md:px-10 text-base'>
                        <div
                          contentEditable={options.isEdiable}
                          suppressContentEditableWarning={true}
                          onBlur={(e) =>
                            rteChange({
                              field: 'thank',
                              value: e.currentTarget.textContent,
                            })
                          }
                          dangerouslySetInnerHTML={{ __html: options.thank }}
                        ></div>
                        <div
                          contentEditable={options.isEdiable}
                          suppressContentEditableWarning={true}
                          onBlur={(e) =>
                            rteChange({
                              field: 'name',
                              value: e.currentTarget.textContent,
                            })
                          }
                          dangerouslySetInnerHTML={{ __html: options.name }}
                        ></div>
                      </div>
                    </div>
                    <div className='w-full md:w-2/5 -order-1 md:order-last mb-5 md:mb-0 md:pr-10 relative group'>
                      <div className=' bg-primary flex justify-end md:h-[85%] md:w-[72%] md:absolute top-0 right-0'></div>

                      {imageUrl ? (
                        <>
                          <img
                            src={imageUrl}
                            className='w-full h-auto m-auto object-cover md:absolute bottom-0 -left-6 rounded-lg md:rounded-none'
                          />
                          <div className='absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 group-hover:opacity-40 transition duration-300 ease-in-out bg-white'></div>
                          <div className='opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out p-3 flex gap-2 items-center absolute w-full h-full top-0 left-0 rounded-md'>
                            <div className='bg-white m-auto p-1 flex items-center rounded-md'>
                              <PermissionAccess permission={'about_us:update'} scope={scope}>
                                <Button
                                  type='primary'
                                  size='small'
                                  className=''
                                  onClick={() => onDelete(imageUrl)}
                                >
                                  <DeleteOutlined className='text-base relative -top-1' /> Delete
                                </Button>
                              </PermissionAccess>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='upload-full-w group mb-3 relative full-h-screen-upload'>
                          <PermissionAccess permission={'about_us:update'} scope={scope}>
                            <FileUpload
                              onUpload={(file) => onUpload(file)}
                              onDelete={(file) => onDelete(file)}
                              imageUrl={imageUrl}
                              listType='CARD'
                              className='h-full'
                            >
                              <div className='w-full h-full object-cover rounded-none flex flex-col items-center justify-center text-center p-5 bg-gray-100 text-gray-500 border border-solid group-hover:border-primary-100'>
                                <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                                <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                                  Browse to find or drag{' '}
                                  <span className='font-bold underline'>Image</span> here
                                </div>
                              </div>
                            </FileUpload>
                          </PermissionAccess>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {!options.isEdiable && (
                <PermissionAccess permission={'about_us:update'} scope={scope}>
                  <WraperEditable onClick={() => setEditable(true)} />
                </PermissionAccess>
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className='text-right mt-3'>
            <PermissionAccess permission={'about_us:update'} scope={scope}>
              <Button type='primary' onClick={() => onFinish(lang)}>
                Save data in [{lang?.toUpperCase()}]
              </Button>
            </PermissionAccess>
          </Col>
        </Row>
      </Spin>
    </>
  )
}

export default FormHtmlEditable
