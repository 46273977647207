import { FormInstance, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { useGlobalStore } from 'stores/global'
import FormContent from './FormContent'
// import FormHtmlEditable from './FormHtmlEditable'

interface ITapProps {
  label: string
  key: string
  children: React.ReactNode
  forceRender: boolean
}

interface IPropTabByLanguage {
  form: FormInstance
  dataSource?: any
}

const TabByLanguage = ({ form, dataSource }: IPropTabByLanguage) => {
  const { languages } = useGlobalStore()
  const [tabItems, setTabItems] = useState<ITapProps[]>([])

  useEffect(() => {
    const items: ITapProps[] = []
    languages.map((item) => {
      const data = dataSource?.contents?.find((f) => f.locale === item.locale)
      items.push({
        label: item.country,
        key: item.locale,
        children: <FormContent lang={item.locale} form={form} dataSource={data} />,
        forceRender: true,
      })
    })

    setTabItems(items)

    return () => {
      items.length = 0
    }
  }, [])

  const handleClick = () => {
    console.log('handle click')
  }

  return <>{!!tabItems.length && <Tabs items={tabItems} onTabClick={handleClick} />}</>
}

export default TabByLanguage
