import { useState } from 'react'
import { Button, Modal } from 'antd'
import { DeleteOutlined, EyeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'

interface IImagePreviewProps {
  imageList: string[]
  imageUrl: string
  index: number
  onDelete: () => void
}
const ImagePreview = ({ imageList, imageUrl, index, onDelete }: IImagePreviewProps) => {
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [indexImage, setIndexImage] = useState<number>(index)
  const [image, setImage] = useState<string>(imageUrl)

  const nextImage = () => {
    const nextIndex = indexImage < imageList.length - 1 ? indexImage + 1 : 0
    setImage(imageList[nextIndex])
    setIndexImage(nextIndex)
  }

  const prevImage = () => {
    const prevIndex = indexImage > 0 ? indexImage - 1 : imageList.length - 1
    setImage(imageList[prevIndex])
    setIndexImage(prevIndex)
  }
  return (
    <>
      <div className='w-[160px] relative cursor-pointer group'>
        <div className=' relative bg-no-repeat bg-cover'>
          <img
            src={imageUrl}
            className='w-full h-[160px] object-cover hover:scale-110 transition duration-300 ease-in-out'
          />
          <div className='absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-white'></div>
        </div>
        <div className='opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out p-3 flex gap-2 items-center justify-center absolute w-full h-full top-0 left-0 rounded-md'>
          <div className='bg-white w-2/3 p-1 gap-2 flex items-center justify-around rounded-md'>
            <Button type='ghost' size='small' className='w-8' onClick={() => setIsPreview(true)}>
              <EyeOutlined className='text-base relative -top-0.5' />
            </Button>
            <Button type='primary' size='small' className='w-8' onClick={() => onDelete()}>
              <DeleteOutlined className='text-base relative -top-0.5' />
            </Button>
          </div>
        </div>
      </div>

      <Modal
        width={768}
        centered
        bodyStyle={{ padding: '4px' }}
        footer={null}
        open={isPreview}
        onCancel={() => setIsPreview(false)}
      >
        <div className='transition-opacity ease-in duration-700 opacity-100 '>
          <img alt='file upload' src={image} className='w-full' />
          <div className='fixed top-0 left-0 w-full h-full flex items-center justify-between p-10'>
            <LeftOutlined
              className='text-5xl cursor-pointer transition duration-200 ease-in-out hover:text-white hover:scale-110'
              onClick={prevImage}
            />
            <RightOutlined
              className='text-5xl cursor-pointer transition duration-200 ease-in-out hover:text-white hover:scale-110'
              onClick={nextImage}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ImagePreview
