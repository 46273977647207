import { useEffect, useState } from 'react'
import { Col, Row, Spin, Form, Input, Modal, message, PageHeader, FormInstance, Switch } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { upsertStaticPage } from 'services/staticPage.service'
import { IAddressValue } from 'interfaces/Address'
import { LanguageEnum } from 'enums/language.enum'

import FormAddresses from 'components/features/form/FormAddresses'

const { confirm } = Modal
const defaultSocials = [
  { name: 'facebookAestema', placeholder: 'Facebook aestema' },
  { name: 'facebookEptq', placeholder: 'Facebook eptq-thailand' },
  { name: 'instagramAestema', placeholder: 'Instragram aestema' },
  { name: 'instagramEptq', placeholder: 'Instragram eptq-thailand' },
  { name: 'lineAccount', placeholder: '@Account Line OA' },
  { name: 'lineLink', placeholder: 'Link Line OA' },
  { name: 'youtube', placeholder: 'Youtube chanal' },
  { name: 'tiktok', placeholder: 'TikTok chanal' },
]

interface IAddressLang {
  address: IAddressValue
  selected: {
    [key: string]: string
  }
}

interface IProps {
  form: FormInstance
  onSuccess?: (item?: any) => void
  dataSource?: any
}

const FormWebConfig = ({ form, onSuccess, dataSource = {} }: IProps) => {
  const { languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(true)
  const [langActive, setLangActive] = useState<string>()
  const [addressLang, setAddressLang] = useState<IAddressLang>()

  useEffect(() => {
    if (dataSource?.items?.length) {
      const socials = dataSource.items[0].options
      form.setFieldsValue({
        ...dataSource,
        ...socials,
        address: dataSource.items[0].options?.address,
      })
    }
    setLoading(false)
    setLangActive(LanguageEnum.TH)
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        let mergeAddress = {}
        const spreadAddress: any = { ...addressLang?.address }
        for (const locale of Object.keys(values.address)) {
          mergeAddress = {
            ...mergeAddress,
            [locale]: {
              name: values.address[locale].name,
              no: values.address[locale].no,
              ...spreadAddress[locale],
            },
          }
        }
        delete values?.address

        const itemValue = [
          {
            ...values,
            id: dataSource?.id,
            pageName: dataSource.pageName,
            pageType: dataSource.pageType,
            options: {
              ...values,
              address: {
                ...mergeAddress,
                selected: addressLang?.selected,
              },
            },
            locale: 'all',
            isActive: true,
          },
        ]

        console.log('itemValue', itemValue)
        upsertStaticPage(itemValue)
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                onSuccess(response.data)
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  return (
    <>
      <Form
        form={form}
        name='setting-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Col span={24} className='m-2 border border-solid rounded-md'>
              <div className='p-3'>
                <PageHeader
                  title='Address Configuration'
                  subTitle='Add your addres to display on website'
                  className='p-0 pb-4'
                />

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label='Email'
                      name='email'
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'please enter email address',
                        },
                      ]}
                    >
                      <Input size='large' placeholder='Email' />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label='Phone'
                      name='phone'
                      rules={[{ required: true, message: 'please enter phone number' }]}
                    >
                      <Input size='large' placeholder='Phone number. e.g: 08xxxxxxx, 09xxxxxxx' />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  {languages.map((item) => {
                    return (
                      <Col span={12} key={'address' + item.locale}>
                        <Form.Item
                          label={`Address Name [${item.locale.toUpperCase()}]`}
                          name={['address', item.locale, 'name']}
                          rules={[
                            { required: true, message: `please enter ${item.country} address` },
                          ]}
                        >
                          <Input size='large' placeholder='Clinic address name' />
                        </Form.Item>
                      </Col>
                    )
                  })}
                  {languages.map((item) => {
                    return (
                      <Col span={12} key={'address' + item.locale}>
                        <Form.Item
                          label={`Address No [${item.locale.toUpperCase()}]`}
                          name={['address', item.locale, 'no']}
                          rules={[
                            { required: true, message: `please enter ${item.country} address` },
                          ]}
                        >
                          <Input size='large' placeholder='Clinic address no' />
                        </Form.Item>
                      </Col>
                    )
                  })}

                  <Col span={24}>
                    {!!langActive && (
                      <FormAddresses
                        form={form}
                        locale={'th'}
                        languages={languages.map((lang) => lang.locale)}
                        onSelect={(value) => setAddressLang(value)}
                      />
                    )}
                  </Col>

                  <Col span={24}>
                    <div className='text-right text-xs text-gray-400 mb-4'>
                      {languages.map((item, key) => {
                        return (
                          <span key={`address-${item.locale}`} className='mb-1 '>
                            {!!addressLang?.selected && (
                              <span>
                                {addressLang?.selected[item.locale]}{' '}
                                <span className={key !== 0 ? 'hidden' : 'mx-2'}>/</span>
                              </span>
                            )}
                          </span>
                        )
                      })}
                    </div>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label='Link Google Map'
                      name='linkGoogleMap'
                      rules={[{ required: false, message: 'please input link google map' }]}
                    >
                      <Input.TextArea size='large' placeholder='Link Google map' rows={2} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className='p-3'>
                <PageHeader
                  title='Social Link Configuration'
                  subTitle='Add your socials to display on website'
                  className='p-0 pb-4'
                />
                <Row gutter={16}>
                  <Col span={24}>
                    {defaultSocials.map((item) => {
                      return (
                        <Row key={item.placeholder}>
                          <Col span={3}>
                            <Form.Item
                              name={[item.name, 'isActive']}
                              valuePropName='checked'
                              className='!mb-0'
                            >
                              <Switch checkedChildren='show' unCheckedChildren='hide' />
                            </Form.Item>
                          </Col>
                          <Col span={14}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues[item.name] !== currentValues[item.name]
                              }
                            >
                              {({ getFieldValue }) => (
                                <Form.Item
                                  name={[item.name, 'value']}
                                  rules={[
                                    {
                                      required: getFieldValue(item.name)?.isActive,
                                      message: `please input ${item.placeholder.toLowerCase()}`,
                                    },
                                  ]}
                                >
                                  <Input size='large' placeholder={item.placeholder} />
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <div className='my-3 mx-4 text-small text-gray-500'>
                              {item.placeholder}
                            </div>
                          </Col>
                        </Row>
                      )
                    })}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormWebConfig
