import { useEffect, useState } from 'react'
import { Drawer, Form, Button } from 'antd'
import { EventTag } from 'stores/types/event.types'

import FormEventCalendar from './FormEventCalendar'

interface IProps {
  onSuccess: () => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
  tags: EventTag[]
}
const FaqDrawer = ({ onSuccess, onClose, isOpen, dataSource, tags }: IProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
    }

    return () => {
      isOpen && form.resetFields()
    }
  }, [isOpen])

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div className='flex gap-2'>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Drawer
      title='Manage Content'
      width={1024}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<FooterDrawer />}
    >
      {!!isOpen && (
        <FormEventCalendar
          form={form}
          dataSource={dataSource}
          tags={tags}
          eventId={dataSource?.id}
          onSuccess={onSuccess}
        />
      )}
    </Drawer>
  )
}

export default FaqDrawer
