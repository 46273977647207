import { Layout } from 'antd'

const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      Copyright © {new Date().getFullYear()} Aestema. All Rights Reserved.
    </Layout.Footer>
  )
}

export default Footer
