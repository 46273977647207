import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Button, Col, Row, Table, Tooltip } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { findAllStaticPages } from 'services/staticPage.service'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'
import { groupBy } from 'utils/common'
import { useAuth } from 'stores/authorized'
import SettingDrawer from './components/Drawer'
import PermissionAccess from 'components/PermissionAccess'

const { Column } = Table

const pageTypeConstant = {
  [pageTypeEnum.PRIVACY_POLICY]: 'Privacy Policy',
  [pageTypeEnum.TERM_CONDITION]: 'Terms and Conditions',
  [pageTypeEnum.WEB_CONFIG]: 'Web Configuration',
}

const defaultData = [
  { pageName: pageNameEnum.SETTING, pageType: pageTypeEnum.PRIVACY_POLICY, items: [] },
  { pageName: pageNameEnum.SETTING, pageType: pageTypeEnum.TERM_CONDITION, items: [] },
  { pageName: pageNameEnum.SETTING, pageType: pageTypeEnum.WEB_CONFIG, items: [] },
]

export interface ISearchState {
  searchText: string | undefined
  categoryId: string | undefined
  pageType: string | undefined
}

const SettingPage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any>()
  const [settings, setSettings] = useState<any[]>(defaultData)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setPageHeader({
      title: 'Setting Overview',
      subTitle: 'Content Management',
      backIcon: false,
      extra: [],
    })
    findSettings()
  }, [])

  const findSettings = async () => {
    setLoading(true)
    const body = {
      search: {
        pageName: pageNameEnum.SETTING,
      },
    }

    const payload: any = await findAllStaticPages(body)
    if (payload?.status?.code === 200) {
      if (payload.data.length) {
        const dataGroups = groupBy(payload.data, 'pageType')
        const results = defaultData.map((item: any) => {
          const dataItem = dataGroups[item.pageType] || []
          return {
            id: dataItem?.[0]?.id,
            title: pageTypeConstant[item.pageType],
            pageName: item.pageName,
            pageType: item.pageType,
            items: dataItem,
            createdAt: dataItem?.[0]?.createdAt,
            updatedAt: dataItem?.[0]?.createdAt,
          }
        })
        setSettings(results)
      }
    }

    setLoading(false)
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findSettings()
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `setting-${record.pageType}`}
            dataSource={settings}
            className='mt-2 mb-2'
          >
            <Column
              className='break-word'
              ellipsis
              render={(record) => (
                <div>
                  <div className='text-md font-normal'>{record?.title}</div>
                </div>
              )}
            />
            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.createdAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.updatedAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              width={100}
              render={(record) => (
                <PermissionAccess permission='setting:update' scope={scope}>
                  <div className='flex gap-2'>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                  </div>
                </PermissionAccess>
              )}
            />
          </Table>
        </Col>
      </Row>

      <SettingDrawer
        onClose={onClose}
        onSuccess={onClose}
        isOpen={isOpen}
        dataSource={dataSource}
      />
    </>
  )
}

export default SettingPage
