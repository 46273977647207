import { Button } from 'antd'

const WraperEditable = ({ onClick, onDelete }: { onClick: () => void; onDelete?: () => void }) => {
  return (
    <div className='opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out absolute h-full w-full top-0 left-0 flex flex-col justify-center items-center bg-white bg-opacity-50'>
      <Button
        type='primary'
        shape='round'
        className='z-10 hover:bg-white hover:text-primary'
        onClick={onClick}
      >
        Click To Edit
      </Button>

      {typeof onDelete === 'function' && (
        <Button
          type='dashed'
          shape='round'
          className='z-10 mt-2 min-w-[115px] bg-white hover:bg-primary hover:text-white'
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </div>
  )
}

export default WraperEditable
