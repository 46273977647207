import fetch from 'utils/fetchAxios'

export const getAccessToken = async () => {
  const method = 'GET'
  const path = '/v1/auth/token'
  return fetch(method, path)
}

export const signIn = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/auth/signin'
  return fetch(method, path, body)
}

export const resetPassword = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/auth/password/reset'
  return fetch(method, path, body)
}

export const changePassword = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/auth/admin/password/change'
  return fetch(method, path, body)
}

export const signOut = async () => {
  const method = 'POST'
  const path = '/v1/auth/admin/signout'
  return fetch(method, path)
}
