import { useEffect, useState } from 'react'
import { Col, Row, Spin, Form, Input, Modal, Switch, message, PageHeader, FormInstance } from 'antd'
import { ExclamationCircleOutlined, PictureOutlined, LoadingOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { useArticleStore } from 'stores/article'
import { createArticle, updateArticle } from 'services/article.service'

import FileUpload from 'components/features/upload/Upload'
import TabByLanguage from './TabByLanguage'

const { confirm } = Modal

interface IProps {
  form: FormInstance
  articleId?: number
  onSuccess?: (item?: any) => void
  dataSource?: any
}

const FormArticle = ({ form, articleId, onSuccess, dataSource = {} }: IProps) => {
  const { languages } = useGlobalStore()
  const { articleType } = useArticleStore()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (dataSource?.id) {
      setImageUrl(dataSource?.coverImage)
    } else {
      setImageUrl(undefined)
      setFileUpload(undefined)
    }
    setLoading(false)
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        // setLoading(true)
        const contents = languages.map((item) => {
          const contentItem = values[item.locale]
          delete values[item.locale]
          return {
            ...contentItem,
            locale: item.locale,
          }
        })

        const itemValue = {
          ...values,
          articleType,
          contents,
        }

        const formData = new FormData()
        for (const item of Object.keys(itemValue)) {
          const value = ['contents'].includes(item)
            ? JSON.stringify(itemValue[item])
            : itemValue[item]

          formData.append(item, value)
        }

        if (fileUpload) {
          formData.append('file', fileUpload, `ARTICLE-ITOON-${fileUpload.name}`)
          formData.append('coverImage', 'null')
        }

        console.log('itemValue', itemValue)

        const promise = articleId ? updateArticle(articleId, formData) : createArticle(formData)
        promise
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                onSuccess(response.data)
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const [imageUrl, setImageUrl] = useState<string>()
  const [fileUpload, setFileUpload] = useState<File>()

  const onUpload = (upload) => {
    setImageUrl(upload.imageUrl)
    setFileUpload(upload.file)
  }

  const onDelete = (file) => {
    console.log('onDelete', file)
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && articleId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          updateArticle(articleId, {
            coverImage: null,
          })
            .then((response) => {
              if (response.status.code === 200) {
                setImageUrl(undefined)
                message.success('Deleted Successfully.')
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      setImageUrl(undefined)
      setFileUpload(undefined)
    }
  }

  return (
    <>
      <Form
        form={form}
        name='article-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Form.Item name='id'>
              <Input hidden />
            </Form.Item>

            <Col span={24}>
              <Form.Item
                label='Name'
                name='title'
                rules={[{ required: true, message: 'please input name' }]}
              >
                <Input size='large' placeholder='Name' />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className='upload-full-w group mt-3'>
                <FileUpload
                  onUpload={onUpload}
                  onDelete={(file) => onDelete(file)}
                  imageUrl={imageUrl}
                  className=''
                >
                  <div className='w-full min-h-[120px] text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                    <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                    <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                      Browse to find or drag{' '}
                      <span className='font-bold underline'>Cover Photo</span> here
                    </div>
                  </div>
                </FileUpload>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='m-2 border border-solid rounded-md'>
              <div className='p-3'>
                <PageHeader
                  title='Content Manager'
                  subTitle='Write content by language'
                  className='p-0'
                />
                <TabByLanguage form={form} dataSource={dataSource} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} className='pt-5 border-t border-solid'>
              <div className='flex items-center'>
                <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which data will be listed on status (Active/InActive)
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormArticle
