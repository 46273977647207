import { useEffect, useState } from 'react'
import { Drawer, Form, Button } from 'antd'

import FormUser from './FormUser'

interface IProps {
  onSuccess: () => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
}
const UserDrawer = ({ onSuccess, onClose, isOpen, dataSource }: IProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      if (dataSource?.id) {
        form.setFieldsValue(dataSource)
      }
    }
    return () => {
      isOpen && form.resetFields()
    }
  }, [isOpen])

  const handleSaveDraft = () => {
    form.setFieldValue('isActive', false)
    form.submit()
  }

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div className='flex gap-2 hidden'>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Drawer
      title='User Profile'
      width={768}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<FooterDrawer />}
    >
      {!!isOpen && (
        <FormUser
          form={form}
          dataSource={dataSource}
          userId={dataSource?.id}
          onSuccess={onSuccess}
        />
      )}
    </Drawer>
  )
}

export default UserDrawer
