import { Form, FormInstance, Input } from 'antd'

interface IFormContentProps {
  form: FormInstance
  lang: string
}

const FormContent = ({ lang }: IFormContentProps) => {
  return (
    <>
      <div className='form-language'>
        <Form.Item
          name={[lang, 'question']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: `please input question ${lang.toUpperCase()}`,
            },
          ]}
        >
          <Input.TextArea size='large' placeholder={`question ${lang.toUpperCase()}`} rows={2} />
        </Form.Item>

        <Form.Item
          name={[lang, 'answer']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: `please input answer ${lang.toUpperCase()}`,
            },
          ]}
        >
          <Input.TextArea size='large' placeholder={`Answer ${lang.toUpperCase()}`} rows={10} />
        </Form.Item>
      </div>
    </>
  )
}

export default FormContent
