import { useEffect, useState } from 'react'
import { Col, message, Modal, PageHeader, Row } from 'antd'
import { PictureOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { deleteImageEvent } from 'services/event.service'

import FileUpload from 'components/features/upload/Upload'
import ImagePreview from 'components/features/lightbox/ImagePreview'

const { confirm } = Modal

interface IProps {
  eventId?: number | undefined
  dataSource?: any
  onSuccess: (images) => void
}

const FormEventImageGallery = ({ eventId, dataSource, onSuccess }: IProps) => {
  const [imagesUrl, setImagesUrl] = useState<string[]>([])
  const [filesUpload, setFilesUpload] = useState<File[]>([])

  useEffect(() => {
    if (eventId) {
      setImagesUrl(dataSource)
    }
  }, [])

  useEffect(() => {
    onSuccess(filesUpload)
  }, [filesUpload?.length])

  const onUpload = (upload) => {
    console.log(upload)
    setImagesUrl((prevImagesUrl) => {
      return [...prevImagesUrl, upload.imageUrl]
    })
    setFilesUpload((prevFilesUpload) => {
      return [...prevFilesUpload, upload.file]
    })
  }

  const onDelete = (file, imageIndex) => {
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && eventId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          deleteImageEvent(eventId, { image: pathUrl })
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
                const removeImage = imagesUrl.filter((_, index) => index !== imageIndex)
                setImagesUrl(removeImage)
                onSuccess(removeImage)
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      const removeImage = imagesUrl.filter((_, index) => index !== imageIndex)
      const removeFiles = filesUpload.filter((_, index) => index !== imageIndex)
      setImagesUrl(removeImage)
      setFilesUpload(removeFiles)
    }
  }

  return (
    <Row gutter={16}>
      <Col span={24} className='m-1.5 p-2 border border-solid rounded-md'>
        <PageHeader title='Gallery' subTitle='management' className='p-0' />

        <div className='flex gap-2 p-3'>
          <div className='w-3/12'>
            <FileUpload
              field='image'
              listType='NONE'
              multiple={true}
              onUpload={onUpload}
              className='w-[160px] h-[160px]'
            >
              <div className='flex flex-none justify-center items-center min-h-[160px] bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                <div className='w-full text-center p-5 '>
                  <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                  <div className='text-sm group-hover:scale-110 transition duration-300 ease-in-out'>
                    Browse to find or drag <br />
                    <span className='font-bold underline'>imgae</span> here
                  </div>
                </div>
              </div>
            </FileUpload>
          </div>
          <div className='w-9/12 grid grid-cols-4 gap-4'>
            {!!imagesUrl.length &&
              imagesUrl.map((item, index) => {
                return (
                  <ImagePreview
                    key={`gallery-${index}`}
                    index={index}
                    imageUrl={item}
                    imageList={imagesUrl}
                    onDelete={() => onDelete(item, index)}
                  />
                )
              })}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default FormEventImageGallery
