import { useEffect, useState } from 'react'
import { Drawer, Form, Button } from 'antd'
import { useProductStore } from 'stores/product'

import FormProduct from './FormProduct'

interface IProps {
  onSuccess: () => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
}
const ProductDrawer = ({ onSuccess, onClose, isOpen, dataSource }: IProps) => {
  const { setProductInfo, setProductItems } = useProductStore()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      if (dataSource?.productId) {
        form.setFieldsValue({
          ...dataSource,
        })
        setProductInfo([])
        setProductItems(dataSource?.items?.sort((a, b) => a.productItemId - b.productItemId) ?? [])
      }
    }
  }, [isOpen])

  const onCancel = () => {
    form.resetFields()
    setProductInfo([])
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Drawer
      title='Manage Product'
      width={970}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<FooterDrawer />}
    >
      {!!isOpen && (
        <FormProduct
          form={form}
          dataSource={dataSource}
          productId={dataSource?.productId}
          onSuccess={onSuccess}
        />
      )}
    </Drawer>
  )
}

export default ProductDrawer
