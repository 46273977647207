import * as EXIF from 'exif-js'

export const convertImage = async (base64Url, img) => {
  const transform = {
    rotate: (base64Img, file, maxWidth = 1024) => {
      return new Promise((resolve) => {
        const mimeType = file.type

        let oldWidth, oldHeight, canvas, ctx, orientation, newHeight, newWidth

        const image: any = new Image()
        image.src = base64Img
        image.onload = () => {
          EXIF.getData(image, function (this) {
            orientation = EXIF.getTag(this, 'Orientation')

            canvas = document.createElement('canvas')

            oldWidth = image.width
            oldHeight = image.height
            // resizing image
            newWidth = oldWidth > maxWidth ? maxWidth : oldWidth
            newHeight = Math.floor((oldHeight / oldWidth) * maxWidth)

            // set proper canvas dimensions before transform & export
            if ([5, 6, 7, 8].indexOf(orientation) > -1) {
              canvas.width = image.height
              canvas.height = image.width
            } else {
              canvas.width = image.width
              canvas.height = image.height
            }

            ctx = canvas.getContext('2d')
            switch (orientation) {
              case 2:
                ctx.transform(-1, 0, 0, 1, image.width, 0)
                break
              case 3:
                ctx.transform(-1, 0, 0, -1, image.width, image.height)
                break
              case 4:
                ctx.transform(1, 0, 0, -1, 0, image.height)
                break
              case 5:
                ctx.transform(0, 1, 1, 0, 0, 0)
                break
              case 6:
                ctx.transform(0, 1, -1, 0, image.height, 0)
                break
              case 7:
                ctx.transform(0, -1, -1, 0, image.height, image.width)
                break
              case 8:
                ctx.transform(0, -1, 1, 0, 0, image.width)
                break
              default:
                ctx.transform(1, 0, 0, 1, 0, 0)
            }

            ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
            resolve(canvas.toDataURL(mimeType, 1.0))
          })
        }
      })
    },
    convert: (url, filename, mimeType) => {
      return fetch(url)
        .then(function (res) {
          return res.arrayBuffer()
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType })
        })
    },
  }

  return new Promise((resolve) => {
    base64Url = transform.rotate(base64Url, img)
    transform.convert(base64Url, 'rotate-' + img.name, img.type).then((fileData) => {
      img = fileData
      resolve({
        file: img,
        imageUrl: base64Url,
      })
    })
  })
}

export const convertBase64ToFile = async (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType })
    })
}

export const rotatePortrait = async (base64Url, img) => {
  const transform = {
    rotate: (base64Img, file) => {
      return new Promise((resolve) => {
        let canvas, ctx
        const mimeType = file.type

        const image: any = new Image()
        image.src = base64Img
        image.onload = () => {
          EXIF.getData(image, function (this) {
            canvas = document.createElement('canvas')
            ctx = canvas.getContext('2d')
            if (image.height > image.width) {
              canvas.width = image.height
              canvas.height = image.width
              ctx.transform(0, 1, -1, 0, image.height, 0)
              ctx.drawImage(image, 0, 0, image.width, image.height)
            } else {
              canvas.width = image.width
              canvas.height = image.height
              ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
            }
            resolve(canvas.toDataURL(mimeType, 1.0))
          })
        }
      })
    },
    convert: (url, filename, mimeType) => {
      return fetch(url)
        .then(function (res) {
          return res.arrayBuffer()
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType })
        })
    },
  }

  return new Promise((resolve) => {
    base64Url = transform.rotate(base64Url, img)
    transform.convert(base64Url, 'rotate-' + img.name, img.type).then((fileData) => {
      img = fileData
      resolve({
        file: img,
        imageUrl: base64Url,
      })
    })
  })
}

const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB']
export const formatBytes = (bytes, precision = 2) => {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-'
  if (typeof precision === 'undefined') precision = 1

  const number = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number]
}
