import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Modal,
  Input,
  message,
  Space,
  Tooltip,
  Select,
  Tag,
} from 'antd'
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { EventCalendar, EventTag } from 'stores/types/event.types'
import { deleteEvent, findEventTags, getEventUsers } from 'services/event.service'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { User } from 'stores/types/user.types'

// import EventDrawer from './components/Drawer'
// import EventDrawerTag from './components/DrawerTag'

const { info } = Modal
const { Column } = Table
const { Search } = Input
const { Option } = Select

export interface ISearchState {
  searchText: string | undefined
  tagId: string | undefined
}

const EventUserPage = () => {
  const navigate = useNavigate()
  const { eventId } = useParams()
  const { setPageHeader } = useGlobalStore()
  const [users, setUsers] = useState<User[]>([])
  const [eventCalendars, setEventCalendars] = useState<EventCalendar>()
  const [eventTags, setEventTags] = useState<EventTag[]>([])
  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [loading, setLoading] = useState<boolean>(false)
  let isDitMount = true

  useEffect(() => {
    if (isDitMount) {
      console.log('findAllTags')
      findAllTags()
    }
  }, [])

  useEffect(() => {
    if (eventCalendars?.id) {
      let tags: any = []
      if (eventTags?.length) {
        tags = eventCalendars?.tags?.map((item) => {
          const tagItem: any = eventTags.find((f) => f.id === item)
          return (
            <Tag key={`tag-event-${item}-${tagItem.id}`} color={tagItem?.options?.color}>
              {tagItem?.name?.en}
            </Tag>
          )
        })
      }

      setPageHeader({
        title: eventCalendars?.name?.en,
        subTitle: `Date: ${dayjs(eventCalendars?.startDate).format('DD MMMM YYYY')}, Time: ${
          eventCalendars?.startTime
        } - ${eventCalendars?.endTime}`,
        tags: tags,
        backIcon: true,
        onBack: '/medical-education/event',
        extra: [
          <Button
            key={1}
            type='dashed'
            className='bg-white hover:bg-primary hover:text-white'
            onClick={() => {
              navigate('/medical-education/event')
            }}
          >
            Back
          </Button>,
        ],
      })
    }
  }, [eventCalendars?.id])

  const findAll = async (param = {}) => {
    setLoading(true)
    const query = {
      // ...pagination,
      ...sorter,
      ...param,
    }
    const payload: any = await getEventUsers(eventId, query)
    if (payload?.status?.code === 200) {
      setEventCalendars(payload.data)
      setUsers(payload.data?.users)
      if (!payload.data?.id) {
        info({
          title: 'No one joined the event calendar right now',
          onOk() {
            navigate('/medical-education/event')
          },
        })
      }
    }
    setLoading(false)
  }

  const findAllTags = async () => {
    isDitMount = false
    setLoading(true)
    const payload: any = await findEventTags()
    if (payload?.status?.code === 200) {
      setEventTags(payload.data)
      findAll()
    }
    setLoading(false)
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `event-${record?.id + ''}`}
            dataSource={users}
            className='mt-2 mb-2'
          >
            <Column
              title='Name'
              className='break-word'
              ellipsis
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>{record?.name?.en}</div>
                  <div className='text-md'>{record?.email}</div>
                </div>
              )}
            />
            <Column
              title='Phone'
              className='break-word'
              ellipsis
              width={150}
              render={(record) => (
                <div>
                  <div className='text-md'>{record?.phone}</div>
                </div>
              )}
            />

            <Column
              title='License'
              className='break-word'
              ellipsis
              width={150}
              render={(record) => (
                <div>
                  <div className='text-md'>{record?.license}</div>
                </div>
              )}
            />

            <Column
              title='Joined Date'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.createdAt).format('DD-MM-YYYY')}</div>
              )}
            />
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default EventUserPage
