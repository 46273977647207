import { useEffect, useState } from 'react'
import { Col, Row, Spin, Form, Input, Modal, message, PageHeader, FormInstance } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { upsertStaticPage } from 'services/staticPage.service'
import { pageTypeEnum } from 'enums/common.enum'

import TabByLanguage from './TabByLanguage'

const { confirm } = Modal
const heading = {
  [pageTypeEnum.PRIVACY_POLICY]: 'PAPA - Privacy policy',
  [pageTypeEnum.TERM_CONDITION]: 'Terms and conditions',
}
interface IProps {
  form: FormInstance
  onSuccess?: (item?: any) => void
  dataSource?: any
}

const FormSetting = ({ form, onSuccess, dataSource = {} }: IProps) => {
  const { languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValue = languages.map((item) => {
          const contentItem = values[item.locale]
          delete values[item.locale]
          return {
            ...contentItem,
            pageName: dataSource.pageName,
            pageType: dataSource.pageType,
            options: {},
            locale: item.locale,
            isActive: true,
          }
        })
        console.log('itemValue', itemValue)
        upsertStaticPage(itemValue)
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                onSuccess(response.data)
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  return (
    <>
      <Form
        form={form}
        name='setting-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Form.Item name='pageName' className='hidden'>
              <Input hidden />
            </Form.Item>
            <Form.Item name='pageType' className='hidden'>
              <Input hidden />
            </Form.Item>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='m-2 border border-solid rounded-md'>
              <div className='p-3'>
                <PageHeader
                  title={`${heading[dataSource.pageType]}`}
                  subTitle='Write content by language'
                  className='p-0'
                />
                <TabByLanguage form={form} dataSource={dataSource} />
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormSetting
