import { useEffect, useState } from 'react'
import { Form, FormInstance, Select } from 'antd'

// import dataGeographies from '../../../constants/addresses/geographies.json'
import dataSubDistrict from '../../../constants/addresses/subdistrict.json'
import dataDistrict from '../../../constants/addresses/district.json'
import dataProvince from '../../../constants/addresses/province.json'
import SelectAddress from './SelectAddress'
import { ISubDistrict, IDistrict, IProvince, IAddressValue } from 'interfaces/Address'
import { LanguageEnum } from 'enums/language.enum'

interface IFormAddressesProps {
  form: FormInstance
  name?: string
  locale?: string
  languages?: string[]
  showInput?: {
    district: boolean
    province: boolean
  }
  onSelect?: (item: any) => void
}

const FormAddresses = ({
  form,
  name = 'address',
  locale = LanguageEnum.TH,
  languages = [LanguageEnum.TH],
  showInput = { district: false, province: false },
  onSelect = undefined,
}: IFormAddressesProps) => {
  const [fieldName, setFieldName] = useState<string>(`name_${LanguageEnum.TH}`)
  const [selected, setSelected] = useState<any>()
  const [address, setAddress] = useState<any>({})

  const [districts] = useState<IDistrict[]>(dataDistrict)
  const [provinces] = useState<IProvince[]>(dataProvince)

  useEffect(() => {
    setFieldName(`name_${locale}`)
    // when open form for update address
    const formAddress = form.getFieldValue(name)
    console.log('formAddress2', formAddress)
    if (formAddress?.selected) {
      const selectValue = {
        value: formAddress.selected.id,
        label: formAddress.selected[locale],
      }
      console.log('selectValue2', selectValue)
      setSelected(selectValue)
      onSelectSubDistrict(formAddress.selected.id)
    }
  }, [locale])

  useEffect(() => {
    if (address?.subDistrict && address?.district && address?.province && address?.zipcode) {
      setFormValue()
    }
  }, [address])

  const setAddressValue = (field, value) => {
    setAddress((previous) => {
      return {
        ...previous,
        [field]: value,
      }
    })
  }

  const setFormValue = () => {
    let addressValue: IAddressValue = {}
    let seletedValue: IAddressValue = {}
    for (const lang of languages) {
      addressValue = {
        ...addressValue,
        [lang]: {
          zipCode: address.zipcode.zip_code,
          subDistrict: address.subDistrict[`name_${lang}`],
          district: address.district[`name_${lang}`],
          province: address.province[`name_${lang}`],
        },
      }
      seletedValue = {
        ...seletedValue,
        [lang]: address.subDistrict[`address_${lang}`],
        id: address.subDistrict.id,
      }
    }

    form.setFieldsValue({
      [name]: addressValue,
    })

    if (onSelect && typeof onSelect === 'function') {
      onSelect({
        address: addressValue,
        selected: seletedValue,
      })
    }
  }

  const onSelectSubDistrict = (value: number | undefined) => {
    if (value) {
      const findSubDistrict: ISubDistrict | undefined = dataSubDistrict.find((f) => f.id === +value)
      if (findSubDistrict) {
        onSelectDistrict(findSubDistrict.district_id)

        setAddressValue('zipcode', findSubDistrict)
        setAddressValue('subDistrict', findSubDistrict)
      }
    }

    return
  }

  const onSelectDistrict = (value: number | undefined) => {
    if (value) {
      const findDistrict: IDistrict | undefined = dataDistrict.find((f) => f.id === +value)
      if (findDistrict) {
        onSelectProvince(findDistrict.province_id)
        setAddressValue('district', findDistrict)
      }
    }
    return
  }

  const onSelectProvince = (value: number | undefined) => {
    if (value) {
      const findProvince: IProvince | undefined = dataProvince.find((f) => f?.id === +value)
      if (findProvince) {
        setAddressValue('province', findProvince)
      }
    }
    return
  }

  return (
    <div>
      <Form.Item
        label='Search Address'
        name={[name, locale, 'subDistrict']}
        rules={[{ required: true, message: 'Please select addresses' }]}
      >
        <SelectAddress
          selected={selected}
          onSelect={(item) => {
            setSelected(item)
            onSelectSubDistrict(item.value)
          }}
        />
      </Form.Item>

      <div className='grid grid-cols-2 gap-x-4'>
        {!!showInput?.district && (
          <Form.Item
            label='District'
            name={[name, locale, 'district']}
            rules={[{ required: true, message: 'Please input district!' }]}
          >
            <Select
              size='large'
              showSearch
              allowClear
              onSelect={onSelectDistrict}
              onClear={() => onSelectDistrict(undefined)}
              disabled={true}
              optionFilterProp='title'
            >
              {districts.map((item: IDistrict) => {
                return (
                  <Select.Option
                    value={item?.id}
                    title={item[fieldName]}
                    key={`district_${item?.id}`}
                  >
                    {item[fieldName]}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        )}

        {!!showInput?.province && (
          <Form.Item
            label='Province'
            name={[name, locale, 'province']}
            rules={[{ required: true, message: 'Please input province!' }]}
          >
            <Select
              size='large'
              showSearch
              allowClear
              onSelect={onSelectProvince}
              onClear={() => onSelectProvince(undefined)}
              disabled={true}
              optionFilterProp='title'
            >
              {provinces.map((item: IProvince) => {
                return (
                  <Select.Option
                    value={item?.id}
                    title={item[fieldName]}
                    key={`province_${item?.id}`}
                  >
                    {item[fieldName]}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        )}
      </div>
    </div>
  )
}

export default FormAddresses
