import { Form, FormInstance, Input } from 'antd'
import { useEffect, useState } from 'react'
import { debounce } from 'utils/common'

import Editor from 'components/features/editor/QuillEditor'
import Tags from 'components/features/tag'

interface IFormContentProps {
  form: FormInstance
  lang: string
  dataSource: any
}

const FormContent = ({ form, lang, dataSource }: IFormContentProps) => {
  const [tags, setTags] = useState<string[]>([])
  const [content, setContent] = useState<string>()

  useEffect(() => {
    if (dataSource?.id && dataSource?.articleId) {
      form.setFieldValue(lang, dataSource)
      setTags(dataSource.tags)
      setContent(dataSource.content)
    } else {
      setContent(' ')
    }
  }, [])

  const rteChange = debounce(function (value: any) {
    setContent(value)
  }, 10)

  const handleTags = (tagValues) => {
    const formValue = form.getFieldValue(lang)
    if (formValue) {
      formValue.tags = tagValues
      form.setFieldValue(lang, formValue)
    }
  }

  return (
    <>
      <div className='form-social'>
        <Form.Item name={[lang, 'id']} className='hidden'>
          <Input hidden />
        </Form.Item>

        <Form.Item name={[lang, 'articleId']} className='hidden'>
          <Input hidden />
        </Form.Item>

        <Form.Item
          name={[lang, 'title']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: `please input phrase of content ${lang.toUpperCase()}`,
            },
          ]}
        >
          <Input size='large' placeholder={`Phrase ${lang.toUpperCase()}`} />
        </Form.Item>

        <Form.Item
          name={[lang, 'caption']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: `please input a short description ${lang.toUpperCase()}`,
            },
          ]}
        >
          <Input.TextArea
            size='large'
            placeholder={`A short description ${lang.toUpperCase()}`}
            rows={3}
          />
        </Form.Item>

        <Form.Item
          name={[lang, 'content']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: `please input content ${lang.toUpperCase()}`,
            },
          ]}
        >
          {!!content && (
            <Editor
              content={content}
              onChange={(value) => rteChange(value)}
              image={true}
              folder={'article'}
              className='editor-content'
            />
          )}
        </Form.Item>
      </div>
    </>
  )
}

export default FormContent
