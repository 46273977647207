import { useEffect, useState } from 'react'
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Col, Row, Spin, Modal, message, notification } from 'antd'
import { createStaticPage, updateStaticPage } from 'services/staticPage.service'
import { debounce } from 'utils/common'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'
import { useAuth } from 'stores/authorized'

import Editor from 'components/features/editor/QuillEditor'
import ViewHtmlEditor from 'components/features/editor/ViewHtmlEditor'
import WraperEditable from 'components/features/wraperEditable'
import PermissionAccess from 'components/PermissionAccess'

interface IFormHtmlEditableProps {
  lang?: string
  staticPageId?: number
  dataSource?: any
}

const optionItems = {
  isEdiable: false,
  html: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
}

const { confirm } = Modal
const FormHtmlEditable = ({ dataSource, staticPageId, lang }: IFormHtmlEditableProps) => {
  const { scope } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>(optionItems)

  useEffect(() => {
    if (staticPageId) {
      setOptions({
        ...dataSource?.options,
        isEdiable: false,
      })
    }
  }, [lang])

  const onFinish = (lang) => {
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValues = {
          pageName: pageNameEnum.ABOUT,
          pageType: pageTypeEnum.FACILITIES,
          isActive: true,
          locale: lang,
          options,
        }
        const optionValues = options
        delete optionValues.isEditable

        const formData = new FormData()
        formData.append('id', `${staticPageId}`)
        formData.append('pageName', pageNameEnum.ABOUT)
        formData.append('pageType', pageTypeEnum.FACILITIES)
        formData.append('locale', lang)
        formData.append('options', JSON.stringify(optionValues))

        console.log(itemValues)
        const promise = staticPageId
          ? updateStaticPage(staticPageId, formData)
          : createStaticPage(formData)
        promise
          .then((response) => {
            setLoading(false)
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const rteChange = debounce(function (event: any) {
    setOptions({
      ...options,
      html: event.value,
    })
  }, 200)

  const setEditable = (isEdiable) => {
    setOptions({
      ...options,
      isEdiable,
    })
  }

  return (
    <>
      <Spin
        tip='Loading...'
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row gutter={16} className='mb-10'>
          <Col span={24}>
            <div className='relative group'>
              {options.isEdiable ? (
                <Editor
                  image={true}
                  content={options.html}
                  onChange={(value) =>
                    rteChange({
                      field: 'html',
                      value,
                    })
                  }
                  className='editor-content'
                />
              ) : (
                <PermissionAccess permission={'about_us:update'} scope={scope}>
                  <ViewHtmlEditor html={options.html} />
                </PermissionAccess>
              )}

              {!options.isEdiable && <WraperEditable onClick={() => setEditable(true)} />}
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className='text-right mt-3'>
            <PermissionAccess permission={'about_us:update'} scope={scope}>
              <Button type='primary' onClick={() => onFinish(lang)}>
                Save data in [{lang?.toUpperCase()}]
              </Button>
            </PermissionAccess>
          </Col>
        </Row>
      </Spin>
    </>
  )
}

export default FormHtmlEditable
