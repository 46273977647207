import { safeContent } from 'utils/common'

interface IProps {
  html: string
  className?: string
}
const ViewHtmlEditor = ({ html, className = '' }: IProps) => {
  return (
    <div className={`editor-content ${className}`}>
      <div className='quill'>
        <div className='ql-container ql-snow'>
          <div className='ql-editor'>
            <div dangerouslySetInnerHTML={safeContent(html)}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewHtmlEditor
