import { Button, Divider, Form, FormInstance, Input, Select, Modal, message, Spin } from 'antd'
import {
  PlusOutlined,
  PictureOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useProductStore } from 'stores/product'
import {
  deleteImageProductInfo,
  deleteProductInfo,
  updateProductInfoList,
} from 'services/product.service'

import Editor from 'components/features/editor/QuillEditor'
import FileUpload from 'components/features/upload/Upload'

const { confirm } = Modal

enum LayoutEditorEnum {
  NO_IMAGHE = 'NO_IMAGHE',
  IMAGE_RIGHT = 'IMAGE_RIGHT',
  IMAGE_LEFT = 'IMAGE_LEFT',
  IMAGE_BOTTOM = 'IMAGE_BOTTOM',
  IMAGE_TOP = 'IMAGE_TOP',
  ONLY_IMAGE = 'ONLY_IMAGE',
}
const LayoutOptions = [
  {
    value: LayoutEditorEnum.NO_IMAGHE,
    label: 'Show only content',
  },
  {
    value: LayoutEditorEnum.IMAGE_RIGHT,
    label: 'Show image on the right side of the content',
  },
  {
    value: LayoutEditorEnum.IMAGE_LEFT,
    label: 'Show image on the left side of the content',
  },
  {
    value: LayoutEditorEnum.IMAGE_BOTTOM,
    label: 'Show image under the content',
  },
  {
    value: LayoutEditorEnum.IMAGE_TOP,
    label: 'Show image over the content',
  },
  {
    value: LayoutEditorEnum.ONLY_IMAGE,
    label: 'Show only image',
  },
]

interface IFormLayoutEditorProps {
  form: FormInstance
  lang: string
  index: number
  name: any
  restField: any
  dataSource?: any
}
const FormLayoutEditor = ({
  form,
  lang,
  index,
  name,
  restField,
  dataSource,
}: IFormLayoutEditorProps) => {
  const { productInfo, setProductInfo } = useProductStore()
  const [layoutClass, setLayoutClass] = useState<string>('')
  const [content, setContent] = useState<string>()
  const [layout, setLayout] = useState<string>(LayoutEditorEnum.NO_IMAGHE)
  const [productInfoId, setProductInfoId] = useState<number>()

  useEffect(() => {
    if (productInfo?.[index]) {
      const infoData = productInfo[index]
      setProductInfoId(infoData?.infoId)
      setContent(infoData?.description)
      changeLayout(infoData?.layout)
      if (infoData?.infoId && infoData?.images?.length) {
        setImageUrl(infoData?.images[0])
      }
      if (infoData?.imageUrl) {
        setImageUrl(infoData?.imageUrl)
      }
    } else {
      setContent(' ')
    }
  }, [])

  const changeLayout = (value: string) => {
    const template = LayoutEditorEnum[value]
    setLayout(template)
    classesLayout(template)
  }

  const handleSetContent = (value) => {
    setContent(value)
    const info = form.getFieldValue('info')
    if (info.length) {
      info[index].description = value
      setProductInfo(info)
    }
  }

  const classesLayout = (layout) => {
    let className = ''
    if ([LayoutEditorEnum.IMAGE_LEFT, LayoutEditorEnum.IMAGE_RIGHT].includes(layout)) {
      className += 'flex items-stretch gap-4'
    }
    if (LayoutEditorEnum.IMAGE_TOP === layout) {
      className += 'flex flex-col-reverse'
    }
    setLayoutClass(className)
  }

  const [imageUrl, setImageUrl] = useState<string | undefined>()
  const onUpload = (upload) => {
    setImageUrl(upload.imageUrl)

    const info = form.getFieldValue('info')
    if (info.length) {
      info[index].imageUrl = upload.imageUrl
      info[index].fileUpload = upload.file
      setProductInfo(info)
    }
  }

  const onDelete = (file) => {
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && productInfoId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          deleteImageProductInfo(productInfoId)
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
                setImageUrl(undefined)
                const info = form.getFieldValue('info')
                if (info.length) {
                  info[index].images = []
                  setProductInfo(info)
                }
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      setImageUrl(undefined)
      const info = form.getFieldValue('info')
      if (info.length) {
        info[index].fileUpload = undefined
        info[index].imageUrl = undefined
        setProductInfo(info)
      }
    }
  }

  return (
    <div className='p-3'>
      <div className='flex gap-2'>
        <Form.Item name={[name, 'locale']} {...restField} className='hidden'>
          <Input className='hidden' />
        </Form.Item>

        <Form.Item
          {...restField}
          name={[name, 'subTitle']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: 'please input section title',
            },
          ]}
          className='w-2/3'
        >
          <Input size='large' placeholder='Title' />
        </Form.Item>

        <Form.Item
          {...restField}
          name={[name, 'layout']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: 'please select template layout',
            },
          ]}
          className='w-1/3'
        >
          <Select onChange={changeLayout} options={LayoutOptions} size='large' />
        </Form.Item>
      </div>

      <div className={layoutClass}>
        {LayoutEditorEnum.ONLY_IMAGE !== layout && (
          <Form.Item
            {...restField}
            name={[name, 'description']}
            fieldKey={[name, 'description']}
            className={`${LayoutEditorEnum.IMAGE_LEFT === layout ? 'order-last' : ''}`}
            style={{
              width: [LayoutEditorEnum.IMAGE_LEFT, LayoutEditorEnum.IMAGE_RIGHT].includes(
                layout as LayoutEditorEnum,
              )
                ? '50%'
                : 'auto',
            }}
          >
            {!!content && (
              <Editor
                content={content}
                onChange={(value) => handleSetContent(value)}
                image={false}
                paramId={dataSource?.productInfoId}
                folder={'product'}
                className='editor-content'
              />
            )}
          </Form.Item>
        )}
        {LayoutEditorEnum.NO_IMAGHE !== layout && (
          <div
            className='upload-full-w group h-full mt-3 mb-3'
            style={{
              width: [LayoutEditorEnum.IMAGE_LEFT, LayoutEditorEnum.IMAGE_RIGHT].includes(
                layout as LayoutEditorEnum,
              )
                ? '50%'
                : 'auto',
            }}
          >
            <Form.Item {...restField} name={[name, lang, 'image']} className='h-full'>
              {imageUrl ? (
                <div className='relative group'>
                  <img src={imageUrl} className='w-auto max-w-full m-auto' />
                  <div className='absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 group-hover:opacity-40 transition duration-300 ease-in-out bg-white'></div>
                  <div className='opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out p-3 flex gap-2 items-center absolute w-full h-full top-0 left-0 rounded-md'>
                    <div className='bg-white m-auto p-1 flex items-center rounded-md'>
                      <Button
                        type='primary'
                        size='small'
                        className=''
                        onClick={() => onDelete(imageUrl)}
                      >
                        <DeleteOutlined className='text-base relative -top-1' /> Delete
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <FileUpload
                  field='image'
                  listType='CARD'
                  onUpload={onUpload}
                  onDelete={(file) => onDelete(file)}
                  imageUrl={imageUrl}
                  className='h-full'
                >
                  <div
                    style={{
                      minHeight: [
                        LayoutEditorEnum.IMAGE_LEFT,
                        LayoutEditorEnum.IMAGE_RIGHT,
                      ].includes(layout as LayoutEditorEnum)
                        ? '356px'
                        : '150px',
                    }}
                    className='flex flex-col justify-center items-center w-full text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'
                  >
                    <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                    <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                      Browse to find or drag <span className='font-bold underline'>Image</span> here
                    </div>
                  </div>
                </FileUpload>
              )}
            </Form.Item>
          </div>
        )}
      </div>
    </div>
  )
}

interface IFormProductInfoProps {
  form: FormInstance
  lang: string
  productId?: number
  dataSource?: any
  onSuccess: (item) => void
}
const FormProductInfo = ({ form, lang, productId, onSuccess }: IFormProductInfoProps) => {
  const { productInfo, setProductInfo } = useProductStore()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  const onFinish = (values: any) => {
    const itemValue = {
      ...values,
      productId,
    }

    console.log(itemValue)
    if (!productId) {
      form
        .validateFields()
        .then(() => {
          onSuccess(productInfo)
        })
        .catch((err) => {
          console.log(err)
        })
      return
    }

    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const formData = new FormData()
        formData.append('productId', productId.toString())

        let index = 0
        for (const info of itemValue.info) {
          if (info.fileUpload) {
            const file = info.fileUpload
            formData.append(
              'files[]',
              file,
              `PRODUCT_INFO-${info.locale}-${index}-ITOON-${file.name}`,
            )
            delete info.fileUpload
            delete info.imageUrl
          }
          const productInfoObj = { ...info, name: info.subTitle, order: index }
          formData.append('info[]', JSON.stringify(productInfoObj))
          index++
        }

        console.log('itemValue', itemValue)

        updateProductInfoList(formData)
          .then((response) => {
            setLoading(false)
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                onSuccess(response.data)
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const onRemove = (index, callback) => {
    const dataInfo = productInfo[index]
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: `Permanently delete this section ${index + 1}`,
      onOk() {
        if (dataInfo?.infoId) {
          deleteProductInfo(dataInfo?.infoId)
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
                const info = form.getFieldValue('info')
                setProductInfo(info)
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        }
        callback()
      },
    })
  }

  const scrollToElement = (id) => {
    setTimeout(() => {
      const element: HTMLElement | null = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }, 500)
  }

  const defaultValue = {
    layout: LayoutEditorEnum.NO_IMAGHE,
    locale: lang,
    subTitle: '',
    description: ' ',
    order: 0,
  }
  return (
    <>
      <Form
        form={form}
        name='productInfo-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          info: [defaultValue],
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <div className='form-product-info'>
            <Form.List
              name='info'
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error('At least 1 description'))
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key} className='product-info'>
                      <FormLayoutEditor
                        form={form}
                        lang={lang}
                        index={index}
                        name={name}
                        restField={restField}
                      />

                      <Divider orientation='right' orientationMargin='0'>
                        End section {index + 1} -
                        <Button
                          id={`remove-${index}`}
                          type='dashed'
                          className='mx-1 border-none hover:border-solid hover:bg-primary hover:text-white'
                          onClick={() => {
                            onRemove(index, () => {
                              remove(name)
                            })
                          }}
                          size='small'
                          shape='round'
                        >
                          <span className='font-bold'>Delete</span>
                        </Button>
                      </Divider>
                    </div>
                  ))}

                  <Form.Item className='text-right hidden'>
                    <Button
                      id='create-info'
                      type='dashed'
                      size='small'
                      className='w-[150px] hover:bg-primary hover:text-white'
                      onClick={() => {
                        add(defaultValue)
                        setProductInfo([...productInfo, defaultValue])
                        scrollToElement(`remove-${productInfo.length}`)
                      }}
                      icon={<PlusOutlined />}
                    >
                      Add Product info
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </Spin>
      </Form>
    </>
  )
}

export default FormProductInfo
