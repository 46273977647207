export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function debounce(func: any, wait: any, immediate: any = undefined) {
  let timeout: number | undefined | ReturnType<typeof setTimeout>
  return function (this: unknown, ...args: any) {
    const context = this
    // const args = arguments
    const later = function () {
      timeout = undefined
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

/**
 * utils to remove all XSS  attacks potential
 * @param {String} html
 * @return {Object}
 */
export const safeContent = (html) => {
  if (html) {
    const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi

    // Removing the <script> tags
    while (SCRIPT_REGEX.test(html)) {
      html = html.replace(SCRIPT_REGEX, '')
    }

    // Removing all events from tags...
    html = html.replace(/ on\w+="[^"]*"/g, '')
  }

  return {
    __html: html,
  }
}

export const formatNumber = (n, frac = 0, max = 2) => {
  const intl = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: frac,
    maximumFractionDigits: max,
  })
  return intl.format(n)
}
