import fetch from '../utils/fetchAxios'
// import { fetchUpload, getTotalChunkSize } from "../utils/fetchChunkUpload"

// export const upload = async (data = {}, options) => {
//   const path = '/api/v1/upload'
//   return await fetchUpload(data, path, options)
// }

export const uploadImage = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/upload/image'
  return await fetch(method, path, body)
}

export const deleteFile = async (body) => {
  const method = 'DELETE'
  const path = '/v1/upload'
  return fetch(method, path, body)
}
