import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Tag,
  Modal,
  Input,
  message,
  Space,
  Tooltip,
  Select,
} from 'antd'
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { Faq, FaqCategory } from 'stores/types/faq.types'
import { deleteFaq, findFaqCategories, findFaqs } from 'services/faq.service'

import FaqDrawer from './components/Drawer'
import FaqDrawerCategory from './components/DrawerCategory'
import { useAuth } from 'stores/authorized'
import PermissionAccess from 'components/PermissionAccess'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input
const { Option } = Select

export interface ISearchState {
  searchText: string | undefined
  categoryId: string | undefined
}

const FAQPage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenCategory, setIsOpenCategory] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Faq | undefined>()
  const [faqs, setFaqs] = useState<Faq[]>([])
  const [categories, setCategories] = useState<FaqCategory[]>([])

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })

  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
    categoryId: undefined,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    findAll()
    findAllCategories()
  }, [])

  useEffect(() => {
    setPageHeader({
      title: 'FAQ Overview',
      subTitle: 'Content Management',
      backIcon: false,
      extra: [
        <PermissionAccess key={1} permission='article:create' scope={scope}>
          <Button
            type='default'
            onClick={() => {
              setIsOpenCategory(true)
            }}
          >
            categories
          </Button>
        </PermissionAccess>,
        <PermissionAccess key={2} permission='article:create' scope={scope}>
          <Button
            type='primary'
            onClick={() => {
              setIsOpen(true)
              setDataSource(undefined)
            }}
          >
            create new Q&A
          </Button>
        </PermissionAccess>,
      ],
    })
  }, [scope?.[0]])

  const findAll = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      ...param,
    }
    const payload: any = await findFaqs(body)
    if (payload?.status?.code === 200) {
      setFaqs(payload.data?.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }
    setLoading(false)
  }

  const findAllCategories = async () => {
    setLoading(true)
    const payload: any = await findFaqCategories()
    if (payload?.status?.code === 200) {
      setCategories(payload.data)
    }
    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findAll({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteFaq(item.id)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findAll()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const onSearchCategory = (value) => {
    const searching = {
      ...search,
      categoryId: value,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      categoryId: undefined,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findAll({
      search,
    })
  }

  const onCloseCategory = () => {
    setIsOpenCategory(false)
    findAllCategories()
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Space className='flex' align='start'>
            <Search
              allowClear
              size='large'
              placeholder='Search..'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />

            <Select
              size='large'
              placeholder='Choose categories'
              className='rounded-md'
              disabled={loading}
              allowClear
              onChange={onSearchCategory}
              value={search.categoryId}
              style={{ width: 200 }}
            >
              {categories.length &&
                categories.map((item: FaqCategory, index: number) => {
                  return (
                    <Option key={`search-categoryId-${index}`} value={item.categoryId}>
                      {item?.name?.en}
                    </Option>
                  )
                })}
            </Select>
            <Button
              type='link'
              onClick={clearSearch}
              disabled={!search.searchText && !search.categoryId}
            >
              clear
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `faq-${record?.id + ''}`}
            dataSource={faqs}
            className='mt-2 mb-2'
          >
            <Column
              title='Q&A'
              className='break-word'
              ellipsis
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>{record?.question?.en}</div>
                  <div className='text-md font-normal truncate overflow-hidden'>
                    {record?.answer?.en}
                  </div>
                </div>
              )}
            />
            <Column
              title='Category'
              className='break-word'
              align='center'
              width={250}
              render={(record) => <div className='text-center'>{record.category.name?.en}</div>}
            />

            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.createdAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.updatedAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='status'
              align='center'
              className='break-word text-center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {record?.isActive ? (
                    <Tag color='processing' className='m-0'>
                      Published
                    </Tag>
                  ) : (
                    <Tag color='default' className='m-0'>
                      Unpublished
                    </Tag>
                  )}
                </div>
              )}
            />

            <Column
              width={100}
              render={(record) => (
                <PermissionAccess permission='article:update' scope={scope}>
                  <div className='flex gap-2'>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        shape='circle'
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </div>
                </PermissionAccess>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <FaqDrawer
        onClose={onClose}
        onSuccess={onClose}
        isOpen={isOpen}
        dataSource={dataSource}
        categories={categories}
      />

      <FaqDrawerCategory
        onClose={onCloseCategory}
        onSuccess={onCloseCategory}
        isOpen={isOpenCategory}
      />
    </>
  )
}

export default FAQPage
