import { useEffect, useState } from 'react'
import {
  HomeOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Button, Col, Pagination, Row, Table, Tag, Modal, Input, message, Tooltip } from 'antd'
import dayjs from 'dayjs'
import BannerDrawer from './components/Drawer'
import { Banner } from 'stores/types/banner.types'
import { useGlobalStore } from 'stores/global'
import { deleteStaticPage, findAllStaticPages } from 'services/staticPage.service'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'
import { useAuth } from 'stores/authorized'
import PermissionAccess from 'components/PermissionAccess'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input

export interface ISearchState {
  searchText: string | undefined
}

const BannerPage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Banner>()
  const [bannerList, setBannerList] = useState<Banner[]>([])

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })

  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    findHomeBanners()
  }, [])

  useEffect(() => {
    setPageHeader({
      title: 'Home Overview',
      subTitle: 'Seasonal Banners',
      onBack: '#',
      backIcon: <HomeOutlined />,
      extra: [
        <PermissionAccess key={1} permission={'home:create'} scope={scope}>
          <Tooltip title='create new banner'>
            <Button
              type='primary'
              shape='round'
              icon={<PlusOutlined className='text-base relative -top-0.5' />}
              onClick={() => {
                setIsOpen(true)
                setDataSource(undefined)
              }}
            >
              create banner
            </Button>
          </Tooltip>
        </PermissionAccess>,
      ],
    })
  }, [scope?.[0]])

  const findHomeBanners = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      search: {
        ...param,
        pageName: pageNameEnum.HOME,
        pageType: pageTypeEnum.BANNER,
      },
    }
    const payload: any = await findAllStaticPages(body)
    if (payload?.status?.code === 200) {
      setBannerList(payload.data.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }
    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findHomeBanners({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteStaticPage(item.id)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findHomeBanners()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findHomeBanners({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
    }
    setSearch(searching)
    findHomeBanners({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findHomeBanners()
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <div className='flex items-center'>
            <Search
              allowClear
              size='large'
              placeholder='Searching...'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />
            <Button type='link' onClick={clearSearch}>
              Clear
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `banner_${record?.id}`}
            dataSource={bannerList}
            className='mt-2 mb-2'
          >
            <Column
              width={250}
              render={(record) => (
                <div>
                  <img
                    src={record?.images?.[0]}
                    className='w-[200px] object-contain border border-solid p-1'
                  />
                </div>
              )}
            />

            <Column
              title='Banners Options'
              className='break-word'
              align='left'
              render={(record) => (
                <>
                  <div className='pb-1'>
                    {record?.options?.en?.title ? (
                      <div>
                        <div className='text-md font-bold'>{record?.options?.en?.title}</div>
                        <div className='text-sm truncate text-gray-500'>
                          {record?.options?.en?.description}
                        </div>
                      </div>
                    ) : (
                      <div className='text-sm truncate text-gray-500'>
                        Option Text require is not configured
                      </div>
                    )}
                  </div>
                  <div className='py-1 border-t border-solid border-gray-300'>
                    <div className='text-left flex gap-2'>
                      <div className='text-sm font-bold'>Setting:</div>
                      <div className='text-sm truncate text-gray-500'>
                        {record?.options?.isRequired
                          ? record?.options?.display === 'RIGHT'
                            ? 'Shown text on the right of ther banner'
                            : 'Shown text on the left of ther  banner'
                          : '-'}
                      </div>
                    </div>
                  </div>
                </>
              )}
            />
            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {dayjs(record?.createdAt).format('DD-MM-YYYY hh:mm:ss')}
                </div>
              )}
            />
            <Column
              title='Last updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {dayjs(record?.updatedAt).format('DD-MM-YYYY hh:mm:ss')}
                </div>
              )}
            />
            <Column
              title='status'
              align='center'
              className='break-word text-center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {record?.isActive ? (
                    <Tag color='processing' className='m-0'>
                      Published
                    </Tag>
                  ) : (
                    <Tag color='default' className='m-0'>
                      Unpublished
                    </Tag>
                  )}
                </div>
              )}
            />

            <Column
              width={100}
              render={(record) => (
                <PermissionAccess permission={'home:update'} scope={scope}>
                  <div className='flex gap-2'>
                    <Tooltip title='Manage banner'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        shape='circle'
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </div>
                </PermissionAccess>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <BannerDrawer onClose={onClose} onSuccess={onClose} isOpen={isOpen} dataSource={dataSource} />
    </>
  )
}

export default BannerPage
