import { useEffect, useState } from 'react'
import {
  Col,
  Row,
  Spin,
  Form,
  Input,
  Modal,
  Switch,
  message,
  FormInstance,
  Radio,
  RadioChangeEvent,
  notification,
} from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined, PictureOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { createStaticPage, updateStaticPage } from 'services/staticPage.service'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'

import FileUpload from 'components/features/upload/Upload'
import FromBannerByLanguage from './FromBannerByLanguage'

const enum DisplayEnum {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}
const { confirm } = Modal

interface IProps {
  form: FormInstance
  bannerId?: number
  onSuccess?: (item?: any) => void
  dataSource?: any
}

const FormBanner = ({ form, bannerId, onSuccess, dataSource = {} }: IProps) => {
  const { languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(true)
  const [isRequired, setIsRequired] = useState<boolean>(true)
  const [display, setDisplay] = useState<DisplayEnum>(
    dataSource?.options?.display || DisplayEnum.RIGHT,
  )

  useEffect(() => {
    setLoading(false)
    if (bannerId) {
      setImageUrl(dataSource?.images?.[0])
    }
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    if (!fileUpload && !bannerId) {
      notification.error({ message: 'Plaese upload seasonal banner image!' })
      return
    }
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        let options = {
          display: values.display,
          isRequired: values.isRequired,
        }
        for (const lang of languages) {
          options = {
            ...options,
            [lang.locale]: {
              title: values[lang.locale]?.[0].title,
              description: values[lang.locale]?.[0].description,
            },
          }
        }
        const formData = new FormData()
        formData.append('isActive', values?.isActive)
        formData.append('pageName', pageNameEnum.HOME)
        formData.append('pageType', pageTypeEnum.BANNER)
        formData.append('locale', 'all')
        formData.append('options', JSON.stringify(options))

        if (fileUpload) {
          formData.append('files[]', fileUpload, `BANNER-ITOON-${fileUpload.name}`)
        }

        const promise = bannerId ? updateStaticPage(bannerId, formData) : createStaticPage(formData)
        promise
          .then((response) => {
            setLoading(false)
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                onSuccess(response.data)
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const [imageUrl, setImageUrl] = useState<string>()
  const [fileUpload, setFileUpload] = useState<File>()

  const onUpload = (upload) => {
    setImageUrl(upload.imageUrl)
    setFileUpload(upload.file)
  }

  const onDelete = (file) => {
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && bannerId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          updateStaticPage(bannerId, {
            images: null,
          })
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
                setImageUrl(undefined)
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      setImageUrl(undefined)
      setFileUpload(undefined)
    }
  }

  return (
    <>
      <Form
        form={form}
        name='banner-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
          isRequired: true,
          display: DisplayEnum.RIGHT,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Form.Item name='id'>
              <Input hidden />
            </Form.Item>

            <Col span={24}>
              <div className='upload-full-w group mb-3 relative'>
                <FileUpload
                  onUpload={onUpload}
                  onDelete={(file) => onDelete(file)}
                  imageUrl={imageUrl}
                  listType='CARD'
                  className=''
                >
                  <div className='w-full min-h-[380px] flex flex-col items-center justify-center text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                    <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                    <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                      Browse to find or drag <span className='font-bold underline'>Banner</span>{' '}
                      here
                    </div>
                  </div>
                </FileUpload>
                {imageUrl &&
                  isRequired &&
                  (display === DisplayEnum.RIGHT ? (
                    <div className='opacity-100 group-hover:opacity-0 group-hover:-z-10 transition duration-500 ease-in-out absolute top-1/2 left-1/2 w-full mx-auto h-full -translate-x-1/2 -translate-y-1/2'>
                      <div className='z-10 shadow-md rounded-lg bg-primary bg-opacity-50 md:bg-opacity-100 text-white absolute top-[15%] bottom-auto right-[10%] w-[30%] p-8'>
                        <div>
                          <h1 className='font-bold text-white text-2xl mb-5'>Aestema Aesthetice</h1>
                          <div className='line-clamp-3 text-ellipsis overflow-auto animate__animated animate__fadeInLeft'>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='opacity-100 group-hover:opacity-0 group-hover:-z-10 transition duration-500 ease-in-out absolute top-1/2 left-1/2 w-full mx-auto h-full -translate-x-1/2 -translate-y-1/2'>
                      <div className='z-10 shadow-md rounded-lg bg-primary bg-opacity-50 md:bg-opacity-100 text-white absolute top-[15%] bottom-auto left-[10%] w-[30%] p-8'>
                        <div>
                          <h1 className='font-bold  text-white text-2xl mb-5'>
                            Aestema Aesthetice
                          </h1>
                          <div className='line-clamp-3 md:block text-ellipsis overflow-auto animate__animated animate__fadeInRight'>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>

            <Col span={24}>
              <FromBannerByLanguage form={form} dataSource={dataSource} isRequired={isRequired} />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={16} className='mt-5'>
              <div className='flex items-center'>
                <Form.Item name='isRequired' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked onClick={() => setIsRequired(!isRequired)} />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which banner text will be shown on status
                  (Active/InActive)
                </div>
              </div>
            </Col>
            <Col span={8} className='mt-5'>
              <div className='flex items-center'>
                <Form.Item name='display'>
                  <Radio.Group
                    options={[
                      { label: 'Show on Left', value: DisplayEnum.LEFT },
                      { label: 'Show on Right', value: DisplayEnum.RIGHT },
                    ]}
                    onChange={({ target: { value } }: RadioChangeEvent) => setDisplay(value)}
                    optionType='button'
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='mt-5'>
              <div className='flex items-center'>
                <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which data will be listed on status (Active/InActive)
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormBanner
