import create from 'zustand'
import { Product, ProductInfo, ProductItem } from './types/product.types'

type State = {
  products: Product[]
  setProducts: (product: Product[]) => void

  productInfo: ProductInfo[]
  setProductInfo: (info: ProductInfo[]) => void

  productItems: ProductItem[]
  setProductItems: (items: ProductItem[]) => void
}

export const useProductStore = create<State>((set) => ({
  products: [],
  setProducts: (product) =>
    set(() => ({
      products: product,
    })),

  productInfo: [],
  setProductInfo: (info) =>
    set(() => ({
      productInfo: info,
    })),

  productItems: [],
  setProductItems: (items) =>
    set(() => ({
      productItems: items,
    })),
}))
