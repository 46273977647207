import fetch from '../utils/fetchAxios'

export const findAllStaticPages = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/static-page'
  return fetch(method, path, query)
}

export const createStaticPage = async (body) => {
  const method = 'POST'
  const path = '/v1/static-page'
  return fetch(method, path, body)
}

export const updateStaticPage = async (id: number, body) => {
  const method = 'PATCH'
  const path = `/v1/static-page/${id}`
  return fetch(method, path, body)
}

export const upsertStaticPage = async (body) => {
  const method = 'PATCH'
  const path = '/v1/static-page/upsert'
  return fetch(method, path, body)
}

export const deleteStaticPage = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/static-page/${id}`
  return fetch(method, path, query)
}
