import React, { useMemo, useRef, useState } from 'react'
import { Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'

import dataSubDistrict from '../../../constants/addresses/subdistrict.json'

function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
}

function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 300, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      showSearch={true}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      {...props}
      options={options}
      size='large'
    />
  )
}

// Usage of DebounceSelect
interface IFetchAddessList {
  label: string
  value: number | string
}

async function fetchAddressList(search: string): Promise<IFetchAddessList[]> {
  console.log('fetching address', search)

  return dataSubDistrict
    .filter((item) => item.address_th.includes(search))
    .map((item) => ({
      label: item.address_th,
      value: item.id,
    }))
}

interface ISelectAddressProps {
  selected?: any
  onSelect: (value: any) => void
}

const SelectAddress = ({ selected, onSelect }: ISelectAddressProps) => {
  return (
    <DebounceSelect
      value={selected}
      placeholder='Select addresses'
      fetchOptions={fetchAddressList}
      onChange={(newValue) => {
        onSelect(newValue)
      }}
      className='w-full'
    />
  )
}

export default SelectAddress
