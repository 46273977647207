import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Col, Row, Spin, Modal, message } from 'antd'
import { createStaticPage, updateStaticPage } from 'services/staticPage.service'
import { debounce, formatNumber, safeContent } from 'utils/common'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'
import { useAuth } from 'stores/authorized'

import WraperEditable from 'components/features/wraperEditable'
import PermissionAccess from 'components/PermissionAccess'

interface IFormHtmlEditableProps {
  lang?: string
  staticPageId?: number
  dataSource?: any
  ref: any
}

const optionItems = {
  isEdiable: false,
  title: 'Aestema',
  description: 'Lorem Ipsum is simply<br /> dummy text of the printing',
  type: 'string',
}

const { confirm } = Modal
// eslint-disable-next-line react/display-name
const FormHtmlEditable = forwardRef(
  ({ dataSource, staticPageId, lang }: IFormHtmlEditableProps, ref) => {
    const { scope } = useAuth()
    const [loading, setLoading] = useState<boolean>(false)
    const [options, setOptions] = useState<any>([
      { title: 'ZERO', description: 'Serious side effects<br /> reported', type: 'string' },
      { title: '1958511', description: 'Syringes sold<br /> sine 2017', type: 'number' },
      {
        title: '1%',
        description: 'Lowest MOD level<br /> among monophasic fillers',
        type: 'string',
      },
      {
        title: 'IX',
        description: 'The 9 Process promises<br /> an excellent quality',
        type: 'string',
      },
    ])

    useEffect(() => {
      if (staticPageId) {
        const optionValues = dataSource?.options
          ?.filter((f) => f)
          .map((item) => {
            item.isEdiable = false
            return item
          })
        setOptions(optionValues)
      }
    }, [lang])

    useImperativeHandle(ref, () => ({
      addItem: handleAddItem,
    }))

    const onFinish = (lang) => {
      console.log(options)
      confirm({
        title: 'Do you want to save it?',
        icon: <ExclamationCircleOutlined />,
        content: 'Please check infomation before Submiting!',
        onOk() {
          setLoading(true)
          const itemValues = {
            pageName: pageNameEnum.HOME,
            pageType: pageTypeEnum.STATISTIC,
            isActive: true,
            locale: lang,
            options,
          }
          const optionValues = options
            ?.filter((f) => f)
            .map((item) => {
              delete item.isEditable
              return item
            })

          const formData = new FormData()
          formData.append('id', `${staticPageId}`)
          formData.append('pageName', pageNameEnum.HOME)
          formData.append('pageType', pageTypeEnum.STATISTIC)
          formData.append('locale', lang)
          formData.append('options', JSON.stringify(optionValues))

          console.log(itemValues)
          const promise = staticPageId
            ? updateStaticPage(staticPageId, formData)
            : createStaticPage(formData)
          promise
            .then((response) => {
              setLoading(false)
              if ([200, 201].includes(response.status.code)) {
                message.success('Successfully saved.')
              }
            })
            .catch((error) => {
              setLoading(false)
              console.error(error)
            })
        },
      })
    }

    const rteChange = debounce(function (event: any) {
      let value = event.value
      const optionState = [...options]
      if (optionState[event.index]['type'] === 'number') {
        value = value.replace(/,/g, '')
      }
      optionState[event.index][event.field] = value
      setOptions(optionState)
    }, 50)

    const rteChangeTextHtml = debounce(function (event: any) {
      const value = safeContent(event.element.target.innerText.replace(/\n/g, '<br />')).__html
      const optionState = [...options]
      optionState[event.index][event.field] = value
      setOptions(optionState)
    }, 50)

    const setEditable = (isEdiable, index) => {
      const optionState = [...options]
      for (const item of optionState) {
        item.isEdiable = false
      }
      optionState[index].isEdiable = isEdiable
      setOptions(optionState)
    }

    const deleteOption = (index) => {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this data',
        onOk() {
          const optionState = [...options]
          delete optionState[index]
          setOptions(optionState.filter((f) => f))
        },
      })
    }

    const handleAddItem = () => {
      setOptions([...options, optionItems])
    }

    return (
      <>
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Col span={24}>
              <section className='bg-secondary mx-0'>
                <div
                  className='relative bg-fixed'
                  style={{
                    // backgroundImage: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)),url("./assets/images/statistic.jpg")`,
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                  }}
                >
                  <div className='flex justify-between px-20 py-10 m-auto overflow-auto'>
                    {options?.map((item, index: number) => {
                      return (
                        <div
                          key={'statistic-' + index}
                          className='relative flex items-start justify-around w-full p-5 group'
                        >
                          <div className='min-w-[150px] p-5 text-center group-hover:bg-gray-200 group-hover:scale-110 transition duration-300 ease-in-out'>
                            <h1
                              className='text-5xl font-bold mb-3'
                              contentEditable={item.isEdiable}
                              suppressContentEditableWarning={true}
                              onBlur={(e) =>
                                rteChange({
                                  field: 'title',
                                  value: e.currentTarget.textContent,
                                  index: index,
                                })
                              }
                            >
                              {item.type === 'number' ? formatNumber(item.title) : item.title}
                            </h1>
                            <div
                              className='text-base whitespace-pre-wrap'
                              contentEditable={item.isEdiable}
                              suppressContentEditableWarning={true}
                              onKeyDown={(event) => {
                                if (event.code === 'Enter') {
                                  ;(document as any).execCommand('insertHTML', false, '\n')
                                  return false
                                }
                              }}
                              onBlur={(event) =>
                                rteChangeTextHtml({
                                  field: 'description',
                                  element: event,
                                  index: index,
                                })
                              }
                              dangerouslySetInnerHTML={{ __html: item.description }}
                            />

                            {!options[index].isEdiable && (
                              <PermissionAccess permission={'home:update'} scope={scope}>
                                <WraperEditable
                                  onClick={() => setEditable(true, index)}
                                  // onDelete={() => deleteOption(index)}
                                />
                              </PermissionAccess>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='text-right mt-3'>
              <PermissionAccess permission={'home:update'} scope={scope}>
                <Button type='primary' onClick={() => onFinish(lang)}>
                  Save data in [{lang?.toUpperCase()}]
                </Button>
              </PermissionAccess>
            </Col>
          </Row>
        </Spin>
      </>
    )
  },
)

export default FormHtmlEditable
