import { Col, Form, FormInstance, Input, message, Modal, PageHeader, Row, Spin, Switch } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined, PictureOutlined } from '@ant-design/icons'

import { useEffect, useState } from 'react'
import { LanguageEnum } from 'enums/language.enum'
import { useProductStore } from 'stores/product'
import { useGlobalStore } from 'stores/global'

import Editor from 'components/features/editor/QuillEditor'
import FileUpload from 'components/features/upload/Upload'
import ImagePreview from 'components/features/lightbox/ImagePreview'
import {
  createProductItem,
  deleteImageProductItem,
  updateProductItem,
} from 'services/product.service'

const { confirm } = Modal
interface IDescriptionLanguage {
  [LanguageEnum.TH]: string
  [LanguageEnum.EN]: string
}

interface IFormProductItemProps {
  form: FormInstance
  lang?: string
  productId?: number
  productItemId?: number
  indexItem?: number
  dataSource?: any
  onSuccess: (item?: any) => void
  onClose: () => void
}
const FormProductItem = ({
  form,
  productId,
  productItemId,
  indexItem,
  dataSource,
  onSuccess,
}: IFormProductItemProps) => {
  const { languages } = useGlobalStore()
  const { productItems, setProductItems } = useProductStore()
  const [loading, setLoading] = useState<boolean>(true)
  const [description, setDescription] = useState<IDescriptionLanguage | any>({})

  useEffect(() => {
    setLoading(false)
    if (dataSource) {
      form.setFieldsValue(dataSource)
      setDescription(dataSource?.description)
      setFilesUpload(dataSource?.filesUpload ?? [])
      let images = dataSource?.images ?? []
      if (dataSource?.imagesUrl) {
        images = [...images, ...dataSource.imagesUrl]
      }
      setImagesUrl(images)
    } else {
      setDescription({
        [LanguageEnum.TH]: ' ',
        [LanguageEnum.EN]: ' ',
      })
    }
  }, [])

  const onFinish = (values: any) => {
    console.log(dataSource)
    const itemValue = {
      ...values,
      productId,
      filesUpload,
      imagesUrl,
    }

    if (!productId) {
      let items = productItems
      if (indexItem !== undefined) {
        items[indexItem] = itemValue
      } else {
        items = [...items, itemValue]
      }

      setProductItems(items)
      onSuccess(itemValue)
      return
    }

    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const formData = new FormData()
        delete itemValue.filesUpload
        delete itemValue.imagesUrl

        for (const item of Object.keys(itemValue)) {
          const fieldValue = ['description', 'images'].includes(item)
            ? JSON.stringify(itemValue[item])
            : itemValue[item]

          formData.append(item, fieldValue)
        }
        if (filesUpload.length) {
          for (const file of filesUpload) {
            formData.append('files[]', file, `ITOON-${file.name}`)
          }
        }

        console.log('itemValue', itemValue)
        const promise = productItemId
          ? updateProductItem(productItemId, formData)
          : createProductItem(formData)
        promise
          .then((response) => {
            setLoading(false)
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                const productResponse = response.data
                if (productItemId && indexItem) {
                  console.log(productItems)
                  const productItemNew = [...productItems]
                  productItemNew[indexItem] = productResponse
                  console.log(productItemNew)
                  setProductItems(productItemNew)
                }
                if (!productItemId) {
                  setProductItems([...productItems, productResponse])
                }

                onSuccess(response.data)
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const [imagesUrl, setImagesUrl] = useState<string[]>([])
  const [filesUpload, setFilesUpload] = useState<File[]>([])
  const onUpload = (upload) => {
    setImagesUrl((prevImagesUrl) => {
      return [...prevImagesUrl, upload.imageUrl]
    })
    setFilesUpload((prevFilesUpload) => {
      return [...prevFilesUpload, upload.file]
    })
  }

  const onDelete = (file, imageIndex) => {
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && productItemId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          deleteImageProductItem(productItemId, { image: pathUrl })
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
                const removeImage = imagesUrl.filter((_, index) => index !== imageIndex)
                setImagesUrl(removeImage)
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      const removeImage = imagesUrl.filter((_, index) => index !== imageIndex)
      const removeFiles = filesUpload.filter((_, index) => index !== imageIndex)
      setImagesUrl(removeImage)
      setFilesUpload(removeFiles)
    }
  }

  const handleSetDescription = (value, locale) => {
    setDescription({
      ...description,
      [locale]: value,
    })
  }

  return (
    <>
      <Form
        form={form}
        name='productItem-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Form.Item name='productId'>
              <Input hidden />
            </Form.Item>

            <Col span={24}>
              <Form.Item
                label='Product Name'
                name='name'
                rules={[{ required: true, message: 'please enter product name' }]}
              >
                <Input size='large' placeholder='example: e.p.t.q. S100 Lidocaine' />
              </Form.Item>
            </Col>
            {languages.map((item) => {
              return (
                <Col key={item.code} span={24}>
                  <Form.Item
                    name={['description', item.locale]}
                    rules={[{ required: true, message: 'please input product item description' }]}
                  >
                    {description[item.locale] && (
                      <Editor
                        content={description[item.locale]}
                        onChange={(value) => handleSetDescription(value, item.locale)}
                        image={false}
                        // paramId={dataSource?.productInfoId}
                        folder={'product-item'}
                        className='editor-content'
                        placeholder={`Type here about product description in ${item.country} language`}
                      />
                    )}
                  </Form.Item>
                </Col>
              )
            })}
          </Row>

          <Row gutter={16}>
            <Col span={24} className='m-1.5 p-2 border border-solid rounded-md'>
              <PageHeader title='Gallery' subTitle='management' className='p-0' />

              <div className='flex gap-2 p-3'>
                <div className='w-3/12'>
                  <FileUpload
                    field='image'
                    listType='NONE'
                    multiple={true}
                    onUpload={onUpload}
                    className='w-[160px] h-[160px]'
                  >
                    <div className='flex flex-none justify-center items-center min-h-[160px] bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                      <div className='w-full text-center p-5 '>
                        <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                        <div className='text-sm group-hover:scale-110 transition duration-300 ease-in-out'>
                          Browse to find or drag <br />
                          <span className='font-bold underline'>imgae</span> here
                        </div>
                      </div>
                    </div>
                  </FileUpload>
                </div>
                <div className='w-9/12 grid grid-cols-4 gap-4'>
                  {!!imagesUrl.length &&
                    imagesUrl.map((item, index) => {
                      return (
                        <ImagePreview
                          key={`gallery-${index}`}
                          index={index}
                          imageUrl={item}
                          imageList={imagesUrl}
                          onDelete={() => onDelete(item, index)}
                        />
                      )
                    })}
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='mt-5'>
              <div className='flex items-center'>
                <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which data will be listed on status (Active/InActive)
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormProductItem
