import { Button, Table, Tag, Tooltip } from 'antd'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

const { Column } = Table

interface ITableProductItemProps {
  onClick: (item, index) => void
  onDelete: (item, index) => void
  productItems: any
}

const TableProductItem = ({ onClick, onDelete, productItems = [] }: ITableProductItemProps) => {
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  const findImageValue = (record) => {
    if (record?.images?.length) {
      return record.images[0]
    }
    if (record?.imagesUrl?.length) {
      return record.imagesUrl[0]
    }

    return './assets/images/logo.png'
  }

  return (
    <>
      <Table
        loading={loading}
        pagination={false}
        rowKey={(record) => `productItem_${record?.productItemId}`}
        dataSource={productItems}
        size='small'
        className='mt-2 mb-2'
      >
        <Column
          width={100}
          render={(record) => (
            <div>
              <img
                src={findImageValue(record)}
                className='w-20 h-20 object-contain border border-solid p-1'
              />
            </div>
          )}
        />
        <Column
          title='Name'
          className='break-word'
          ellipsis
          render={(record) => (
            <div>
              <div className='text-md font-bold'>{record?.name}</div>
            </div>
          )}
        />
        <Column
          title='Gellery'
          className='break-word'
          ellipsis
          align='center'
          render={(record) => (
            <div className='text-md text-center'>
              {record?.images?.length || record?.imagesUrl?.length} images
            </div>
          )}
        />
        <Column
          title='Last updated'
          className='break-word'
          align='center'
          width={200}
          render={(record) => (
            <div className='text-center'>
              {dayjs(record?.updatedAt).format('D MMM YYYY hh:mm A')}
            </div>
          )}
        />
        <Column
          title='status'
          align='center'
          className='break-word text-center'
          width={130}
          render={(record) => (
            <div className='text-center'>
              {record?.isActive ? (
                <Tag color='processing' className='m-0'>
                  Published
                </Tag>
              ) : (
                <Tag color='default' className='m-0'>
                  Unpublished
                </Tag>
              )}
            </div>
          )}
        />
        <Column
          width={70}
          render={(_, record, index) => (
            <div className='flex justify-between'>
              <Tooltip title='Editable'>
                <Button
                  type='dashed'
                  icon={<FormOutlined />}
                  shape='circle'
                  size='small'
                  onClick={() => {
                    onClick(record, index)
                  }}
                />
              </Tooltip>
              <Tooltip title='Delete'>
                <Button
                  type='link'
                  icon={<DeleteOutlined />}
                  size='small'
                  onClick={() => {
                    onDelete(record, index)
                  }}
                />
              </Tooltip>
            </div>
          )}
        />
      </Table>
    </>
  )
}

export default TableProductItem
