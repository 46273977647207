import { useEffect, useState } from 'react'
import { HomeOutlined, LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

import { useGlobalStore } from 'stores/global'
import { findAllStaticPages } from 'services/staticPage.service'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'

import TabByLanguage from './components/TabByLanguage'

const SectionStatisticPage = () => {
  const { setPageHeader, languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any>([])

  useEffect(() => {
    setPageHeader({
      title: 'Home Overview',
      subTitle: 'Statistic Management',
      onBack: '#',
      backIcon: <HomeOutlined />,
      extra: [],
    })

    findHomeAbouts()
  }, [])

  const findHomeAbouts = async () => {
    setLoading(true)
    const body = {
      search: {
        pageName: pageNameEnum.HOME,
        pageType: pageTypeEnum.STATISTIC,
      },
    }
    const payload: any = await findAllStaticPages(body)
    if (payload?.status?.code === 200 && payload.data?.length) {
      setDataSource(payload.data)
    }
    setLoading(false)
  }

  return (
    <>
      <Spin
        tip='Loading...'
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {!loading && <TabByLanguage dataSource={dataSource} />}
      </Spin>
    </>
  )
}

export default SectionStatisticPage
