import { useAuth } from 'stores/authorized'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input, Modal, notification } from 'antd'
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { changePassword } from 'services/auth.service'
import { LOGIN_TYPE } from 'constants/authoriz'

const { confirm } = Modal
const ResetPassword = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { auth } = useAuth()

  useEffect(() => {
    console.log(auth)
    if (!auth?.isLoggedIn) {
      navigate('/login')
      return
    }
    form.setFieldValue('email', auth.user?.email)
  }, [])

  const [loading, setLoading] = useState<boolean>(false)

  const onFinish = (values: any) => {
    console.log('Success:', values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValue = {
          ...values,
        }

        changePassword(itemValue)
          .then((response) => {
            if (response?.status.code === 200) {
              if (response.data === LOGIN_TYPE.OK) {
                form.resetFields()
                notification.success({
                  message: 'change password completed',
                  description: 'Your password has been changed',
                })
                navigate('/home/banner')
              }
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
            notification.error({
              message: 'Somgthing went wrong!',
              description: error.error.description + ', please check and try again',
            })
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className='flex items-center justify-center w-screen h-screen bg-gradient-to-r from-secondary to-secondary'>
      <div className='relative py-20 w-2/5 bg-white rounded-md shadow-xl'>
        <div className='flex flex-col items-center justify-center'>
          {/* <h1 className='font-bold text-teal-300 text-9xl'>404</h1> */}

          <h6 className='mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl'>
            <span className='text-primary'>Reset</span> your password
          </h6>

          <p className='mb-8 text-center text-gray-500 md:text-lg'>
            The password should have atleast 6 characters
          </p>

          <div className='w-3/5'>
            <Form
              form={form}
              name='forget-password'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='on'
            >
              <Form.Item
                name='email'
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input
                  placeholder='Email'
                  size='large'
                  className='rounded-full'
                  prefix={<UserOutlined />}
                  disabled
                />
              </Form.Item>

              <Form.Item
                name='newPassword'
                rules={[{ required: true, message: 'Please input your new password!' }]}
                className='w-full'
              >
                <Input.Password placeholder='New Password' size='large' className='rounded-full' />
              </Form.Item>

              <Form.Item
                name='confirmPassword'
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('The two passwords that you entered do not match!'),
                      )
                    },
                  }),
                ]}
                className='w-full'
              >
                <Input.Password
                  placeholder='confirm password'
                  size='large'
                  className='rounded-full'
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  size='large'
                  shape='round'
                  block
                  loading={loading}
                >
                  Change password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className='absolute bottom-5 px-6 py-2 text-sm font-semibold text-teal-800 bg-blue-100 cursor-pointer'>
          To make sure your account is secure, you&apos;ll be logged out once you set the new
          password.
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
