import { useEffect, useState } from 'react'
import { Col, Row, Spin, Form, Input, Modal, message, PageHeader, FormInstance, Table } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { createAdmin, updateAdmin } from 'services/admin.service'
import { useAuth } from 'stores/authorized'

const { confirm } = Modal

interface IProps {
  form: FormInstance
  adminId?: number | string | undefined
  onSuccess?: (item?: any) => void
  type: 'password' | 'information'
}

const FormInfomation = ({ form, onSuccess, type }: IProps) => {
  const { auth } = useAuth()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValue = {
          ...values,
        }
        console.log('itemValue', itemValue)
        const promise = values?.id ? updateAdmin(values?.id, itemValue) : createAdmin(itemValue)
        promise
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                form.resetFields()
                onSuccess(type)
              }
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  return (
    <>
      <Form
        form={form}
        name='admin-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Form.Item name='id' className='hidden'>
            <Input hidden className='hidden' />
          </Form.Item>
          {!!(type === 'information') && (
            <Row gutter={16} className='p-2'>
              <Col span={24} className='border border-solid rounded-md'>
                <div className='p-3'>
                  <PageHeader
                    title='Information'
                    subTitle='manage your information'
                    className='p-0'
                  />
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label='First Name'
                        name='firstName'
                        rules={[
                          {
                            required: true,
                            message: 'please input firstname',
                          },
                        ]}
                      >
                        <Input size='large' placeholder='Firstname' />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label='Last Name'
                        name='lastName'
                        rules={[
                          {
                            required: true,
                            message: 'please input lastName',
                          },
                        ]}
                      >
                        <Input size='large' placeholder='Lastname' />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label='Email'
                        name='email'
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          { required: true, message: 'please input admin email' },
                        ]}
                      >
                        <Input size='large' placeholder='Email for sign in to backend' />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label='Phone'
                        name='phone'
                        rules={[
                          {
                            required: true,
                            message: 'please input phone',
                          },
                        ]}
                      >
                        <Input size='large' placeholder='phone' />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}

          {!!(type === 'password') && (
            <Row gutter={16} className='p-2'>
              <Col span={24} className='border border-solid rounded-md'>
                <div className='p-3'>
                  <PageHeader
                    title='Change Password'
                    subTitle='manage your password'
                    className='p-0'
                  />
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label='Current Password'
                        name='password'
                        rules={[{ required: true, message: 'Please input your current password!' }]}
                        className='w-full'
                      >
                        <Input.Password placeholder='Current Password' size='large' />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label='Password'
                        name='newPassword'
                        rules={[{ required: true, message: 'Please input your new password!' }]}
                        className='w-full'
                      >
                        <Input.Password placeholder='New Password' size='large' />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label='Confirm Password'
                        name='confirmPassword'
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your new password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                new Error('The two passwords that you entered do not match!'),
                              )
                            },
                          }),
                        ]}
                        className='w-full'
                      >
                        <Input.Password placeholder='confirm password' size='large' />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </Spin>
      </Form>
    </>
  )
}

export default FormInfomation
