import { useEffect, useState } from 'react'
import {
  ApartmentOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  DeleteOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'
import { Button, Col, Pagination, Row, Table, Tag, Modal, Input, message, Tooltip } from 'antd'
import dayjs from 'dayjs'
import ClinicDrawer from './components/Drawer'

import { Clinic } from 'stores/types/clinic.types'
import { useGlobalStore } from 'stores/global'
import { useClinicStore } from 'stores/clinic'
import { deleteClinic, findAllClinics } from 'services/clinic.service'
import { useAuth } from 'stores/authorized'
import PermissionAccess from 'components/PermissionAccess'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input

export interface ISearchState {
  searchText: string | undefined
}

const ClinicPage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()
  const { setBranches } = useClinicStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Clinic>()
  const [clinicList, setClinicList] = useState<Clinic[]>([])

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })

  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    findClinics()
  }, [])

  useEffect(() => {
    setPageHeader({
      title: 'Clinic Overview',
      subTitle: '',
      onBack: '#',
      backIcon: <ApartmentOutlined />,
      extra: [
        <PermissionAccess key={1} permission={'clinic:create'} scope={scope}>
          <Button
            type='primary'
            shape='round'
            icon={<PlusOutlined className='text-base relative -top-0.5' />}
            onClick={() => {
              setIsOpen(true)
              setDataSource(undefined)
              setBranches([])
            }}
          >
            create clinic
          </Button>
        </PermissionAccess>,
      ],
    })
  }, [scope?.[0]])

  const findClinics = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      ...param,
      isApp: true,
    }
    const payload: any = await findAllClinics(body)
    if (payload?.status?.code === 200) {
      setClinicList(payload.data.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }
    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findClinics({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteClinic(item.id)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findClinics()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findClinics({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      clinicType: undefined,
      categoryId: undefined,
    }
    setSearch(searching)
    findClinics({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findClinics()
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <div className='flex items-center'>
            <Search
              allowClear
              size='large'
              placeholder='ค้นหาข้อมูล'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />
            <Button type='link' onClick={clearSearch} disabled={!search.searchText}>
              clear
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `clinic_${record?.id}`}
            dataSource={clinicList}
            className='mt-2 mb-2'
          >
            <Column
              width={150}
              render={(record) => (
                <div>
                  <img
                    src={record.logo}
                    className='w-24 h-24 object-cover border border-solid p-1'
                  />
                </div>
              )}
            />

            <Column
              title='Name'
              className='break-word'
              ellipsis
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>{record?.name?.th}</div>
                  <div className='text-sm truncate text-gray-500 '>{record?.name?.en}</div>
                </div>
              )}
            />

            <Column
              title='Address'
              render={(record) => (
                <div>
                  <div className='text-sm text-gray-500 '>{record?.address?.selected?.en}</div>
                </div>
              )}
            />
            <Column
              title='Branches'
              className='break-word'
              align='center'
              width={100}
              render={(record) => <div className='flex'>{record?.branches?.length} branches</div>}
            />
            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {dayjs(record?.createdAt).format('DD-MM-YYYY hh:mm:ss')}
                </div>
              )}
            />
            <Column
              title='Last updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {dayjs(record?.updatedAt).format('DD-MM-YYYY hh:mm:ss')}
                </div>
              )}
            />
            <Column
              title='status'
              align='center'
              className='break-word text-center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {record?.isActive ? (
                    <Tag color='processing' className='m-0'>
                      Published
                    </Tag>
                  ) : (
                    <Tag color='default' className='m-0'>
                      Unpublished
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              width={150}
              render={(record) => (
                <div className='flex gap-2'>
                  <Tooltip title='clipboard link'>
                    <Button
                      type='default'
                      icon={<PaperClipOutlined />}
                      shape='circle'
                      onClick={() => {
                        const copyText = `${process.env.REACT_APP_FRONT_URL}/clinic?branches=${record?.id}`
                        navigator.clipboard.writeText(copyText)
                        message.success('copy ' + copyText)
                      }}
                    />
                  </Tooltip>
                  <PermissionAccess permission={'clinic:update'} scope={scope}>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </PermissionAccess>
                </div>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <ClinicDrawer onClose={onClose} onSuccess={onClose} isOpen={isOpen} dataSource={dataSource} />
    </>
  )
}

export default ClinicPage
