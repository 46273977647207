import fetch from 'utils/fetchAxios'

export const findContacts = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/contact'
  return fetch(method, path, query)
}

export const deleteContact = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/contact/${id}`
  return fetch(method, path, query)
}
