import { useState } from 'react'
import { Divider, Input, Modal } from 'antd'
import { ExclamationCircleOutlined, PictureOutlined } from '@ant-design/icons'

import FileUpload from '../upload/Upload'

interface IProps {
  isOpen: boolean
  onSuccess: () => void
}
const { confirm } = Modal
const ImageHandler = ({ isOpen }: IProps) => {
  const handleOk = () => {
    console.log('handleOk')
  }
  const handleCancel = () => {
    console.log('handleCancel')
  }

  const [imageUrl, setImageUrl] = useState<string>()
  const [fileUpload, setFileUpload] = useState<File>()

  const onUpload = (upload) => {
    setImageUrl(upload.imageUrl)
    setFileUpload(upload.file)
  }

  const onDelete = (file) => {
    console.log('onDelete', file)
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http')) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          // updateStaticPage(staticPageId, {
          //   images: [],
          // })
          //   .then((response) => {
          //     if (response.status.code === 200) {
          //       message.success('Deleted Successfully.')
          //       setImageUrl(undefined)
          //     }
          //   })
          //   .catch((error) => {
          //     message.error('Something went wrong! please try again later.')
          //     console.error(error)
          //   })
        },
      })
    } else {
      setFileUpload(undefined)
      setImageUrl(undefined)
    }
  }

  return (
    <Modal title='Upload Manager' width={800} open={isOpen} onOk={handleOk} onCancel={handleCancel}>
      <div className='flex gap-2'>
        <div className='w-1/2'>
          <div className='upload-full-w group mb-3 relative'>
            <FileUpload
              onUpload={onUpload}
              onDelete={(file) => onDelete(file)}
              imageUrl={imageUrl}
              listType='CARD'
              className=''
            >
              <div className='w-full min-h-[380px] flex flex-col items-center justify-center text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                  Browse to find or drag <span className='font-bold underline'>Banner</span> here
                </div>
              </div>
            </FileUpload>
          </div>
        </div>
        <div className='w-1/2'>
          <Divider orientation='left'>Pixel Dimensions</Divider>
          <div className='flex gap-2 justify-center items-center px-7'>
            <Input placeholder='Width' /> X <Input placeholder='Height' />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ImageHandler
