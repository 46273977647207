import { useEffect, useState } from 'react'
import {
  HomeOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  PictureOutlined,
} from '@ant-design/icons'
import { Button, Col, Row, Spin, Tooltip, Modal, message, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as faIconSolid from '@fortawesome/free-solid-svg-icons'
import * as faIconRegular from '@fortawesome/free-regular-svg-icons'

import { useGlobalStore } from 'stores/global'
import { findAllStaticPages } from 'services/staticPage.service'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'
import TabByLanguage from './components/TabByLanguage'

const FacilitiesPage = () => {
  const { setPageHeader } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any>([])

  useEffect(() => {
    setPageHeader({
      title: 'About us Overview',
      subTitle: 'Facilities',
      onBack: '#',
      backIcon: <HomeOutlined />,
      extra: [],
    })

    findHomeAbouts()
  }, [])

  const findHomeAbouts = async () => {
    setLoading(true)
    const body = {
      search: {
        pageName: pageNameEnum.ABOUT,
        pageType: pageTypeEnum.FACILITIES,
      },
    }
    const payload: any = await findAllStaticPages(body)
    if (payload?.status?.code === 200 && payload.data?.length) {
      setDataSource(payload.data)
    }
    setLoading(false)
  }

  return (
    <>
      <Spin
        tip='Loading...'
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {!loading && <TabByLanguage dataSource={dataSource} />}
      </Spin>
    </>
  )
}

export default FacilitiesPage
