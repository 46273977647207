import { useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
// import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css'

import { debounce } from 'utils/common'
import { uploadImage } from 'services/upload.service'
import ImageResize from 'quill-image-resize-module-react'
import ImageHandler from './ImageHandler'

Quill.register((Quill.import('formats/image').className = 'img-fluid'), true)
Quill.register('modules/imageResize', ImageResize)

interface IProps {
  onChange?: (value: any) => void
  className?: string
  content?: string
  image?: boolean
  paramId?: string | number | undefined
  folder?: string
  placeholder?: string
}

const Editor = ({
  onChange,
  content = ' ',
  className,
  image = false,
  paramId,
  folder = 'editor',
  placeholder = 'Type here...',
}: IProps) => {
  const quillRef = useRef<any>(null)
  const [value, setValue] = useState(content)
  const [isImageHandler, setIsImageHandler] = useState<boolean>(false)

  useEffect(() => {
    typeof onChange === 'function' && onChange(value)
  }, [value])

  const imageHandler = () => {
    // setIsImageHandler(true)
    const input: any = document.createElement('input')
    input.setAttribute('type', 'file')
    input.click()

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0]

      // file type is only image.
      if (/^image\//.test(file.type)) {
        upload(file)
      } else {
        console.warn('You could only upload images.')
      }
    }
  }

  const upload = async (file: any) => {
    const formData = new FormData()
    // formData.append('paramId', paramId + '')
    formData.append('folder', folder)
    formData.append('file', file)

    const payload = await uploadImage(formData)
    if (payload?.status?.code === 200) {
      insertToEditor(payload?.data)
    }
  }

  const insertToEditor = (url: string) => {
    // push image url to rich editor.
    const editor = quillRef.current.getEditor() // The error here TypeError: quillRef.current.getEditor is not a function
    const range = editor.getSelection() // This also fails , the idea is to insert the uploaded image to the selected position

    editor.insertEmbed(range.index, 'image', url)
  }

  const imageToolbar = image ? ['link', 'image', 'video'] : ['link', 'video']
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ color: [] }, { background: [] }, { align: ['', 'center', 'right', 'justify'] }],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          imageToolbar,
          ['clean'],
          // remove formatting button custom
          // ['omega']
        ],
        handlers: {
          image: imageHandler,
          omega: () => {
            console.log('omega')
            const editor = quillRef.current.getEditor() // The error here TypeError: quillRef.current.getEditor is not a function
            const range = editor.getSelection() // This also fails , the idea is to insert the uploaded image to the selected position
            console.log(range, editor)
            editor.insertEmbed(
              range.index,
              'image',
              'https://ckeditor.com/assets/images/bg/volcano-8967c4575e.jpg',
            )
          },
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
    }),
    [],
  )

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'color',
    'background',
    'align',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'clean',
  ]

  // args: content, delta, source, editor
  const rteChange = debounce(function (content: string) {
    setValue(content)
  }, 150)

  return (
    <div className={className}>
      <ReactQuill
        ref={quillRef}
        // theme="bubble"
        value={value}
        // onFocus={() => console.log('onFocus')}
        // onBlur={() => console.log('onBlur')}
        // placeholder='พิมพ์เนื้อหาของคุณที่นี่...'
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        onChange={rteChange}
      />

      <ImageHandler isOpen={isImageHandler} onSuccess={() => console.log('file')} />
    </div>
  )
}

export default Editor
