import { useEffect, useState } from 'react'
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  PictureOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Button, Col, Row, Spin, Modal, message, notification, Tooltip } from 'antd'
import { createStaticPage, updateStaticPage } from 'services/staticPage.service'
import { debounce, safeContent } from 'utils/common'
import { pageNameEnum, pageTypeEnum } from 'enums/common.enum'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as faIconSolid from '@fortawesome/free-solid-svg-icons'
import * as faIconRegular from '@fortawesome/free-regular-svg-icons'

import FileUpload from 'components/features/upload/Upload'
import WraperEditable from 'components/features/wraperEditable'
import IconModal from 'components/features/icons/IconModal'
import PermissionAccess from 'components/PermissionAccess'
import { useAuth } from 'stores/authorized'

interface IContetWithIconProps {
  isEdiable: boolean
  value: any
  onEdited: (value, field) => void
}

const faIcons = Object.assign({}, faIconSolid, faIconRegular)
const ContentWithIcon = ({ isEdiable, value, onEdited }: IContetWithIconProps) => {
  const { scope } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleSuccess = (icon) => {
    setIsModalOpen(false)
    onEdited(icon, 'icon')
  }

  return (
    <>
      <div className='flex items-center justify-around w-full md:w-1/2 mb-5'>
        <Tooltip title='click to change this icon'>
          <div
            className='h-16 w-16 hover:scale-110 transition duration-300 ease-in-out flex justify-center items-center bg-primary text-white rounded-full cursor-pointer'
            onClick={() => setIsModalOpen(true)}
          >
            {value.icon && <FontAwesomeIcon icon={faIcons[value.icon]} size='2xl' />}
          </div>
        </Tooltip>
        <div className='w-3/4 md:w-1/2 pl-2 md:pl-0'>
          <div
            className='text-base font-bold'
            contentEditable={isEdiable}
            suppressContentEditableWarning={true}
            onBlur={(event) => onEdited(event.currentTarget.textContent, 'title')}
          >
            {value.title}
          </div>
          <div
            contentEditable={isEdiable}
            suppressContentEditableWarning={true}
            onBlur={(event) => onEdited(event.currentTarget.textContent, 'description')}
          >
            {value.description}
          </div>
        </div>
      </div>

      <PermissionAccess permission={'home:update'} scope={scope}>
        <IconModal
          isOpen={isModalOpen}
          currentIcon={value.icon}
          onSuccess={handleSuccess}
          onClose={() => setIsModalOpen(false)}
        />
      </PermissionAccess>
    </>
  )
}

interface IFormHtmlEditableProps {
  lang?: string
  staticPageId?: number
  dataSource?: any
}

const optionItems = {
  isEdiable: false,
  title: 'Aestema Aesthetics',
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
  icons: [
    { title: 'Lorem', description: 'Lorem Ipsum is simply dummy text', icon: 'faUsers' },
    { title: 'Lorem', description: 'Lorem Ipsum is simply dummy text', icon: 'faCommentMedical' },
    { title: 'Lorem', description: 'Lorem Ipsum is simply dummy text', icon: 'faNotesMedical' },
    { title: 'Lorem', description: 'Lorem Ipsum is simply dummy text', icon: 'faSyringe' },
  ],
  image: null,
}

const { confirm } = Modal
const FormHtmlEditable = ({ dataSource, staticPageId, lang }: IFormHtmlEditableProps) => {
  const { scope } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>(optionItems)

  useEffect(() => {
    if (staticPageId) {
      setOptions({
        ...dataSource?.options,
        isEdiable: false,
      })
      setImageUrl(dataSource?.images?.[0])
    }
  }, [lang])

  const onFinish = (lang) => {
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValues = {
          pageName: pageNameEnum.HOME,
          pageType: pageTypeEnum.ABOUT,
          isActive: true,
          locale: lang,
          options: {
            icons: options.icons,
            title: options.title,
            description: options.description,
          },
          files: [fileUpload],
        }

        const formData = new FormData()
        formData.append('id', `${staticPageId}`)
        formData.append('pageName', pageNameEnum.HOME)
        formData.append('pageType', pageTypeEnum.ABOUT)
        formData.append('locale', lang)
        formData.append(
          'options',
          JSON.stringify({
            icons: options.icons,
            title: options.title,
            description: options.description,
          }),
        )

        if (fileUpload) {
          formData.append('files[]', fileUpload, `ABOUT-ITOON-${fileUpload.name}`)
        }
        console.log(itemValues)
        const promise = staticPageId
          ? updateStaticPage(staticPageId, formData)
          : createStaticPage(formData)
        promise
          .then((response) => {
            setLoading(false)
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const [imageUrl, setImageUrl] = useState<string>()
  const [fileUpload, setFileUpload] = useState<File>()

  const onUpload = (upload) => {
    setImageUrl(upload.imageUrl)
    setFileUpload(upload.file)
  }

  const onDelete = (file) => {
    console.log('onDelete', file)
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && staticPageId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          updateStaticPage(staticPageId, {
            images: [],
          })
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
                setImageUrl(undefined)
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      setFileUpload(undefined)
      setImageUrl(undefined)
    }
  }

  const rteChange = debounce(function (event: any) {
    const value = safeContent(event.value.target.innerText.replace(/\n/g, '<br />')).__html
    setOptions({
      ...options,
      [event.field]: value,
    })
  }, 200)

  const rteChangeTextHtml = debounce(function (event: any) {
    const value = safeContent(event.element.target.innerText.replace(/\n/g, '<br />')).__html
    setOptions({
      ...options,
      [event.field]: value,
    })
  }, 200)

  const setEditable = (isEdiable) => {
    setOptions({
      ...options,
      isEdiable,
    })
  }

  const onEdited = (value, field, index) => {
    options.icons[index][field] = value
    setOptions(options)
  }

  return (
    <>
      <Spin
        tip='Loading...'
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row gutter={16}>
          <Col span={24}>
            <section className='bg-secondary -mx-5 md:mx-0'>
              <div className='flex-none flex justify-between m-auto max-w-[90rem] px-10 py-10'>
                <div className='p-5 w-1/2 relative group'>
                  <h1
                    className='text-4xl font-bold mb-4'
                    contentEditable={options.isEdiable}
                    suppressContentEditableWarning={true}
                    onBlur={(event) =>
                      rteChange({
                        field: 'title',
                        value: event, // event.currentTarget.textContent,
                      })
                    }
                    dangerouslySetInnerHTML={{ __html: options.title }}
                  ></h1>
                  <div
                    className='text-ellipsis mb-4'
                    contentEditable={options.isEdiable}
                    suppressContentEditableWarning={true}
                    onKeyDown={(event) => {
                      if (event.code === 'Enter') {
                        ;(document as any).execCommand('insertHTML', false, '\n')
                        return false
                      }
                    }}
                    onBlur={(event) =>
                      rteChangeTextHtml({
                        field: 'description',
                        element: event,
                      })
                    }
                    dangerouslySetInnerHTML={{ __html: options.description }}
                  ></div>
                  <div className='flex-none md:flex justify-around '>
                    <ContentWithIcon
                      value={options.icons[0]}
                      isEdiable={options.isEdiable}
                      onEdited={(value, field) => onEdited(value, field, 0)}
                    />

                    <ContentWithIcon
                      value={options.icons[1]}
                      isEdiable={options.isEdiable}
                      onEdited={(value, field) => onEdited(value, field, 1)}
                    />
                  </div>

                  <div className='flex-none md:flex justify-around '>
                    <ContentWithIcon
                      value={options.icons[2]}
                      isEdiable={options.isEdiable}
                      onEdited={(value, field) => onEdited(value, field, 2)}
                    />

                    <ContentWithIcon
                      value={options.icons[3]}
                      isEdiable={options.isEdiable}
                      onEdited={(value, field) => onEdited(value, field, 3)}
                    />
                  </div>

                  {!options.isEdiable && (
                    <PermissionAccess permission={'home:update'} scope={scope}>
                      <WraperEditable onClick={() => setEditable(true)} />
                    </PermissionAccess>
                  )}
                </div>
                <div className='p-10 w-1/2 flex items-center'>
                  <div className='w-full upload-full-w group mb-3 relative'>
                    <PermissionAccess permission={'home:update'} scope={scope}>
                      <FileUpload
                        onUpload={onUpload}
                        onDelete={(file) => onDelete(file)}
                        imageUrl={imageUrl}
                        listType='CARD'
                        className=''
                      >
                        <div className='w-full min-h-[380px] flex flex-col items-center justify-center text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                          <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                          <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                            Browse to find or drag{' '}
                            <span className='font-bold underline'>Banner</span> here
                          </div>
                        </div>
                      </FileUpload>
                    </PermissionAccess>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className='text-right mt-3'>
            <PermissionAccess permission={'home:update'} scope={scope}>
              <Button type='primary' onClick={() => onFinish(lang)}>
                Save data in [{lang?.toUpperCase()}]
              </Button>
            </PermissionAccess>
          </Col>
        </Row>
      </Spin>
    </>
  )
}

export default FormHtmlEditable
