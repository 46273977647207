export const enum pageNameEnum {
  HOME = 'HOME',
  ABOUT = 'ABOUT',
  SETTING = 'SETTING',
}

export const enum pageTypeEnum {
  BANNER = 'BANNER',
  ABOUT = 'ABOUT',
  STATISTIC = 'STATISTIC',
  CEO_MESSAGE = 'CEO_MESSAGE',
  FACILITIES = 'FACILITIES',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERM_CONDITION = 'TERM_CONDITION',
  WEB_CONFIG = 'WEB_CONFIG',
}

export const enum ArticleTypeEnum {
  NEWS = 'NEWS',
  ARTICLE = 'ARTICLE',
  EVENT_CALENDAR = 'EVENT_CALENDAR',
  WEBINAR = 'WEBINAR',
  LIVE_DEMO = 'LIVE_DEMO',
  SCHOLAR = 'SCHOLAR',
}
