import { useNavigate } from 'react-router-dom'
import { Layout, PageHeader } from 'antd'
import { useGlobalStore } from '../../../stores/global'

const isDisableLayout = false

const Content = ({ children }: any) => {
  const navigate = useNavigate()
  const {
    pageHeader: { title, subTitle, onBack, backIcon, extra, tags },
  } = useGlobalStore()
  return (
    <>
      {isDisableLayout ? (
        <>
          <Layout.Content
            id='app-content'
            style={{
              margin: '0 16px 24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Layout.Content>
        </>
      ) : (
        <>
          {onBack ? (
            <PageHeader
              tags={tags}
              onBack={() => (onBack ? navigate(onBack) : navigate(-1))}
              backIcon={backIcon}
              title={title}
              subTitle={subTitle}
              extra={extra}
            />
          ) : (
            <PageHeader title={title} subTitle={subTitle} extra={extra} />
          )}

          <Layout.Content
            id='app-content'
            className='site-layout-background bg-white'
            style={{
              margin: '0 16px 24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Layout.Content>
        </>
      )}
    </>
  )
}
export default Content
