import { useProductStore } from 'stores/product'
import { Button, Drawer, Form } from 'antd'
import { useEffect, useState } from 'react'
import FormProductInfo from './FormProductInfo'

interface IProps {
  onSuccess: (item?: any) => void
  onClose: () => void
  isOpen: boolean
  lang: string
  dataSource?: any
  productId?: number
}
const ProductInfoDrawer = ({ onSuccess, onClose, isOpen, lang, productId }: IProps) => {
  const [form] = Form.useForm()
  const { productInfo, setProductInfo } = useProductStore()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isOpen) {
      if (productInfo?.length) {
        const info = productInfo.sort((a: any, b: any) => a.order - b.order)
        form.setFieldValue('info', info)
      }
    } else {
      onCancel()
    }

    setLoading(false)
  }, [isOpen])

  const onCancel = () => {
    if (!loading) {
      form.resetFields()
      setProductInfo([])
      onClose()
    }
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save Information
        </Button>
      </div>
    </div>
  )

  return (
    <>
      <Drawer
        title={`Manage product description [${lang.toUpperCase()}]`}
        width={1024}
        closable={false}
        maskClosable={false}
        onClose={onCancel}
        open={isOpen}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<FooterDrawer />}
        extra={
          <Button
            type='dashed'
            className='w-[150px] hover:bg-primary hover:text-white'
            onClick={() => {
              document.getElementById('create-info')?.click()
            }}
          >
            Add Information
          </Button>
        }
      >
        {!!isOpen && (
          <FormProductInfo form={form} lang={lang} productId={productId} onSuccess={onSuccess} />
        )}
      </Drawer>
    </>
  )
}

export default ProductInfoDrawer
