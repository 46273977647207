import { createElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Menu, Modal, MenuProps, Dropdown, Space } from 'antd'
import {
  ExclamationCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
} from '@ant-design/icons'
import { useGlobalStore } from '../../../stores/global'
import { useAuth } from '../../../stores/authorized'
import AccountDrawer from './components/Drawer'

const { confirm } = Modal
const Navbar = () => {
  const navigate = useNavigate()
  const { collapsed, setCollapsed } = useGlobalStore()
  const { auth, setAuth } = useAuth()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [editType, setEditType] = useState<'password' | 'information'>('information')

  const signOut = () => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you really want to sign out?',
      centered: true,
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk() {
        setAuth(undefined)
        sessionStorage.clear()
        navigate('/signin')
      },
    })
  }

  const handleSuccess = (type) => {
    setIsOpen(false)
    setAuth(undefined)
    sessionStorage.clear()
    navigate('/signin')
  }

  const items: MenuProps['items'] = [
    {
      key: 'infomation',
      label: (
        <span
          onClick={() => {
            setIsOpen(true)
            setEditType('information')
          }}
        >
          Infomation
        </span>
      ),
    },
    {
      key: 'password',
      label: (
        <span
          onClick={() => {
            setIsOpen(true)
            setEditType('password')
          }}
        >
          Change Password
        </span>
      ),
    },
    {
      key: 'signout',
      label: <span onClick={signOut}>SignOut</span>,
    },
  ]

  return (
    <Layout.Header className='site-layout-background py-0 px-4 flex justify-between'>
      <div className='flex h-full py-2'>
        <div className='list-unstyled list-inline text-2xl text-primary'>
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
        </div>
      </div>

      <div className=''>
        <div className='list-unstyled list-inline mr-4'>
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>{auth?.user?.name}</Space>
            </a>
          </Dropdown>
        </div>
      </div>
      <AccountDrawer
        isOpen={isOpen}
        onSuccess={handleSuccess}
        onClose={() => setIsOpen(false)}
        type={editType}
      />
    </Layout.Header>
  )
}

export default Navbar
