import create from 'zustand'
import { Langyages, PageHeader, WebConfig } from './types/global.types'

type State = {
  collapsed: boolean
  setCollapsed: (toggle: boolean) => void // set toggle menu
  pageHeader: PageHeader
  setPageHeader: (pageHeader: PageHeader) => void

  webconfig: WebConfig
  setWebConfig: (config: WebConfig) => void

  languages: Langyages[]
  setLanguages: (lang: Langyages[]) => void
}

export const useGlobalStore = create<State>((set) => ({
  collapsed: false,
  setCollapsed: () => set((state) => ({ collapsed: !state.collapsed })),

  pageHeader: {
    title: undefined,
    subTitle: undefined,
    backIcon: false,
    onBack: undefined,
    extra: [],
  },
  setPageHeader: (pageHeader) => {
    pageHeader.tags = pageHeader.tags ? pageHeader.tags : []
    set((state) => ({
      pageHeader: {
        ...state.pageHeader,
        ...pageHeader,
      },
    }))
  },

  webconfig: { languages: [] },
  setWebConfig: (config) =>
    set((state) => ({
      webconfig: {
        ...state.webconfig,
        ...config,
      },
    })),

  languages: [],
  setLanguages: (lang) =>
    set({
      languages: lang,
    }),
}))
