import create from 'zustand'
import { ArticleTypeEnum } from 'enums/common.enum'

type State = {
  articleType: ArticleTypeEnum | undefined
  setArticleType: (pathname: any) => any
}

export const useArticleStore = create<State>((set) => ({
  articleType: undefined,
  setArticleType: (pathname) => {
    let articleTypeCheck = ArticleTypeEnum.ARTICLE
    if (pathname.toUpperCase().includes(ArticleTypeEnum.NEWS)) {
      articleTypeCheck = ArticleTypeEnum.NEWS
    }
    set(() => ({
      articleType: articleTypeCheck,
    }))

    return articleTypeCheck
  },
}))
