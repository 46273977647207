import { Navigate, useLocation } from 'react-router-dom'
import { Layout, ConfigProvider } from 'antd'
import { useAuth } from '../stores/authorized'

// Components
import Content from './layouts/content'
import Footer from './layouts/footer'
import Navbar from './layouts/navbar'
import Sidebar from './layouts/sidebar'
import { useEffect } from 'react'
import { RoleAdminEnum } from 'enums/roles.enum'

const PrivateRoute = ({ scope, component: Component }: any) => {
  const location = useLocation()
  const { auth, setScope } = useAuth()
  const scopeItem = scope?.replace(/ /g, '_').toLowerCase()

  useEffect(() => {
    const authScope = auth?.user?.scope?.filter((role) => role.startsWith(scopeItem))
    setScope(authScope)
  }, [scopeItem])

  const hasRole = () => {
    return [RoleAdminEnum.ADMIN, RoleAdminEnum.SUPERADMIN].includes(auth?.user?.role)
      ? true
      : auth?.user?.scope.some((role) => role.startsWith(scopeItem))
  }

  if (!auth?.isLoggedIn) {
    return <Navigate replace to='/signin' state={{ from: location.pathname }} />
  }

  if (!hasRole()) {
    return <Navigate replace to='/404' state={{ from: location.pathname }} />
  }

  return (
    <ConfigProvider>
      <Layout id='app-layout' className='min-h-screen'>
        <Sidebar />
        <Layout className='navbar-layout'>
          <Navbar />
          <Content>
            <Component />
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default PrivateRoute
