import fetch from '../utils/fetchAxios'

export const findAllProducts = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/product'
  return fetch(method, path, query)
}

export const createProduct = async (body) => {
  const method = 'POST'
  const path = '/v1/product'
  return fetch(method, path, body)
}

export const updateProduct = async (id: number, body) => {
  const method = 'PATCH'
  const path = `/v1/product/${id}`
  return fetch(method, path, body)
}

export const deleteProduct = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/product/${id}`
  return fetch(method, path, query)
}

// Product info
export const updateProductInfoList = async (body) => {
  const method = 'PATCH'
  const path = '/v1/product/info/list'
  return fetch(method, path, body)
}

export const deleteProductInfo = async (id: number, body = {}) => {
  const method = 'DELETE'
  const path = `/v1/product/info/${id}`
  return fetch(method, path, body)
}

export const deleteImageProductInfo = async (id: number) => {
  const method = 'DELETE'
  const path = `/v1/product/info/${id}/image`
  return fetch(method, path)
}

// Product item
export const createProductItem = async (body) => {
  const method = 'POST'
  const path = '/v1/product/item'
  return fetch(method, path, body)
}

export const updateProductItem = async (id: number, body) => {
  const method = 'PATCH'
  const path = `/v1/product/item/${id}`
  return fetch(method, path, body)
}

export const deleteProductItem = async (id: number, body = {}) => {
  const method = 'DELETE'
  const path = `/v1/product/item/${id}`
  return fetch(method, path, body)
}

export const deleteImageProductItem = async (id: number, body) => {
  const method = 'DELETE'
  const path = `/v1/product/item/${id}/image`
  return fetch(method, path, body)
}
