import { Spin, Button, Checkbox, Form, Input, notification } from 'antd'
import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../stores/authorized'
import { signIn } from 'services/auth.service'

import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import './SignIn.scss'
import { AUTHEN, LOGIN_TYPE, STORAGE_AUTH } from 'constants/authoriz'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const SignInPage = () => {
  const navigate = useNavigate()
  const { setAuth } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const onFinish = (values: any) => {
    console.log(values)
    setLoading(true)

    signIn(values)
      .then((response) => {
        if (response?.status.code === 200) {
          const { data, token } = response.data
          const decoded: any = jwtDecode(token.accessToken)
          const maxAge = dayjs(new Date(decoded.exp * 1000)).diff(dayjs(), 'second')
          sessionStorage.setItem(AUTHEN.ACCESS_TOKEN, token?.refreshToken)
          sessionStorage.setItem(AUTHEN.AUTHEN_TOKEN, token?.accessToken)
          sessionStorage.setItem(STORAGE_AUTH, JSON.stringify({ ...data, isLoggedIn: true }))
          setAuth({
            user: data,
            isLoggedIn: true,
            authorized: {
              ...token,
              expiresIn: maxAge,
              scope: decoded?.scpoe,
            },
          })
          if (data.status === LOGIN_TYPE.CONTINUE) {
            navigate('/password/reset')
            return
          }
          navigate('/home/banner')
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        notification.error({
          message: 'invalid user password',
          description: error.error.description + ', please check and try again',
        })
      })

    // if (values.email === 'admin@aestemaworld.com') {
    //   // Example set value to store state for login user
    //   setAuth({
    //     user: {
    //       title: 'M.r.',
    //       firstName: 'Aestema',
    //       lassName: 'World',
    //       name: 'Aestema World',
    //       email: 'admin@aestemaworld.com',
    //     },
    //     isLoggedIn: true,
    //     authorized: {
    //       accessToken: 'accessToken',
    //       refreshToken: 'refreshToken',
    //       expiresIn: 300,
    //       tokenType: 'bearer',
    //       scope: 'full_access',
    //     },
    //   })
    // } else {
    //   notification.error({
    //     message: 'Unable to log in',
    //     description: 'Please check your email and password',
    //   })
    //   setLoading(false)
    // }
  }

  return (
    <>
      <div className='flex justify-center items-center content-center bg-gradient-to-br from-sky-50 to-gray-200 min-h-screen'>
        <div className='w-4/5'>
          <div className='m-auto md:w-8/12 lg:w-6/12 xl:w-6/12'>
            <Spin spinning={loading} indicator={antIcon}>
              <div className='rounded-xl bg-white shadow-xl'>
                <div className='p-6 pt-0 sm:p-16 '>
                  <div className='space-y-4 p-5 rounded-lg'>
                    <img
                      src='./assets/images/logo.png'
                      loading='lazy'
                      className='m-auto w-3/5'
                      alt='logo'
                    />
                  </div>
                  <div className='p-6'>
                    <Form
                      name='login'
                      className='login-form'
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name='email'
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          {
                            required: true,
                            message: 'Please input your E-mail!',
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <UserOutlined className='site-form-item-icon text-gray-400 relative -top-0.5' />
                          }
                          placeholder='Username'
                          size='large'
                          className='rounded-lg'
                        />
                      </Form.Item>
                      <Form.Item
                        name='password'
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                      >
                        <Input
                          prefix={
                            <LockOutlined className='site-form-item-icon text-gray-400 relative -top-0.5' />
                          }
                          type='password'
                          placeholder='Password'
                          size='large'
                          className='rounded-lg'
                        />
                      </Form.Item>
                      <Form.Item>
                        <Form.Item name='remember' valuePropName='checked' noStyle>
                          <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                      </Form.Item>

                      <Form.Item>
                        <Button type='primary' htmlType='submit' shape='round' size='large' block>
                          Log in
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>

                  <div className='space-y-4 text-gray-600 text-center sm:-mb-8'>
                    <p className='text-xs'>
                      By proceeding, you agree to our <br />
                      <span className='underline '>Terms of Use</span> and confirm you have read our{' '}
                      <span className='underline'>Privacy and Cookie Statement</span>.
                    </p>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignInPage
