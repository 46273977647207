import { Button, Drawer, Form } from 'antd'
import { useEffect, useState } from 'react'
import FormProductItem from './FormProductItem'

interface IProps {
  onSuccess: (item?: any) => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
  indexItem?: number
  productId?: number
}
const ProductItemDrawer = ({
  onSuccess,
  onClose,
  isOpen,
  dataSource,
  indexItem,
  productId,
}: IProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)
  const [productItemId, setProductItemId] = useState<number>()

  useEffect(() => {
    if (isOpen) {
      if (dataSource?.productItemId) {
        setProductItemId(dataSource?.productItemId)
      }
    } else {
      onCancel()
    }
    setLoading(false)
  }, [isOpen])

  const onCancel = () => {
    if (!loading) {
      form.resetFields()
      setProductItemId(undefined)
      onClose()
    }
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save Product
        </Button>
      </div>
    </div>
  )

  return (
    <>
      <Drawer
        title='Manage product items'
        width={1024}
        closable={false}
        maskClosable={false}
        onClose={onCancel}
        open={isOpen}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<FooterDrawer />}
      >
        {isOpen && (
          <FormProductItem
            form={form}
            productId={productId}
            productItemId={productItemId}
            indexItem={indexItem}
            dataSource={dataSource}
            onSuccess={onSuccess}
            onClose={onCancel}
          />
        )}
      </Drawer>
    </>
  )
}

export default ProductItemDrawer
