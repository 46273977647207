import { useEffect, useState } from 'react'
import {
  Col,
  Row,
  Spin,
  Form,
  Input,
  Modal,
  Switch,
  message,
  PageHeader,
  FormInstance,
  Tag,
  Button,
  Table,
  Select,
} from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { createAdmin, updateAdmin, getAdmin } from 'services/admin.service'

import dayjs from 'dayjs'
import { Admin } from '@stores/types/admin.types'
import { MenuPermissionEnum } from 'enums/roles.enum'
import { RolePermission } from 'constants/authoriz'

const { confirm } = Modal
const { Column } = Table

interface IProps {
  form: FormInstance
  adminId?: number
  onSuccess?: (item?: any) => void
  dataSource?: any
}

const FormAdmin = ({ form, adminId, onSuccess, dataSource = {} }: IProps) => {
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValue = {
          ...values,
        }
        console.log('itemValue', itemValue)
        const promise = values?.id ? updateAdmin(values?.id, itemValue) : createAdmin(itemValue)
        promise
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                form.resetFields()
                onSuccess()
              }
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const handleChange = (value: string[], name) => {
    if (value?.length && !value?.includes(`${name}:read`)) {
      const scope = form.getFieldValue('scope')
      if (scope) {
        scope[name] = [`${name}:read`, ...value]
        form.setFieldValue('scope', scope)
      }
    }
  }

  return (
    <>
      <Form
        form={form}
        name='admin-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Form.Item name='id'>
              <Input hidden />
            </Form.Item>

            <Col span={12}>
              <Form.Item
                label='First Name'
                name='firstName'
                rules={[
                  {
                    required: true,
                    message: 'please input firstname',
                  },
                ]}
              >
                <Input size='large' placeholder='Firstname' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Last Name'
                name='lastName'
                rules={[
                  {
                    required: true,
                    message: 'please input lastName',
                  },
                ]}
              >
                <Input size='large' placeholder='Lastname' />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  { required: true, message: 'please input admin email' },
                ]}
              >
                <Input size='large' placeholder='Email for sign in to backend' />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Phone'
                name='phone'
                rules={[
                  {
                    required: true,
                    message: 'please input phone',
                  },
                ]}
              >
                <Input size='large' placeholder='phone' />
              </Form.Item>
            </Col>
            {!adminId && (
              <>
                <Col span={12}>
                  <Form.Item
                    label='Password'
                    name='password'
                    rules={[{ required: true, message: 'Please input your new password!' }]}
                    className='w-full'
                  >
                    <Input.Password placeholder='New Password' size='large' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='Confirm Password'
                    name='confirmPassword'
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error('The two passwords that you entered do not match!'),
                          )
                        },
                      }),
                    ]}
                    className='w-full'
                  >
                    <Input.Password placeholder='confirm password' size='large' />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>

          <Row gutter={16}>
            <Col span={24} className='m-2 border border-solid rounded-md'>
              <div className='p-3'>
                <PageHeader title='Permission' subTitle='manage permission' className='p-0' />

                <Table
                  loading={loading}
                  pagination={false}
                  size='small'
                  rowKey={(record) => `faq-category-${record?.name + ''}`}
                  dataSource={RolePermission}
                  className='mt-2 mb-2'
                >
                  <Column
                    title='Menu'
                    className='break-word'
                    ellipsis
                    render={(record) => (
                      <div className='flex gap-2 text-md font-bold'>{record?.name}</div>
                    )}
                  />

                  <Column
                    title='Permissions'
                    className='break-word'
                    ellipsis
                    render={(record) => {
                      const name = record.name?.replace(/ /g, '_').toLowerCase()
                      return (
                        <Form.Item shouldUpdate name={['scope', name]} noStyle>
                          <Select
                            mode='multiple'
                            allowClear
                            placeholder='Please select role'
                            className='w-full rounded-lg'
                            onChange={(value) => handleChange(value, name)}
                            options={[
                              { label: 'read', value: `${name}:read` },
                              { label: 'create', value: `${name}:create` },
                              { label: 'update', value: `${name}:update` },
                            ]}
                          />
                        </Form.Item>
                      )
                    }}
                  />
                </Table>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} className='pt-5 border-t border-solid'>
              <div className='flex items-center'>
                <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which data will be listed on status (Active/InActive)
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormAdmin
