import fetch from '../utils/fetchAxios'

export const findAllClinics = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/clinic'
  return fetch(method, path, query)
}

export const createClinic = async (body) => {
  const method = 'POST'
  const path = '/v1/clinic'
  return fetch(method, path, body)
}

export const updateClinic = async (id: number, body) => {
  const method = 'PATCH'
  const path = `/v1/clinic/${id}`
  return fetch(method, path, body)
}

export const deleteClinic = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/clinic/${id}`
  return fetch(method, path, query)
}
