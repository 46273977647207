import fetch from 'utils/fetchAxios'

export const findAllAdmins = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/admin'
  return fetch(method, path, query)
}

export const getAdmin = async (id: any, query = {}) => {
  const method = 'GET'
  const path = `/v1/admin/${id}`
  return fetch(method, path, query)
}

export const createAdmin = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/admin'
  return fetch(method, path, body)
}

export const updateAdmin = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/admin/${id}`
  return fetch(method, path, body)
}

export const deleteAdmin = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/admin/${id}`
  return fetch(method, path, query)
}
