import fetch from 'utils/fetchAxios'

export const findAllArticles = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/article'
  return fetch(method, path, query)
}

export const getArticle = async (id: number, query = {}) => {
  const method = 'GET'
  const path = `/v1/article/${id}`
  return fetch(method, path, query)
}

export const createArticle = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/article'
  return fetch(method, path, body)
}

export const updateArticle = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/article/${id}`
  return fetch(method, path, body)
}

export const deleteArticle = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/article/${id}`
  return fetch(method, path, query)
}

export const updateArticleContent = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/article-content/${id}`
  return fetch(method, path, body)
}

export const findArticleCategories = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/article-category'
  return fetch(method, path, query)
}

export const createArticleCategory = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/article-category'
  return fetch(method, path, body)
}

export const updateArticleCategory = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/article-category/${id}`
  return fetch(method, path, body)
}

export const deleteArticleCategory = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/article-category/${id}`
  return fetch(method, path, query)
}
