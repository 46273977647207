import { useEffect, useState } from 'react'
import { Drawer, Form, Button } from 'antd'
import { pageTypeEnum } from 'enums/common.enum'

import FormSetting from './FormSetting'
import FormWebConfig from './FormWebConfig'

interface IProps {
  onSuccess: () => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
}
const SettingDrawer = ({ onSuccess, onClose, isOpen, dataSource }: IProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      form.setFieldsValue(dataSource)
    }
    return () => {
      isOpen && form.resetFields()
    }
  }, [isOpen])

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div className='flex gap-2'>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Drawer
      title='Manage Content'
      width={1024}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<FooterDrawer />}
    >
      {!!isOpen && (
        <>
          {dataSource?.pageType === pageTypeEnum.WEB_CONFIG ? (
            <FormWebConfig form={form} dataSource={dataSource} onSuccess={onSuccess} />
          ) : (
            <FormSetting form={form} dataSource={dataSource} onSuccess={onSuccess} />
          )}
        </>
      )}
    </Drawer>
  )
}

export default SettingDrawer
