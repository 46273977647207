import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Tag,
  Modal,
  Input,
  message,
  Space,
  Tooltip,
} from 'antd'
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  FormOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { Admin } from 'stores/types/admin.types'
import { deleteAdmin, findAllAdmins } from 'services/admin.service'

import AdminDrawer from './components/Drawer'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input

export interface ISearchState {
  searchText: string | undefined
  categoryId: string | undefined
  adminType: string | undefined
}

const AdminPage = () => {
  const { setPageHeader } = useGlobalStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Admin | undefined>()
  const [admins, setAdmins] = useState<Admin[]>([])

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })

  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
    categoryId: undefined,
    adminType: undefined,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setPageHeader({
      title: 'Admin Overview',
      subTitle: 'Profile Management',
      backIcon: false,
      extra: [
        <Button
          key={1}
          type='primary'
          shape='round'
          icon={<PlusOutlined className='text-base relative -top-0.5' />}
          onClick={() => {
            setIsOpen(true)
            setDataSource(undefined)
          }}
        >
          create admin
        </Button>,
      ],
    })
    findAdmins()
  }, [])

  const findAdmins = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      ...param,
    }

    const payload: any = await findAllAdmins(body)
    if (payload?.status?.code === 200) {
      setAdmins(payload.data?.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }

    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findAdmins({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteAdmin(item.id)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findAdmins()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findAdmins({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      categoryId: undefined,
    }
    setSearch(searching)
    findAdmins({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findAdmins({
      search,
    })
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Space className='flex' align='start'>
            <Search
              allowClear
              size='large'
              placeholder='Search..'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />
            <Button
              type='link'
              onClick={clearSearch}
              disabled={!search.searchText && !search.categoryId}
            >
              clear
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `admin-${record?.id + ''}`}
            dataSource={admins}
            className='mt-2 mb-2'
          >
            <Column
              title='#'
              className='break-word'
              ellipsis
              width={250}
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>
                    {record?.firstName} {record?.lastName}
                  </div>
                </div>
              )}
            />
            <Column
              title='Email'
              className='break-word'
              render={(record) => (
                <>
                  <div className='text-md font-normal'>
                    <span>{record.email}</span>
                  </div>
                </>
              )}
            />
            <Column
              title='Phone'
              className='break-word'
              render={(record) => (
                <>
                  <div className='text-md font-normal'>{record.phone}</div>
                </>
              )}
            />
            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.createdAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.updatedAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Status'
              align='center'
              className='break-word text-center'
              width={110}
              render={(record) => (
                <div className='text-center'>
                  {record?.isActive ? (
                    <Tag color='processing' className='m-0 w-20'>
                      active
                    </Tag>
                  ) : (
                    <Tag color='error' className='m-0  w-20'>
                      unactive
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              title='Verify Email'
              align='center'
              className='break-word text-center'
              width={110}
              render={(record) => (
                <div className='text-center'>
                  {record?.isVerify ? (
                    <Tag color='processing' className='m-0 w-20'>
                      verified
                    </Tag>
                  ) : (
                    <Tag color='warning' className='m-0 w-20'>
                      unverified
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              width={100}
              render={(record) => (
                <div className='flex justify-between'>
                  <Tooltip title='Editable'>
                    <Button
                      type='dashed'
                      icon={<FormOutlined />}
                      shape='circle'
                      onClick={() => {
                        setDataSource(record)
                        setIsOpen(true)
                      }}
                    />
                  </Tooltip>
                  <Tooltip title='Delete'>
                    <Button
                      type='link'
                      icon={<DeleteOutlined />}
                      shape='circle'
                      className='text-primary-orange'
                      onClick={() => {
                        handelDelete(record)
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <AdminDrawer onClose={onClose} onSuccess={onClose} isOpen={isOpen} dataSource={dataSource} />
    </>
  )
}

export default AdminPage
