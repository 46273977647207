import fetch from 'utils/fetchAxios'

export const findAllUsers = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/user'
  return fetch(method, path, query)
}

export const getUser = async (id: any, query = {}) => {
  const method = 'GET'
  const path = `/v1/user/${id}`
  return fetch(method, path, query)
}

export const createUser = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/user'
  return fetch(method, path, body)
}

export const updateUser = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/user/${id}`
  return fetch(method, path, body)
}

export const deleteUser = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/user/${id}`
  return fetch(method, path, query)
}
