import { useEffect, useState } from 'react'
import { Drawer, Form, Button } from 'antd'
import { useClinicStore } from 'stores/clinic'

import FormClinic from './FormClinic'

interface IProps {
  onSuccess: () => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
}
const ClinicDrawer = ({ onSuccess, onClose, isOpen, dataSource }: IProps) => {
  const { setBranches } = useClinicStore()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      if (dataSource?.id) {
        form.setFieldsValue({
          ...dataSource,
          linkGoogleMap: dataSource?.googlemap?.linkGoogleMap,
          branches: dataSource?.branches?.map((item) => {
            return {
              name: item?.branchName?.th,
            }
          }),
        })
        setBranches(dataSource?.branches)
      }
    }
    return () => {
      isOpen && form.resetFields()
      setBranches([])
    }
  }, [isOpen])

  const onCancel = () => {
    form.resetFields()
    setBranches([])
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Drawer
      title='Manage Clinic'
      width={900}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<FooterDrawer />}
    >
      {!!isOpen && (
        <FormClinic
          form={form}
          isBranches={false}
          dataSource={dataSource}
          clinicId={dataSource?.id}
          onSuccess={onSuccess}
        />
      )}
    </Drawer>
  )
}

export default ClinicDrawer
