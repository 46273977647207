import { useEffect, useState } from 'react'
import {
  Col,
  Row,
  Spin,
  Form,
  Input,
  Modal,
  Switch,
  message,
  PageHeader,
  FormInstance,
  Select,
} from 'antd'
import {
  ExclamationCircleOutlined,
  PictureOutlined,
  FileDoneOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { createFaq, updateFaq } from 'services/faq.service'
import { FaqCategory } from '@stores/types/faq.types'

import TabByLanguage from './TabByLanguage'
const { confirm } = Modal
const { Option } = Select

interface IProps {
  form: FormInstance
  faqId?: number
  onSuccess?: (item?: any) => void
  dataSource?: any
  categories: FaqCategory[]
}

const FormQandA = ({ form, faqId, onSuccess, dataSource = {}, categories }: IProps) => {
  const { languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (dataSource?.id) {
      form.setFieldsValue({
        id: dataSource?.id,
        categoryId: dataSource?.categoryId,
        isActive: dataSource?.isActive,
      })
      languages.map((item) => {
        form.setFieldValue([item.locale], {
          question: dataSource.question[item.locale],
          answer: dataSource.answer[item.locale],
        })
      })
    }
    setLoading(false)
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const body = {
          id: values?.id,
          categoryId: values.categoryId,
          question: {},
          answer: {},
          isActive: values?.isActive,
        }
        for (const item of languages) {
          body.question = {
            ...body.question,
            [item.locale]: values[item.locale]?.question,
          }
          body.answer = {
            ...body.answer,
            [item.locale]: values[item.locale]?.answer,
          }
        }
        const promise = values?.id ? updateFaq(values?.id, body) : createFaq(body)
        promise
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                form.resetFields()
                onSuccess()
              }
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  return (
    <>
      <Form
        form={form}
        name='article-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name='id' className='hidden'>
                <Input hidden />
              </Form.Item>

              <Form.Item name='categoryId' label='Category'>
                <Select
                  size='large'
                  placeholder='choose categories'
                  className='rounded-md'
                  disabled={loading}
                  allowClear
                >
                  {categories.length &&
                    categories.map((item: FaqCategory, index: number) => {
                      return (
                        <Option key={`select-category-${index}`} value={item.categoryId}>
                          {item?.name?.en}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='m-2 border border-solid rounded-md'>
              <div className='p-3'>
                <PageHeader title='Q&A Manager' subTitle='Write Q&A by language' className='p-0' />
                <TabByLanguage form={form} dataSource={dataSource} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} className='pt-5 border-t border-solid'>
              <div className='flex items-center'>
                <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which data will be listed on status (Active/InActive)
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormQandA
