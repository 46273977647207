import { useLocation, useNavigate } from 'react-router-dom'
import { Layout, Menu, MenuProps } from 'antd'
import {
  FormOutlined,
  ReadOutlined,
  ApartmentOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  UserOutlined,
  LaptopOutlined,
  MailOutlined,
  LockOutlined,
} from '@ant-design/icons'
import { useGlobalStore } from '../../../stores/global'
import { useEffect, useState } from 'react'
import { MenuPermissionEnum, RoleAdminEnum } from 'enums/roles.enum'
import { useAuth } from 'stores/authorized'

const { Sider } = Layout

const items: MenuProps['items'] = [
  {
    key: '/home',
    icon: <HomeOutlined />,
    label: MenuPermissionEnum.HOME,
    children: [
      {
        label: 'Banner',
        key: '/home/banner',
      },
      {
        label: 'Home About',
        key: '/home/about',
      },
      {
        label: 'Statistic',
        key: '/home/statistic',
      },
    ],
  },
  {
    key: '/about',
    icon: <InfoCircleOutlined />,
    label: MenuPermissionEnum.ABOUT,
    children: [
      {
        label: 'CEO Message',
        key: '/about/ceo-message',
      },
      {
        label: 'Facilities',
        key: '/about/facilities',
      },
    ],
  },
  {
    key: '/clinic',
    icon: <ApartmentOutlined />,
    label: MenuPermissionEnum.CLINIC,
  },
  {
    key: '/content/news',
    icon: <FormOutlined />,
    label: MenuPermissionEnum.NEWS,
  },
  {
    key: '/content/article',
    icon: <ReadOutlined />,
    label: MenuPermissionEnum.ARTICLE,
    children: [
      {
        label: 'Blogs',
        key: '/article/blog',
      },
      {
        label: 'FAQ',
        key: '/article/faq',
      },
    ],
  },
  {
    key: '/product',
    icon: <ShopOutlined />,
    label: MenuPermissionEnum.PRODUCT,
  },
  {
    key: '/user',
    icon: <UserOutlined />,
    label: MenuPermissionEnum.USERS,
  },
  {
    key: '/medical-education',
    icon: <LaptopOutlined />,
    label: MenuPermissionEnum.MEDICAL_EDUCATION,
    children: [
      {
        label: 'Events',
        key: '/medical-education/event',
      },
      {
        label: 'Webinar',
        key: '/medical-education/webinar',
      },
      {
        label: 'Live demo',
        key: '/medical-education/live-demo',
      },
      {
        label: 'Scholar',
        key: '/medical-education/scholar',
      },
    ],
  },
  {
    key: '/contact',
    icon: <MailOutlined />,
    label: MenuPermissionEnum.CONTACT,
  },
  {
    key: '/setting',
    icon: <SettingOutlined />,
    label: MenuPermissionEnum.SETTING,
  },
  {
    key: '/admin',
    icon: <LockOutlined />,
    label: 'Administrator',
    // label: <LinkMenu path='/product' name='Product' />,
  },
]

const Sidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useAuth()
  const { collapsed, setCollapsed } = useGlobalStore()
  const [current, setCurrent] = useState('home')
  const [menuItems, setMenuItems] = useState<any>(items)

  useEffect(() => {
    setCurrent(location.pathname)
    if (![RoleAdminEnum.ADMIN, RoleAdminEnum.SUPERADMIN].includes(auth?.user?.role)) {
      const menu = items?.filter((scope: any) =>
        auth?.user?.scope.some((role) =>
          role.startsWith(scope?.label?.replace(/ /g, '_').toLowerCase()),
        ),
      )
      setMenuItems(menu)
    }
  }, [])

  const pathBackList = ['/home']
  const onClick: MenuProps['onClick'] = (e) => {
    if (!pathBackList.includes(e.key)) {
      setCurrent(e.key)
      navigate(e.key)
    }
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed as boolean}
      onCollapse={() => setCollapsed(!collapsed)}
      id='app-sidenav'
    >
      <section className='sidenav-header'>
        <div className='logo subpixel-antialiased font-bold text-center text-xl h-8 m-4 flex justify-center items-center'>
          {collapsed ? <span className='text-base'>Aestema</span> : 'Aestema World'}
        </div>
      </section>

      <div className='sidenav-content'>
        <Menu
          theme='dark'
          mode='inline'
          // inlineIndent={20}
          items={menuItems}
          // selectedKeys={[current]}
          // defaultOpenKeys={['/home']}
          onClick={onClick}
        />
      </div>
    </Sider>
  )
}

export default Sidebar
