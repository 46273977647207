import { useEffect, useState } from 'react'
import { Input, Modal } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as faIconSolid from '@fortawesome/free-solid-svg-icons'
import * as faIconRegular from '@fortawesome/free-regular-svg-icons'
import { debounce } from 'utils/common'
const { Search } = Input
const faIcons = Object.assign({}, faIconSolid, faIconRegular)

interface IIconModalProps {
  isOpen: boolean
  currentIcon: any
  onSuccess: (icon) => void
  onClose: () => void
}
const IconModal = ({ isOpen, currentIcon, onSuccess, onClose }: IIconModalProps) => {
  const [iconList, setIconList] = useState<any>(Object.keys(faIcons))
  const [activeIcon, setActiveIcon] = useState<any>()

  useEffect(() => {
    setActiveIcon(currentIcon)
  }, [currentIcon])

  const handleOk = () => {
    onSuccess(activeIcon)
  }

  const handleCancel = () => {
    onClose()
  }

  const onSearching = debounce(function (event: any) {
    const term = event.target.value
    if (term && term !== '') {
      const iconValues = Object.keys(faIcons).filter((f) => f.toLowerCase().indexOf(term) > -1)
      setIconList(iconValues)
    } else {
      setIconList(Object.keys(faIcons))
    }
  }, 200)

  return (
    <Modal
      title='Icon List'
      width={800}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ top: 20 }}
    >
      <div className='mb-4'>
        <Search onChange={onSearching} size='large' allowClear placeholder='Search icon' />
      </div>
      {!!isOpen && (
        <div className='grid grid-cols-10 gap-3 h-[400px] overflow-y-auto'>
          {!!iconList?.length &&
            iconList.map((icon) => {
              return (
                !['fas', 'prefix', 'far'].includes(icon) && (
                  <div
                    key={icon}
                    className={`${
                      icon === activeIcon
                        ? 'border-primary bg-primary text-white'
                        : 'bg-secondary border-gray-500'
                    } border border-solid hover:border-primary hover:text-primary h-16 w-16 flex justify-center items-center rounded-lg cursor-pointer`}
                    onClick={() => setActiveIcon(icon)}
                  >
                    <FontAwesomeIcon key={icon} icon={faIcons[icon]} size='2xl' />
                  </div>
                )
              )
            })}
        </div>
      )}
    </Modal>
  )
}

export default IconModal
