import create from 'zustand'
import { Clinic } from './types/clinic.types'

type State = {
  branches: Clinic[]
  setBranches: (branches: Clinic[]) => void
}

export const useClinicStore = create<State>((set) => ({
  branches: [],
  setBranches: (branch) =>
    set(() => ({
      branches: branch,
    })),
}))
