import { useAuth } from 'stores/authorized'
import { RoleAdminEnum } from 'enums/roles.enum'

interface IProps {
  permission: string
  scope: string[] | undefined
  children: React.ReactNode
}

const PermissionAccess = ({ permission, scope, children }: IProps) => {
  const { auth } = useAuth()
  if (
    [RoleAdminEnum.ADMIN, RoleAdminEnum.SUPERADMIN].includes(auth?.user?.role) ||
    scope?.includes(permission)
  ) {
    return <>{children}</>
  }
  return <></>
}

export default PermissionAccess
