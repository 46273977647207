import { Button, Drawer, Form } from 'antd'
import { useEffect, useState } from 'react'
import FormClinic from './FormClinic'

interface IProps {
  onSuccess: (item?: any) => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
  isBranches: boolean
  clinicId?: number
}
const ClinicBranchDrawer = ({
  onSuccess,
  onClose,
  isOpen,
  dataSource = {},
  isBranches,
  clinicId,
}: IProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
    if (Object.keys(dataSource)?.length) {
      form.setFieldsValue({
        ...dataSource,
        linkGoogleMap: dataSource?.googlemap?.linkGoogleMap || dataSource?.linkGoogleMap,
      })
    }
  }, [])

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save Branches
        </Button>
      </div>
    </div>
  )

  return (
    <>
      <Drawer
        title='Manage Branch Clinic'
        width={900}
        closable={false}
        maskClosable={false}
        onClose={onCancel}
        open={isOpen}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<FooterDrawer />}
      >
        {!!isOpen && (
          <FormClinic
            form={form}
            clinicId={clinicId}
            isBranches={isBranches}
            onSuccess={onSuccess}
            dataSource={dataSource}
          />
        )}
      </Drawer>
    </>
  )
}

export default ClinicBranchDrawer
