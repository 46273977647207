import fetch from 'utils/fetchAxios'

export const findFaqs = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/faq'
  return fetch(method, path, query)
}

export const getFaq = async (id: number, query = {}) => {
  const method = 'GET'
  const path = `/v1/faq/${id}`
  return fetch(method, path, query)
}

export const createFaq = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/faq'
  return fetch(method, path, body)
}

export const updateFaq = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/faq/${id}`
  return fetch(method, path, body)
}

export const deleteFaq = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/faq/${id}`
  return fetch(method, path, query)
}

export const findFaqCategories = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/faq/category'
  return fetch(method, path, query)
}

export const createFaqCategory = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/faq/category'
  return fetch(method, path, body)
}

export const updateFaqCategory = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/faq/category/${id}`
  return fetch(method, path, body)
}

export const deleteFaqCategory = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/faq/category/${id}`
  return fetch(method, path, query)
}
