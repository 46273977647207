import { Tabs, Form, FormInstance, Input } from 'antd'
import { useEffect, useState } from 'react'
import { useGlobalStore } from 'stores/global'

interface ITapProps {
  label: string
  key: string
  children: React.ReactNode
  forceRender: boolean
}

interface IPropFormByLanguage {
  locale: string
  isRequired: boolean
  dataSource?: any
}

interface IPropTabByLanguage {
  form: FormInstance
  isRequired?: boolean
  dataSource?: any
}

const FormItemByLanguage = ({ locale, isRequired }: IPropFormByLanguage) => {
  return (
    <>
      <Form.List name={locale}>
        {(fields) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Form.Item
                  {...restField}
                  name={[name, 'title']}
                  label='Banner Title'
                  rules={[
                    {
                      required: isRequired,
                      message: `Please enter title [${locale.toUpperCase()}]`,
                    },
                  ]}
                >
                  <Input placeholder={`title [${locale.toUpperCase()}]`} size='large' />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, 'description']}
                  rules={[
                    {
                      required: isRequired,
                      message: `please input short description [${locale.toUpperCase()}]`,
                    },
                  ]}
                  label='A short banner descriptions'
                >
                  <Input.TextArea
                    size='large'
                    placeholder={`tell me about a banner. [${locale.toUpperCase()}]`}
                    rows={3}
                  />
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </>
  )
}

const FromBannerByLanguage = ({ form, dataSource, isRequired = true }: IPropTabByLanguage) => {
  const { languages } = useGlobalStore()
  const [tabItems, setTabItems] = useState<ITapProps[]>([])

  useEffect(() => {
    const items: ITapProps[] = []
    languages.map((item) => {
      items.push({
        label: item.country,
        key: item.locale,
        children: (
          <FormItemByLanguage
            locale={item.locale}
            dataSource={dataSource}
            isRequired={isRequired}
          />
        ),
        forceRender: true,
      })

      form.setFieldsValue({
        [item.locale]: [
          {
            title: dataSource?.options?.[item.locale]?.title,
            description: dataSource?.options?.[item.locale]?.description,
          },
        ],
      })
    })

    setTabItems(items)

    return () => {
      items.length = 0
    }
  }, [isRequired])

  return (
    <>
      <Tabs items={tabItems} />
    </>
  )
}

export default FromBannerByLanguage
