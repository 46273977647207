import { useEffect, useState } from 'react'
import { Drawer, Form, Button } from 'antd'
import { getAdmin } from 'services/admin.service'
import { Admin } from 'stores/types/admin.types'

import FormInfomation from './FormInfomation'

interface IProps {
  onSuccess: (item) => void
  onClose: () => void
  isOpen: boolean
  type: 'password' | 'information'
}
const AccountDrawer = ({ onSuccess, onClose, isOpen, type }: IProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)
  const [admin, setAdmin] = useState<Admin>()

  useEffect(() => {
    if (isOpen) {
      findUser()
    }
    return () => {
      isOpen && form.resetFields()
    }
  }, [isOpen])

  const findUser = async () => {
    setLoading(true)
    const payload = await getAdmin('owner')
    if (payload?.status?.code === 200) {
      setAdmin(payload?.data)
      form.setFieldsValue(payload?.data)
    }
    setLoading(false)
  }

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div className='flex gap-2'>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Drawer
      title='Admin Profile'
      width={768}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<FooterDrawer />}
    >
      {!!isOpen && (
        <FormInfomation form={form} adminId={admin?.id + ''} onSuccess={onSuccess} type={type} />
      )}
    </Drawer>
  )
}

export default AccountDrawer
