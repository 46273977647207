export enum MenuPermissionEnum {
  HOME = 'Home',
  ABOUT = 'About us',
  CLINIC = 'Clinic',
  NEWS = 'News',
  ARTICLE = 'Article',
  PRODUCT = 'Product',
  USERS = 'Users',
  MEDICAL_EDUCATION = 'Medical Education',
  CONTACT = 'Contact',
  SETTING = 'Setting',
}

export const enum RoleAdminEnum {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}
