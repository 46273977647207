import React, { useState, useEffect } from 'react'
import { Upload, Modal, Spin, Button } from 'antd'
import { LoadingOutlined, DeleteOutlined, EyeOutlined, ZoomInOutlined } from '@ant-design/icons'
import { convertImage, formatBytes } from 'utils/convertImage'

// We're sorry, but your image is to small for the seleted product size. \n Please replace the image or choose a smaller product size.
const enum ListTypeEnum {
  CARD,
  LIST,
  NONE,
}

interface IProps {
  imageUrl?: any
  showPreviewIcon?: boolean
  showRemoveIcon?: boolean
  multiple?: boolean
  accept?: string
  listType?: ListTypeEnum | string
  onUpload: (file: any, field?: string) => void
  onDelete?: (file: any, field?: string) => void
  maxCount?: number
  className?: string
  children?: React.ReactNode
  field?: string
}

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.readAsDataURL(img)
  reader.addEventListener('load', async () => {
    convertImage(reader.result, img).then((file) => {
      callback(file)
    })
  })
}

const parseFileUrlToObject = async (url) => {
  const name = url.split('/')
  const response = await fetch(url)
  const blob = await response.blob()
  const file = new File([blob], name[name.length - 1], { type: blob.type })
  return file
}

const childrenElem = (
  <p>
    อัพโหลดรูปภาพ <br />
    jpeg, jpg, png
  </p>
)

const FileUpload = ({
  imageUrl,
  // showPreviewIcon = true,
  // showRemoveIcon = true,
  multiple = false,
  accept = 'image/*',
  listType = ListTypeEnum.LIST,
  onUpload,
  onDelete,
  className,
  children = childrenElem,
  field,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [fileList, setFileList] = useState([] as any)
  const [filePreview, setFilePreview] = useState({
    previewImage: '',
    previewVisible: false,
  })

  useEffect(() => {
    if (typeof onDelete === 'function') {
      if (typeof imageUrl === 'string' && imageUrl) {
        // avilable file upload
        if (imageUrl.includes('http')) {
          const fetchData = async () => {
            const fileObject = await parseFileUrlToObject(imageUrl)
            const file = {
              size: fileObject.size,
              type: fileObject.type,
              name: fileObject.name,
              url: imageUrl,
              path: imageUrl,
            }
            setFileList([file])
          }
          fetchData()
        } else {
          // new upload
          setFileList([
            {
              ...fileList[0],
              url: imageUrl,
              path: imageUrl,
            },
          ])
        }
      } else {
        setFileList([])
      }
    } else {
      setFileList([])
    }
  }, [imageUrl])

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = ''
    }

    setFilePreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  const handleCancel = () => {
    setFilePreview({ ...filePreview, previewVisible: false })
  }

  const handleDelete = (file) => {
    if (fileList?.length) {
      if (onDelete) {
        onDelete(file)
      }
    }
  }

  const handleUpload = (info) => {
    setTimeout(() => {
      if (
        // info.file.status !== 'removed' &&
        // info.file.status === 'done' &&
        info.file.status === 'uploading' &&
        info.file.originFileObj
      ) {
        setLoading(true)
        // Get url from response in real world.
        getBase64(info.file.originFileObj, async (image) => {
          const imageUrl = await image.imageUrl
          if (ListTypeEnum.NONE === listType) {
            setFileList([])
          } else {
            setFileList([
              {
                uid: info.file.uid,
                size: info.file.size,
                type: info.file.type,
                name: info.file.name,
                url: imageUrl,
                path: imageUrl,
              },
            ])
          }
          setLoading(false)
          onUpload(
            {
              file: info.file.originFileObj,
              imageUrl,
            },
            field,
          )
        })
      } else {
        setLoading(false)
      }
    }, 1)
  }

  const customRequest = async ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const { previewVisible, previewImage } = filePreview

  const previewThumbnailCard = (
    <div className='relative group'>
      <div className=' relative overflow-hidden bg-no-repeat bg-cover'>
        <img
          src={imageUrl}
          className='w-full object-cover hover:scale-110 transition duration-300 ease-in-out'
        />
        <div className='absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-white'></div>
      </div>
      <div className='opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out p-3 flex gap-2 items-center absolute w-full h-full top-0 left-0 rounded-md'>
        <div className='bg-white w-full p-1 gap-2 flex items-center rounded-md'>
          <Button
            type='ghost'
            size='small'
            className='w-1/2 m-0.5'
            onClick={() => handlePreview(fileList[0])}
          >
            <EyeOutlined className='text-base relative -top-0.5' /> Preview
          </Button>
          <Button
            type='primary'
            size='small'
            className='w-1/2 m-0.5'
            onClick={() => handleDelete(fileList[0])}
          >
            <DeleteOutlined className='text-base relative -top-1' /> Delete
          </Button>
        </div>
      </div>
    </div>
  )

  const previewThumbnailList = (
    <div className='flex rounded-md border border-solid relative p-2 bg-gray-50'>
      <div className='relative w-4/12 overflow-hidden bg-no-repeat bg-cover max-w-xs'>
        <img
          src={imageUrl}
          className='h-[100px] w-full object-cover max-w-xs hover:scale-110 transition duration-300 ease-in-out'
        />
        <div className='absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-white'></div>
      </div>
      <div className='w-8/12 flex flex-col justify-center px-4 text-xs leading-5'>
        <div className='truncate'>{fileList[0]?.name}</div>
        <div>{formatBytes(fileList[0]?.size)}</div>
        <div>{fileList[0]?.type}</div>
        <div className='w-full bg-gray-200 h-1 my-2'>
          <div className='bg-blue-600 h-1' style={{ width: '1%' }}></div>
        </div>
        <div className='flex justify-end gap-1'>
          <Button
            type='default'
            size='small'
            shape='circle'
            onClick={() => handlePreview(fileList[0])}
            icon={<ZoomInOutlined className='text-sm' />}
          ></Button>
          <Button
            type='primary'
            size='small'
            shape='circle'
            onClick={() => handleDelete(fileList[0])}
            icon={<DeleteOutlined className='text-sm' />}
          ></Button>
        </div>
      </div>
    </div>
  )

  const uploadButton = (
    <Spin spinning={loading} indicator={<LoadingOutlined spin />}>
      <div className='ant-upload-text text-18'>{children}</div>
    </Spin>
  )

  return (
    <div className='upload h-full w-full'>
      {listType === ListTypeEnum.NONE || !imageUrl ? (
        <Upload
          multiple={multiple}
          fileList={fileList}
          accept={accept}
          className={className}
          customRequest={customRequest}
          onChange={handleUpload}
          onRemove={handleDelete}
          onPreview={handlePreview}
          showUploadList={false}
        >
          {uploadButton}
        </Upload>
      ) : listType === ListTypeEnum.LIST ? (
        previewThumbnailList
      ) : (
        previewThumbnailCard
      )}

      <Modal
        width={768}
        centered
        bodyStyle={{ padding: '4px' }}
        footer={null}
        open={previewVisible}
        onCancel={handleCancel}
      >
        <img alt='file upload' src={previewImage} className='w-full' />
      </Modal>
    </div>
  )
}

export default FileUpload
