import React, { useEffect, useState } from 'react'
import {
  Drawer,
  Form,
  Button,
  Modal,
  Spin,
  Row,
  Col,
  Input,
  PageHeader,
  Table,
  Tooltip,
  message,
} from 'antd'
import {
  ExclamationCircleOutlined,
  FormOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import { useGlobalStore } from 'stores/global'
import { ArticleCategory } from 'stores/types/article.types'
import {
  createArticleCategory,
  deleteArticleCategory,
  findArticleCategories,
  updateArticleCategory,
} from 'services/article.service'
import { ArticleTypeEnum } from 'enums/common.enum'
const { confirm } = Modal
const { Column } = Table

interface IProps {
  onSuccess: () => void
  onClose: () => void
  isOpen: boolean
  categoryType: ArticleTypeEnum
}
const FaqDrawerCategory = ({ onSuccess, onClose, isOpen, categoryType }: IProps) => {
  const [form] = Form.useForm()
  const { languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(true)
  const [categories, setCategories] = useState<ArticleCategory[]>([])

  useEffect(() => {
    form.setFieldValue('categoryType', categoryType)
    if (isOpen) {
      findAll()
    }
  }, [isOpen])

  const findAll = async () => {
    setLoading(true)
    const query = {
      search: {
        categoryType,
      },
    }
    const payload: any = await findArticleCategories(query)
    if (payload?.status?.code === 200) {
      setCategories(payload.data)
    }
    setLoading(false)
  }

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const promise = values?.categoryId
          ? updateArticleCategory(values?.categoryId, values)
          : createArticleCategory(values)
        promise
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                form.resetFields()
                findAll()
              }
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteArticleCategory(item.categoryId)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findAll()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  return (
    <Drawer
      title='Categories'
      width={768}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div className='text-right'>
          <Button disabled={loading} onClick={onCancel}>
            Close
          </Button>
        </div>
      }
    >
      {!!isOpen && (
        <>
          <Form
            form={form}
            name='category-form'
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            initialValues={{
              isActive: true,
            }}
            requiredMark={false}
            scrollToFirstError
          >
            <Spin
              tip='Loading...'
              spinning={loading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <Row gutter={16}>
                <Form.Item name='categoryId' className='hidden'>
                  <Input hidden />
                </Form.Item>
                <Form.Item name='categoryType' className='hidden'>
                  <Input hidden />
                </Form.Item>
              </Row>

              <Row gutter={16}>
                {languages.map((item) => {
                  return (
                    <Col span={9} key={'name' + item.locale}>
                      <Form.Item
                        label={`Category Name [${item.locale.toUpperCase()}]`}
                        name={['name', item.locale]}
                        rules={[
                          { required: true, message: `please enter category name ${item.country}` },
                        ]}
                      >
                        <Input size='large' placeholder='name' />
                      </Form.Item>
                    </Col>
                  )
                })}
                <Col span={5} className='flex items-center'>
                  <div className='flex gap-2 justify-between mt-3'>
                    <Button loading={loading} type='primary' onClick={() => form.submit()}>
                      Save
                    </Button>

                    <Button disabled={loading} onClick={() => form.resetFields()}>
                      Cancle
                    </Button>
                  </div>
                </Col>
              </Row>
            </Spin>
          </Form>

          <div className='p-3'>
            <PageHeader title='Category Overview' className='p-0' />

            <Table
              loading={loading}
              pagination={false}
              size='small'
              rowKey={(record) => `faq-category-${record?.categoryId + ''}`}
              dataSource={categories}
              className='mt-2 mb-2'
            >
              <Column
                title='Name'
                className='break-word'
                ellipsis
                render={(record) => (
                  <div className='flex gap-2 text-md font-normal'>
                    {languages.map((item, key) => {
                      return (
                        <React.Fragment key={`name-${item.locale}`}>
                          <div>
                            [{item.locale.toUpperCase()}] {record?.name?.[item.locale]}
                          </div>
                          {!!(key === 0) && <div>/</div>}
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
              />

              <Column
                width={100}
                render={(record) => (
                  <div className='flex justify-between'>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        onClick={() => {
                          form.setFieldsValue({
                            categoryId: record?.categoryId,
                            name: record.name,
                          })
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
              />
            </Table>
          </div>
        </>
      )}
    </Drawer>
  )
}

export default FaqDrawerCategory
