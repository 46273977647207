import fetch from '../utils/fetchAxios'

export const healthCheck = async () => {
  const method = 'GET'
  const path = '/actuator/health'
  return fetch(method, path)
}

export const webConfig = async () => {
  const method = 'GET'
  const path = '/v1/config'
  return fetch(method, path)
}
