import { useEffect } from 'react'
import { HomeOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import './Home.scss'

const HomePage = () => {
  const { setPageHeader } = useGlobalStore()

  useEffect(() => {
    setPageHeader({
      title: 'Home Overview',
      subTitle: '',
      onBack: '#',
      backIcon: <HomeOutlined />,
      extra: [],
    })
  }, [])

  return (
    <div className=''>
      <div className='border border-solid p-1 bg-secondary w-full h-[628px]'></div>
    </div>
  )
}

export default HomePage
