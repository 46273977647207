import fetch from 'utils/fetchAxios'

export const findEvents = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/event-calendar'
  return fetch(method, path, query)
}

export const getEventUsers = async (id: any, query = {}) => {
  const method = 'GET'
  const path = `/v1/event-calendar/${id}/users`
  return fetch(method, path, query)
}

export const getEvent = async (id: number, query = {}) => {
  const method = 'GET'
  const path = `/v1/event-calendar/${id}`
  return fetch(method, path, query)
}

export const createEvent = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/event-calendar'
  return fetch(method, path, body)
}

export const updateEvent = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/event-calendar/${id}`
  return fetch(method, path, body)
}

export const deleteEvent = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/event-calendar/${id}`
  return fetch(method, path, query)
}

export const deleteImageEvent = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/event-calendar/${id}/image`
  return fetch(method, path, query)
}

export const findEventTags = async (query = {}) => {
  const method = 'GET'
  const path = '/v1/event-tag'
  return fetch(method, path, query)
}

export const createEventTag = async (body = {}) => {
  const method = 'POST'
  const path = '/v1/event-tag'
  return fetch(method, path, body)
}

export const updateEventTag = async (id: number, body = {}) => {
  const method = 'PATCH'
  const path = `/v1/event-tag/${id}`
  return fetch(method, path, body)
}

export const deleteEventTag = async (id: number, query = {}) => {
  const method = 'DELETE'
  const path = `/v1/event-tag/${id}`
  return fetch(method, path, query)
}
