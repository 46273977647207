import { Button, Tabs } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useGlobalStore } from 'stores/global'
import FormHtmlEditable from './FormHtmlEditable'

interface ITapProps {
  label: string
  key: string
  children: React.ReactNode
  forceRender: boolean
}

interface IPropTabByLanguage {
  dataSource?: any
}

const TabByLanguage = ({ dataSource }: IPropTabByLanguage) => {
  const buttonRef: any = useRef(null)
  const { languages } = useGlobalStore()
  const [tabItems, setTabItems] = useState<ITapProps[]>([])

  useEffect(() => {
    const items: ITapProps[] = []
    languages.map((item) => {
      const data = dataSource?.find((f) => f.locale === item.locale)
      items.push({
        label: item.country,
        key: item.locale,
        children: (
          <FormHtmlEditable
            lang={item.locale}
            dataSource={data}
            staticPageId={data?.id}
            ref={buttonRef}
          />
        ),
        forceRender: true,
      })
    })

    setTabItems(items)

    return () => {
      items.length = 0
    }
  }, [])

  const handleClick = () => {
    console.log('handle click')
  }

  return (
    <>
      {!!tabItems.length && (
        <Tabs
          items={tabItems}
          onTabClick={handleClick}
          // tabBarExtraContent={
          //   <Button type='dashed' onClick={() => buttonRef.current.addItem()}>
          //     Add statistic
          //   </Button>
          // }
        />
      )}
    </>
  )
}

export default TabByLanguage
