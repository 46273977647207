import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Tag,
  Modal,
  Input,
  message,
  Space,
  Tooltip,
} from 'antd'
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { Article } from 'stores/types/article.types'
import { deleteArticle, findAllArticles } from 'services/article.service'
import { useLocation } from 'react-router-dom'
import { ArticleTypeEnum } from 'enums/common.enum'
import { useArticleStore } from 'stores/article'
import { useAuth } from 'stores/authorized'

import ArticleDrawer from './components/Drawer'
import PermissionAccess from 'components/PermissionAccess'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input

export interface ISearchState {
  searchText: string | undefined
  categoryId: string | undefined
  articleType: string | undefined
}

const ArticlePage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()
  const { setArticleType } = useArticleStore()
  const location = useLocation()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Article | undefined>()
  const [articles, setArticles] = useState<Article[]>([])
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })
  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })
  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
    categoryId: undefined,
    articleType: undefined,
  })
  const [loading, setLoading] = useState<boolean>(false)

  let permission = 'news'
  let title = 'News Overview'
  if (location.pathname.includes(ArticleTypeEnum.ARTICLE.toLowerCase())) {
    permission = 'article'
    title = 'Article Overview'
  }

  useEffect(() => {
    const articleTypeCheck = setArticleType(location.pathname)
    const searchFilter = {
      search: {
        ...search,
        articleType: articleTypeCheck,
      },
    }
    setSearch(searchFilter.search)
    findArticles(searchFilter)
  }, [location.pathname])

  useEffect(() => {
    setPageHeader({
      title,
      subTitle: 'Content Management',
      backIcon: false,
      extra: [
        <PermissionAccess key={1} permission={`${permission}:create`} scope={scope}>
          <Button
            type='primary'
            onClick={() => {
              setIsOpen(true)
              setDataSource(undefined)
            }}
          >
            create
          </Button>
        </PermissionAccess>,
      ],
    })
  }, [scope?.[0]])

  const findArticles = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      ...param,
    }

    const payload: any = await findAllArticles(body)
    if (payload?.status?.code === 200) {
      setArticles(payload.data?.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }

    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findArticles({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteArticle(item.id)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findArticles()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findArticles({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      categoryId: undefined,
    }
    setSearch(searching)
    findArticles({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findArticles({
      search,
    })
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Space className='flex' align='start'>
            <Search
              allowClear
              size='large'
              placeholder='Search..'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />
            <Button type='link' onClick={clearSearch} disabled={!search.searchText}>
              clear
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `article-${record?.id + ''}`}
            dataSource={articles}
            className='mt-2 mb-2'
          >
            <Column
              title='Title'
              className='break-word'
              ellipsis
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>{record?.title}</div>
                </div>
              )}
            />

            <Column
              title='tags'
              className='break-word'
              render={(record) =>
                record?.contents?.length
                  ? record?.contents?.[0]?.tags.map((item) => {
                      return <Tag key={item}>{item}</Tag>
                    })
                  : 'No tags'
              }
            />
            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.createdAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.updatedAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Status'
              align='center'
              className='break-word text-center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {record?.isActive ? (
                    <Tag color='processing' className='m-0'>
                      publish
                    </Tag>
                  ) : (
                    <Tag color='default' className='m-0'>
                      unpublish
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              width={100}
              render={(record) => (
                <PermissionAccess permission={`${permission}:update`} scope={scope}>
                  <div className='flex gap-2'>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        shape='circle'
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </div>
                </PermissionAccess>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <ArticleDrawer
        onClose={onClose}
        onSuccess={onClose}
        isOpen={isOpen}
        dataSource={dataSource}
      />
    </>
  )
}

export default ArticlePage
