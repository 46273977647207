import { useEffect, useState } from 'react'
import {
  Col,
  Row,
  Spin,
  Form,
  Input,
  Modal,
  Switch,
  message,
  PageHeader,
  FormInstance,
  Select,
} from 'antd'
import { ExclamationCircleOutlined, PictureOutlined, LoadingOutlined } from '@ant-design/icons'
import { DatePicker, TimePicker } from 'components/features/picker'
import { EventTag } from 'stores/types/event.types'
import { useGlobalStore } from 'stores/global'
import { createEvent, updateEvent } from 'services/event.service'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import TabByLanguage from './TabByLanguage'
import FileUpload from 'components/features/upload/Upload'
import FormEventImageGallery from './FormEventImageGallery'

dayjs.extend(customParseFormat)

const { RangePicker } = DatePicker
const { confirm } = Modal
const { Option } = Select

const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm'

interface IProps {
  form: FormInstance
  eventId?: number
  onSuccess?: (item?: any) => void
  dataSource?: any
  tags: EventTag[]
}

const FormEventCalendar = ({ form, eventId, onSuccess, dataSource = {}, tags }: IProps) => {
  const { languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(true)
  const [fileGallery, setFileGallery] = useState<File[]>([])

  useEffect(() => {
    if (dataSource?.id) {
      form.setFieldsValue({
        id: dataSource?.id,
        tags: dataSource?.tags,
        options: dataSource?.options,
        eventDate: [dayjs(dataSource?.startDate), dayjs(dataSource?.endDate)],
        startTime: dayjs(dataSource?.startDate),
        endTime: dayjs(dataSource?.endDate),
        isActive: dataSource?.isActive,
      })
      languages.map((item) => {
        form.setFieldValue([item.locale], {
          name: dataSource.name[item.locale],
          description: dataSource.description[item.locale],
        })
      })

      setImageUrl(dataSource?.coverImage)
    }
    setLoading(false)
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValue: any = {
          id: values?.id,
          tags: values.tags.sort(),
          name: {},
          description: {},
          options: values?.options,
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
          isActive: values?.isActive,
        }

        const dateStart = values.eventDate[0].format(dateFormat)
        const dateEnd = values.eventDate[1].format(dateFormat)
        const timeStart = values?.startTime.format(timeFormat)
        const timeEnd = values?.endTime.format(timeFormat)

        itemValue.startTime = timeStart
        itemValue.endTime = timeEnd
        itemValue.startDate = dayjs(`${dateStart} ${timeStart}:00`).format()
        itemValue.endDate = dayjs(`${dateEnd} ${timeEnd}:00`).format()

        for (const item of languages) {
          itemValue.name = {
            ...itemValue.name,
            [item.locale]: values[item.locale]?.name,
          }
          itemValue.description = {
            ...itemValue.description,
            [item.locale]: values[item.locale]?.description,
          }
        }

        const formData = new FormData()
        for (const item of Object.keys(itemValue)) {
          const value = ['name', 'description', 'options', 'tags', 'images'].includes(item)
            ? JSON.stringify(itemValue[item])
            : itemValue[item]
          formData.append(item, value)
        }

        if (fileUpload) {
          formData.append('files[]', fileUpload, `coverImage-ITOON-${fileUpload.name}`)
          formData.append('coverImage', 'null')
        }

        if (fileGallery?.length) {
          for (const file of fileGallery) {
            formData.append('files[]', file, `image-ITOON-${file.name}`)
          }
        }

        const promise = values?.id ? updateEvent(values?.id, formData) : createEvent(formData)
        promise
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                form.resetFields()
                onSuccess()
              }
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const [imageUrl, setImageUrl] = useState<string>()
  const [fileUpload, setFileUpload] = useState<File>()

  const onUpload = (upload) => {
    setImageUrl(upload.imageUrl)
    setFileUpload(upload.file)
  }

  const onDelete = (file) => {
    console.log('onDelete', file)
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && eventId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          updateEvent(eventId, {
            coverImage: null,
          })
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
                setImageUrl(undefined)
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      setImageUrl(undefined)
      setFileUpload(undefined)
    }
  }

  return (
    <>
      <Form
        form={form}
        name='article-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Form.Item name='id' className='hidden' noStyle>
            <Input hidden />
          </Form.Item>
          <Row gutter={16}>
            <Col span={14}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name='tags'
                    label='Tags'
                    rules={[
                      {
                        required: true,
                        message: 'please select tags',
                      },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      style={{ width: '100%' }}
                      placeholder='select one tag'
                      optionLabelProp='label'
                      size='large'
                    >
                      {tags.length &&
                        tags.map((item: EventTag, index: number) => {
                          return (
                            <Option
                              key={`select-tag-${index}`}
                              label={item.name?.en}
                              value={item.id}
                            >
                              <div className='flex gap-2 items-center'>
                                <span
                                  className='h-6 w-6 border border-solid border-gray-400'
                                  style={{ backgroundColor: item.options?.color }}
                                ></span>
                                <span>{item?.name?.en}</span>
                              </div>
                            </Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={14}>
                  <Form.Item
                    name='eventDate'
                    label='Event Date'
                    rules={[
                      {
                        required: true,
                        message: 'please choose event date',
                      },
                    ]}
                  >
                    <RangePicker size='large' allowClear={false} />
                  </Form.Item>
                </Col>

                <Col span={5}>
                  <Form.Item
                    name='startTime'
                    label='Start time'
                    rules={[
                      {
                        required: true,
                        message: 'start time',
                      },
                    ]}
                  >
                    <TimePicker
                      format={timeFormat}
                      size='large'
                      placeholder='Start'
                      showSecond={false}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name='endTime'
                    label='End time'
                    rules={[
                      {
                        required: true,
                        message: 'end time',
                      },
                    ]}
                  >
                    <TimePicker
                      format={timeFormat}
                      size='large'
                      placeholder='End'
                      showSecond={false}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <div className='upload-full-w group'>
                <FileUpload
                  onUpload={onUpload}
                  onDelete={(file) => onDelete(file)}
                  imageUrl={imageUrl}
                  className=''
                >
                  <div className='w-full min-h-[120px] text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                    <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                    <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                      Browse to find or drag{' '}
                      <span className='font-bold underline'>Cover Photo</span> here
                    </div>
                  </div>
                </FileUpload>
                <div className='mt-1 text-xs text-gray-400'>
                  Maximum file size: 5 MB. Allowed file types: JPG, PNG, WEBP
                </div>
                <div className='text-xs text-gray-400'>Image size 768x500</div>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={['options', 'googleMap']}
                label='Google Map'
                rules={[
                  { required: true, message: 'Please input google map link' },
                  { type: 'url', warningOnly: true, message: 'Google map url is not valid' },
                  { type: 'string', min: 6 },
                ]}
              >
                <Input.TextArea size='large' placeholder='Google map url' rows={2} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='m-2 border border-solid rounded-md'>
              <div className='p-3'>
                <PageHeader
                  title='Event Management'
                  subTitle='Write content by language'
                  className='p-0'
                />
                <TabByLanguage form={form} dataSource={dataSource} />
              </div>
            </Col>
          </Row>

          <FormEventImageGallery
            eventId={eventId}
            dataSource={dataSource?.gallery}
            onSuccess={(images) => {
              setFileGallery(images)
            }}
          />

          <Row>
            <Col span={24} className='pt-5 border-t border-solid'>
              <div className='flex items-center'>
                <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which data will be listed on status (Active/InActive)
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormEventCalendar
