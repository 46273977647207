import { useEffect, useState } from 'react'
import { Col, Row, Spin, Form, Input, Modal, Switch, message, PageHeader, FormInstance } from 'antd'
import {
  ExclamationCircleOutlined,
  PictureOutlined,
  FileDoneOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import FormAddresses from 'components/features/form/FormAddresses'
import FileUpload from 'components/features/upload/Upload'
import FormSocialMedia from 'components/features/form/FormSocialMedia'
import FormBranches from './FormBranches'

import { useGlobalStore } from 'stores/global'
import { useClinicStore } from 'stores/clinic'
import { UploadFieldEnum } from 'enums/upload.enum'
import { LanguageEnum } from 'enums/language.enum'
import { IAddressValue } from 'interfaces/Address'
import { createClinic, updateClinic } from 'services/clinic.service'
import TableProduct from './TableProduct'

const { confirm } = Modal

interface IAddressLang {
  address: IAddressValue
  selected: {
    [key: string]: string
  }
}

interface IFileUpload {
  [UploadFieldEnum.LOGO]: any
  [UploadFieldEnum.CETIFICATE]: any
}

interface IProps {
  form: FormInstance
  isBranches?: boolean
  clinicId?: number
  onSuccess?: (item?: any) => void
  dataSource?: any
}

const defaultFile: IFileUpload = {
  [UploadFieldEnum.LOGO]: undefined,
  [UploadFieldEnum.CETIFICATE]: undefined,
}

const FormClinic = ({ form, isBranches = false, clinicId, onSuccess, dataSource = {} }: IProps) => {
  const { languages } = useGlobalStore()
  const { branches } = useClinicStore()

  const [langActive, setLangActive] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [addressLang, setAddressLang] = useState<IAddressLang>()

  useEffect(() => {
    if (Object.keys(dataSource)?.length) {
      setImageUrl({
        [UploadFieldEnum.LOGO]: dataSource[UploadFieldEnum.LOGO],
        [UploadFieldEnum.CETIFICATE]: dataSource[UploadFieldEnum.CETIFICATE],
      })
    } else {
      setImageUrl(defaultFile)
      setFileUpload(defaultFile)
    }
    setLangActive(LanguageEnum.TH)
    setLoading(false)
  }, [])

  const isUploaded = (field) => fileUpload?.[field] && !imageUrl?.[field]?.includes('http')

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        let mergeAddress = {}
        const spreadAddress: any = { ...addressLang?.address }
        for (const locale of Object.keys(values.address)) {
          mergeAddress = {
            ...mergeAddress,
            [locale]: {
              no: values.address[locale].no,
              ...spreadAddress[locale],
            },
          }
        }

        const itemValue = {
          ...values,
          address: {
            ...mergeAddress,
            selected: addressLang?.selected,
          },
          branches: [],
          isHaveBranch: !isBranches,
        }
        let updateId = clinicId
        // submit create branches with available clinic
        if (isBranches && clinicId) {
          updateId = itemValue?.id
          itemValue.clinicId = clinicId
        }
        // submit create branches with new clinic
        if (isBranches && !clinicId) {
          if (typeof onSuccess === 'function') {
            onSuccess(itemValue)
          }
          return
        }

        if (!isBranches && branches?.length) {
          itemValue.branches = branches
        }

        const formData = new FormData()
        for (const item of Object.keys(itemValue)) {
          const value = ['name', 'branchName', 'address', 'socials', 'branches'].includes(item)
            ? JSON.stringify(itemValue[item])
            : itemValue[item]
          formData.append(item, value)
        }
        // if (!isBranches) {
        //   if (isUploaded(UploadFieldEnum.LOGO)) {
        //     const file = fileUpload[UploadFieldEnum.LOGO]
        //     formData.append('files[]', file, `${UploadFieldEnum.LOGO}-ITOON-${file.name}`)
        //     formData.append(UploadFieldEnum.LOGO, 'null')
        //   }
        //   if (isUploaded(UploadFieldEnum.CETIFICATE)) {
        //     const file = fileUpload[UploadFieldEnum.CETIFICATE]
        //     formData.append('files[]', file, `${UploadFieldEnum.CETIFICATE}-ITOON-${file.name}`)
        //     formData.append(UploadFieldEnum.CETIFICATE, 'null')
        //   }
        // }
        if (isUploaded(UploadFieldEnum.LOGO)) {
          console.log('upload logo')
          const file = fileUpload[UploadFieldEnum.LOGO]
          formData.append('files[]', file, `${UploadFieldEnum.LOGO}-ITOON-${file.name}`)
          formData.append(UploadFieldEnum.LOGO, 'null')
        }
        if (isUploaded(UploadFieldEnum.CETIFICATE)) {
          const file = fileUpload[UploadFieldEnum.CETIFICATE]
          formData.append('files[]', file, `${UploadFieldEnum.CETIFICATE}-ITOON-${file.name}`)
          formData.append(UploadFieldEnum.CETIFICATE, 'null')
        }

        console.log('itemValue', itemValue)
        const promise = updateId ? updateClinic(updateId, formData) : createClinic(formData)
        promise
          .then((response) => {
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                onSuccess(response.data)
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const [imageUrl, setImageUrl] = useState<IFileUpload>(defaultFile)
  const [fileUpload, setFileUpload] = useState<IFileUpload>(defaultFile)

  const onUpload = (upload, field) => {
    setImageUrl({
      ...imageUrl,
      [field]: upload.imageUrl,
    })
    setFileUpload({
      ...fileUpload,
      [field]: upload.file,
    })
  }

  const onDelete = (file, field: string) => {
    console.log('onDelete', file)
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http') && clinicId) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          updateClinic(clinicId, {
            [field]: null,
          })
            .then((response) => {
              if (response.status.code === 200) {
                setImageUrl({
                  ...imageUrl,
                  [field]: undefined,
                })
                message.success('Deleted Successfully.')
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        },
      })
    } else {
      setImageUrl({
        ...imageUrl,
        [field]: undefined,
      })
      setFileUpload({
        ...fileUpload,
        [field]: undefined,
      })
    }
  }

  return (
    <>
      <Form
        form={form}
        name='clinic-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          address: {},
          branches: [],
          isActive: true,
          isHaveBranch: !isBranches,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Form.Item name='id'>
              <Input hidden />
            </Form.Item>

            <Form.Item name='clinicId'>
              <Input hidden />
            </Form.Item>

            <Col span={24}>
              <Form.Item
                label='Link Google Map'
                name='linkGoogleMap'
                rules={[{ required: true, message: 'please input link google map' }]}
              >
                <Input.TextArea size='large' placeholder='Link Google map' rows={2} />
              </Form.Item>
            </Col>

            {languages.map((item) => {
              return (
                <Col span={12} key={`name-${item.locale}`}>
                  <Form.Item
                    label={`Clinic Name [${item.country}]`}
                    name={['name', item.locale]}
                    rules={[
                      { required: true, message: `please enter clinic name [${item.locale}]` },
                    ]}
                  >
                    <Input size='large' placeholder='Clinic Name' />
                  </Form.Item>
                </Col>
              )
            })}

            {/* {!isBranches && ( */}
            <>
              <Col span={12}>
                <div className='upload-full-w group mt-3'>
                  <FileUpload
                    field={UploadFieldEnum.LOGO}
                    onUpload={onUpload}
                    onDelete={(file) => onDelete(file, UploadFieldEnum.LOGO)}
                    imageUrl={imageUrl[UploadFieldEnum.LOGO]}
                    className=''
                  >
                    <div className='w-full min-h-[120px] text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                      <PictureOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                      <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                        Browse to find or drag <span className='font-bold underline'>Logo</span>{' '}
                        here
                      </div>
                    </div>
                  </FileUpload>
                </div>
              </Col>

              <Col span={12}>
                <div className='upload-full-w group mt-3'>
                  <FileUpload
                    field={UploadFieldEnum.CETIFICATE}
                    onUpload={onUpload}
                    onDelete={(file) => onDelete(file, UploadFieldEnum.CETIFICATE)}
                    imageUrl={imageUrl[UploadFieldEnum.CETIFICATE]}
                    className=''
                  >
                    <div className='w-full min-h-[120px] text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                      <FileDoneOutlined className='text-5xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                      <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                        Browse to find or drag{' '}
                        <span className='font-bold underline'>Certificate</span> here
                      </div>
                    </div>
                  </FileUpload>
                </div>
              </Col>

              <Col span={24}>
                <div className='mb-4 mt-4 text-right text-xs text-gray-400'>
                  Maximum file size: 5 MB. Allowed file types: JPG, PNG, WEBP
                </div>
              </Col>
            </>
            {/* )} */}

            <Col span={12}>
              <Form.Item
                label='Email'
                name='email'
                rules={[{ required: false, message: 'please enter clinic email.' }]}
              >
                <Input size='large' placeholder='Email' />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Phone'
                name='phone'
                rules={[{ required: false, message: 'please enter clinic phone.' }]}
              >
                <Input size='large' placeholder='Phone' />
              </Form.Item>
            </Col>

            {languages.map((item) => {
              return (
                <Col span={12} key={'branches' + item.locale}>
                  <Form.Item
                    label={`Branch Name [${item.locale.toUpperCase()}]`}
                    name={['branchName', item.locale]}
                    rules={[
                      { required: isBranches, message: `please enter ${item.country} branches` },
                    ]}
                  >
                    <Input size='large' placeholder='branch' />
                  </Form.Item>
                </Col>
              )
            })}

            {languages.map((item) => {
              return (
                <Col span={12} key={'address' + item.locale}>
                  <Form.Item
                    label={`Address No [${item.locale.toUpperCase()}]`}
                    name={['address', item.locale, 'no']}
                    rules={[{ required: true, message: `please enter ${item.country} address` }]}
                  >
                    <Input size='large' placeholder='Clinic address no' />
                  </Form.Item>
                </Col>
              )
            })}

            <Col span={24}>
              {!!langActive && (
                <FormAddresses
                  form={form}
                  locale={langActive}
                  languages={languages.map((lang) => lang.locale)}
                  onSelect={(value) => setAddressLang(value)}
                />
              )}
            </Col>

            {/* <Col span={10}>
              {
                // disable when add data for branches
                !isBranches && (
                  <div className='flex items-center'>
                    <Form.Item name='isHaveBranch' valuePropName='checked' className='!mb-0'>
                      <Switch onChange={(haveBranch) => setIsHaveBranch(haveBranch)} />
                    </Form.Item>
                    <div className='ml-3 text-xs text-gray-400'>Add Other branches</div>
                  </div>
                )
              }
            </Col> */}

            <Col span={24}>
              <div className='text-right text-xs text-gray-400 mb-4'>
                {languages.map((item) => {
                  return (
                    <div key={`address-${isBranches}-${item.locale}`} className='mb-1 '>
                      {!!addressLang?.selected && addressLang?.selected[item.locale]}
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>

          {!isBranches && (
            <Row gutter={16}>
              <Col span={24} className='mt-2 p-2 border border-solid rounded-md'>
                <PageHeader title='Branches' subTitle='management' className='p-0' />
                <FormBranches form={form} clinicId={clinicId} />
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            <Col span={24} className='mt-2 p-2 border border-solid rounded-md'>
              <PageHeader title='Social' subTitle='Management' className='p-0' />
              <FormSocialMedia />
            </Col>
          </Row>

          {/* <Row gutter={16}>
            <Col span={24} className='mt-2 p-2 border border-solid rounded-md'>
              <PageHeader
                title="Products"
                subTitle="Management"
                className="p-0"
              />
              <TableProduct />
            </Col>
          </Row> */}

          <Row>
            <Col span={24} className='pt-5 border-t border-solid'>
              <div className='flex items-center'>
                <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which data will be listed on status (Active/InActive)
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormClinic
