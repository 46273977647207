import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { useEffect } from 'react'
import { webConfig } from 'services/common.service'
import { useGlobalStore } from 'stores/global'

// Pages
import PrivateRoute from './components/PrivateRoute'
import NotFound from './pages/errors/NotFound'
import SignInPage from './pages/signin'
import HomePage from './pages/home'
import BannerPage from './pages/home/banner'
import SectionAboutPage from 'pages/home/sectionAbout/SectionAbout'
import SectionStatisticPage from 'pages/home/sectionStatistic/SectionStatistic'

import ClinicPage from 'pages/clinic'
import ProductPage from 'pages/product'
import CeoMessagePage from 'pages/aboutus/ceomessage'
import FacilitiesPage from 'pages/aboutus/facilities'
import ArticlePage from 'pages/article/Article'
import FAQPage from 'pages/faq/FAQ'
import SettingPage from 'pages/setting/Setting'
import UserPage from 'pages/user/User'
import ContactPage from 'pages/contact/Contact'
import EventPage from 'pages/medicalEducation/event/EventCalendar'
import EventUserPage from 'pages/medicalEducation/event/EventUsers'
import WebinarPage from 'pages/medicalEducation/webinar/Webinar'
import LiveDemoPage from 'pages/medicalEducation/liveDemo/LiveDemo'
import ScholarPage from 'pages/medicalEducation/scholar/Scholar'
import AdminPage from 'pages/admin/Admin'
import ResetPassword from 'pages/password/ResetPassword'
import { MenuPermissionEnum } from 'enums/roles.enum'

const App = () => {
  const { setWebConfig, setLanguages } = useGlobalStore()
  useEffect(() => {
    const fetchWebConfig = async () => {
      const payload = await webConfig()
      if (payload.status?.code === 200) {
        setWebConfig(payload?.data)
        setLanguages(payload?.data.languages)
      }
    }

    fetchWebConfig()
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/signin' element={<SignInPage />} />
        <Route
          path='/home'
          element={<PrivateRoute component={HomePage} scope={MenuPermissionEnum.HOME} />}
        />
        <Route
          path='/home/banner'
          element={<PrivateRoute component={BannerPage} scope={MenuPermissionEnum.HOME} />}
        />
        <Route
          path='/home/about'
          element={<PrivateRoute component={SectionAboutPage} scope={MenuPermissionEnum.HOME} />}
        />
        <Route
          path='/home/statistic'
          element={
            <PrivateRoute component={SectionStatisticPage} scope={MenuPermissionEnum.HOME} />
          }
        />

        <Route
          path='/about/ceo-message'
          element={<PrivateRoute component={CeoMessagePage} scope={MenuPermissionEnum.ABOUT} />}
        />
        <Route
          path='/about/facilities'
          element={<PrivateRoute component={FacilitiesPage} scope={MenuPermissionEnum.ABOUT} />}
        />

        <Route
          path='/clinic'
          element={<PrivateRoute component={ClinicPage} scope={MenuPermissionEnum.CLINIC} />}
        />
        <Route
          path='/product'
          element={<PrivateRoute component={ProductPage} scope={MenuPermissionEnum.PRODUCT} />}
        />

        <Route
          path={'/content/news'}
          element={<PrivateRoute component={ArticlePage} scope={MenuPermissionEnum.NEWS} />}
        />
        <Route
          path={'/article/blog'}
          element={<PrivateRoute component={ArticlePage} scope={MenuPermissionEnum.ARTICLE} />}
        />
        <Route
          path={'/article/faq'}
          element={<PrivateRoute component={FAQPage} scope={MenuPermissionEnum.ARTICLE} />}
        />

        <Route
          path={'/medical-education/event'}
          element={
            <PrivateRoute component={EventPage} scope={MenuPermissionEnum.MEDICAL_EDUCATION} />
          }
        />
        <Route
          path={'/medical-education/event/:eventId/users'}
          element={
            <PrivateRoute component={EventUserPage} scope={MenuPermissionEnum.MEDICAL_EDUCATION} />
          }
        />
        <Route
          path={'/medical-education/webinar'}
          element={
            <PrivateRoute component={WebinarPage} scope={MenuPermissionEnum.MEDICAL_EDUCATION} />
          }
        />
        <Route
          path={'/medical-education/live-demo'}
          element={
            <PrivateRoute component={LiveDemoPage} scope={MenuPermissionEnum.MEDICAL_EDUCATION} />
          }
        />
        <Route
          path={'/medical-education/scholar'}
          element={
            <PrivateRoute component={ScholarPage} scope={MenuPermissionEnum.MEDICAL_EDUCATION} />
          }
        />

        <Route
          path={'/user'}
          element={<PrivateRoute component={UserPage} scope={MenuPermissionEnum.USERS} />}
        />
        <Route
          path={'/contact'}
          element={<PrivateRoute component={ContactPage} scope={MenuPermissionEnum.CONTACT} />}
        />
        <Route
          path={'/setting'}
          element={<PrivateRoute component={SettingPage} scope={MenuPermissionEnum.SETTING} />}
        />
        <Route path={'/admin'} element={<PrivateRoute component={AdminPage} scope='admin' />} />
        <Route path={'/password/reset'} element={<ResetPassword />} />
        <Route index element={<SignInPage />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
