import { Button, Form, FormInstance, Input } from 'antd'
import { PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useState } from 'react'
import ClinicBranchDrawer from './DrawerBranch'
import { useClinicStore } from 'stores/clinic'
import { LanguageEnum } from 'enums/language.enum'

interface IFormBranchesProps {
  form: FormInstance
  lang?: string
  clinicId?: number
}

const FormBranches = ({ form, lang = LanguageEnum.TH, clinicId }: IFormBranchesProps) => {
  const { branches, setBranches } = useClinicStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [branchIndex, setBranchIndex] = useState<number>(0)
  const [dataSource, setDataSource] = useState<any>({})

  const onClose = () => {
    setIsOpen(false)
    if (!clinicId && !branches[branchIndex]) {
      document.getElementById(`remove-${branchIndex}`)?.click()
    }
  }

  const editBranches = (indexItem) => {
    console.log('branches[indexItem]::', branches[indexItem])
    setIsOpen(true)
    setBranchIndex(indexItem)
    setDataSource(branches[indexItem])
  }

  const callbackSuccess = (item) => {
    setIsOpen(false)
    const branchValues = form.getFieldValue('branches')
    if (branchValues?.length) {
      branchValues[branchIndex] = {
        name: item.branchName[lang],
      }

      const branchItems = branches
      branchItems[branchIndex] = item
      setBranches(branchItems)
    }
  }

  return (
    <>
      <div className='form-social'>
        <Form.List name='branches'>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <div key={key} className='flex gap-4'>
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'please input name of branches',
                      },
                    ]}
                    className='w-1/2 mx-1'
                  >
                    <Input
                      placeholder='Branches'
                      disabled={true}
                      className='!bg-white !text-gray-600 !border-gray-100 text-base'
                    />
                  </Form.Item>

                  <Button
                    type='ghost'
                    shape='circle'
                    icon={<FormOutlined />}
                    onClick={() => {
                      editBranches(index)
                    }}
                  ></Button>
                  <Button
                    id={`remove-${index}`}
                    type='link'
                    className='border-none hover:border-solid'
                    onClick={() => remove(name)}
                  >
                    Delete
                  </Button>
                </div>
              ))}

              <Form.Item className='text-right'>
                <Button
                  type='dashed'
                  size='small'
                  className='w-[150px] hover:bg-primary hover:text-white'
                  onClick={() => {
                    add()
                    setIsOpen(true)
                    setBranchIndex(fields.length)
                    setDataSource({})
                  }}
                  icon={<PlusOutlined className='text-base relative -top-0.5' />}
                >
                  Add Branches
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        {!!isOpen && (
          <ClinicBranchDrawer
            isBranches={true}
            isOpen={isOpen}
            dataSource={dataSource}
            clinicId={clinicId}
            onClose={onClose}
            onSuccess={callbackSuccess}
          />
        )}
      </div>
    </>
  )
}

export default FormBranches
