import { Button, Form, Input, Select } from 'antd'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'

interface ISocialType {
  label: string
  value: string
}

const socialTypes = [
  { label: 'Website', value: 'website' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Instragram', value: 'instragram' },
  { label: 'Line', value: 'line' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Linkin', value: 'linkin' },
]
const FormSocialMedia = () => {
  return (
    <div className='form-social'>
      <Form.List name='socials'>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className='flex'>
                <Form.Item
                  {...restField}
                  name={[name, 'type']}
                  fieldKey={[name, 'type']}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input social type!',
                    },
                  ]}
                  className='w-[20%] mx-1'
                >
                  <Select optionFilterProp='title' placeholder='Social'>
                    {socialTypes.map((item: ISocialType, key: number) => {
                      return (
                        <Select.Option
                          value={item.value}
                          title={item.label}
                          key={`social-type-${key}`}
                        >
                          {item.label}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, 'link']}
                  fieldKey={[name, 'link']}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'please input social link',
                    },
                  ]}
                  className='w-[70%] mx-1'
                >
                  <Input placeholder='Link' />
                </Form.Item>

                <Button
                  type='ghost'
                  className='border-none hover:border-solid'
                  onClick={() => remove(name)}
                >
                  <CloseOutlined />
                </Button>
              </div>
            ))}

            <Form.Item className='text-right'>
              <Button
                type='dashed'
                size='small'
                className='w-[150px] hover:bg-primary hover:text-white'
                onClick={() => add()}
                icon={<PlusOutlined className='text-base relative -top-0.5' />}
              >
                Add Social Link
              </Button>

              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  )
}

export default FormSocialMedia
