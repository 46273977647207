import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Tag,
  Modal,
  Input,
  message,
  Space,
  Tooltip,
} from 'antd'
import {
  ExclamationCircleOutlined,
  FormOutlined,
  DeleteOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { User } from 'stores/types/user.types'
import { deleteUser, findAllUsers } from 'services/user.service'
import { useAuth } from 'stores/authorized'

import UserDrawer from './components/Drawer'
import PermissionAccess from 'components/PermissionAccess'
import { resetPassword } from 'services/auth.service'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input

export interface ISearchState {
  searchText: string | undefined
  categoryId: string | undefined
  userType: string | undefined
}

const UserPage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<User | undefined>()
  const [users, setUsers] = useState<User[]>([])

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })

  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
    categoryId: undefined,
    userType: undefined,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setPageHeader({
      title: 'User Overview',
      subTitle: 'Profile Management',
      backIcon: false,
      extra: [],
    })
    findUsers()
  }, [])

  const findUsers = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      ...param,
    }

    const payload: any = await findAllUsers(body)
    if (payload?.status?.code === 200) {
      setUsers(payload.data?.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }

    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findUsers({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteUser(item.id)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findUsers()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findUsers({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      categoryId: undefined,
    }
    setSearch(searching)
    findUsers({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findUsers({
      search,
    })
  }

  const [sending, setSending] = useState<boolean>(false)
  const fetchResetPassword = async (item) => {
    setSending(true)
    const payload = await resetPassword({
      email: item?.email,
    })
    if (payload?.status?.code === 200) {
      message.success('Sent email reset password successfully')
    }

    setSending(false)
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Space className='flex' align='start'>
            <Search
              allowClear
              size='large'
              placeholder='Search..'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />
            <Button type='link' onClick={clearSearch} disabled={!search.searchText}>
              clear
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `user-${record?.id + ''}`}
            dataSource={users}
            className='mt-2 mb-2'
          >
            <Column
              title='#'
              className='break-word'
              ellipsis
              width={250}
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>{record?.name?.en}</div>
                  <div className='text-md font-normal'>{record?.email}</div>
                </div>
              )}
            />

            <Column
              title='Infomation'
              className='break-word'
              render={(record) => (
                <>
                  <div className='text-md font-normal flex gap-2'>
                    <span>Phone: {record.phone}</span> |<span>License: {record.license}</span>
                  </div>
                  <div className='text-md font-normal flex gap-2'>
                    <span>Experience: {record?.experience},</span>
                    <span>Branch: {record?.branch}, </span>
                    <span>Work:{record?.workAt}</span>
                  </div>
                </>
              )}
            />
            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.createdAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.updatedAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Status'
              align='center'
              className='break-word text-center'
              width={120}
              render={(record) => (
                <div className='text-center'>
                  {record?.status === 'ACTIVE' ? (
                    <Tag color='success' className='m-0'>
                      approved
                    </Tag>
                  ) : (
                    <Tag color='error' className='m-0'>
                      wait to approved
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              title='Verify Email'
              align='center'
              className='break-word text-center'
              width={110}
              render={(record) => (
                <div className='text-center'>
                  {record?.isVerify ? (
                    <Tag color='processing' className='m-0'>
                      verified
                    </Tag>
                  ) : (
                    <Tag color='default' className='m-0'>
                      unverified
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              title='Reset Pass'
              align='center'
              className='break-word text-center'
              width={110}
              render={(record) => (
                <Tooltip title='Send email for reset password'>
                  <Button
                    type='ghost'
                    icon={<MailOutlined />}
                    shape='circle'
                    onClick={() => fetchResetPassword(record)}
                    loading={sending}
                  />
                </Tooltip>
              )}
            />
            <Column
              width={100}
              render={(record) => (
                <PermissionAccess permission='users:update' scope={scope}>
                  <div className='flex justify-between'>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        shape='circle'
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </div>
                </PermissionAccess>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <UserDrawer onClose={onClose} onSuccess={onClose} isOpen={isOpen} dataSource={dataSource} />
    </>
  )
}

export default UserPage
